import { Directive, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';

@Directive({
  selector: '[appDateGreaterOrEquals]',
  providers: [{ provide: NG_VALIDATORS, useExisting: DateGreaterOrEqualsDirective, multi: true }]
})
export class DateGreaterOrEqualsDirective implements Validator {
  @Input('appDateGreaterOrEquals') controls: AbstractControl[] = [];

  constructor() { }

  validate(control: AbstractControl): ValidationErrors {

    if (this.controls[0] !== undefined && !this.controls[0].disabled && this.controls[1] !== undefined && !this.controls[1].disabled) {
      if (this.controls[0].value && this.controls[1].value) {
        return !(new Date(this.controls[0].value) <= new Date(this.controls[1].value)) ? { 'DateGreaterOrEqualsFail': true } : null;
      }
    }


    return null;
  }
}
