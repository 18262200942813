import { Component, OnInit } from '@angular/core';
import { AuthService } from '../_services/auth.service';
import { SharedService } from '../shared/shared.service';
import { Router, NavigationStart } from '@angular/router';
import { stringify } from '@angular/compiler/src/util';  

@Component({
  selector: 'app-sidebar-menu',
  templateUrl: './sidebar-menu.component.html',
  styleUrls: ['./sidebar-menu.component.css']
})
export class SidebarMenuComponent implements OnInit {
  items: any;

  constructor(public service: SharedService, private router: Router, private authService: AuthService) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.reloadData();
      }
    })
  }

  show() {
    let show = true;

    if (this.router.url.includes("/verifytoken")) {
      show = false;
    }

    return show;
  }

  ngOnInit() {
    this.items = this.service.getMenu(); 
  }

  reloadData() { 
    var items = JSON.stringify(this.service.getMenu());
    this.items = JSON.parse(items);
  }

  isLoggedin() {
    return this.authService.isLoggedIn();
  }
}
