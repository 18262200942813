import { Pipe, PipeTransform } from '@angular/core';
import { DictionaryService } from './dictionary.service';

@Pipe({ name: 'ateco', pure: false })

export class AtecoPipe implements PipeTransform {
  prevalue: any;
  requestSent: boolean;
  result: string = null;

  constructor(private service: DictionaryService) { }

  transform(value: any, args?: any): any {
    if (value !== this.prevalue) {
      this.prevalue = value;
      this.requestSent = false;
    }

    if (!this.requestSent) {
      this.requestSent = true;
      this.result = null;

      this.service.getCodiceAteco(value).subscribe(result => this.result = result ? result.atecofin2007.concat(" - ", result.description) : value);
    }

    return this.result;
  }
}
