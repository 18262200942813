import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { SharedService } from '../../shared/shared.service';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root',
})

export class OrderProductCertificateService {
  CONTROLLER_NAME = "OrderProductCertificate";

  constructor(private http: HttpClient, @Inject('BASE_URL') private baseUrl: string, private sharedService: SharedService) { }

  getByFilters(filter: any): Observable<any> {
    return this.http.post<any[]>(this.baseUrl + `api/${this.CONTROLLER_NAME}/GetByFilters`, filter, httpOptions);
  }

  getById(id: number): Observable<any> {
    return this.http.get<any>(this.baseUrl + `api/${this.CONTROLLER_NAME}/GetById/${id}`);
  }

  save(item: any): Observable<any> {
    return this.http.post(this.baseUrl + `api/${this.CONTROLLER_NAME}/Save`, item, httpOptions);
  }

  getOrderProductByToken(filter: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + `api/${this.CONTROLLER_NAME}/GetOrderProductByToken`, filter, httpOptions);
  }
}
