import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { PhaseService } from '../Phase.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SharedService } from '../../../shared/shared.service';
import { PhaseValidatorService } from '../Phase.validator';

@Component({
  selector: 'app-phase-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.css']
})
export class PhaseDetailComponent implements OnInit {
  @Input() modal: boolean;
  @Output() saveEvent = new EventEmitter();

  item: any;
  isNew: boolean;
  phaseForm = new FormGroup({}); // Instantiating our form
  expertiseArea: any[];
  idWorkOrderPhase: any = null;

  constructor(private route: ActivatedRoute,
    private service: PhaseService,
    private location: Location,
    private sharedService: SharedService,
    private formBuilder: FormBuilder,
    private workOrderPhaseValidator: PhaseValidatorService)
  {
    this.phaseForm = formBuilder.group({
      'percentage': [null, [Validators.min(0), Validators.max(100)]],
      'idArea': [null],
      'title': [null],
      'id': [null]
    }, { validator: this.workOrderPhaseValidator.workOrderPhase() });
  }

  ngOnInit(): void {
    this.expertiseArea = this.sharedService.getExpertiseAreas();
    this.getItem();
  }

  getItem(): void {
    const id = +this.route.snapshot.paramMap.get('id');
    if (!this.modal && id > 0)
      this.service.getById(id).subscribe(result => {
        this.item = result;
        this.idWorkOrderPhase = this.item.id
      });
    else {
      this.isNew = true;
      this.item = new Object();
    }
  }

  getExpertiseArea(): any[] {
    if (this.expertiseArea && this.expertiseArea.length) {
      return this.expertiseArea.filter(i => i.id != 30);
    }
    return null
  }

  save(): void {
    this.service.save(this.item).subscribe(() => { this.modal ? this.saveEvent.emit() : this.location.back() });
  }

}
