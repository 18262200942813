import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ErrorService } from './error.service';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root',
})

export class AuthService implements CanActivate {

  constructor(private http: HttpClient, @Inject('BASE_URL') private baseUrl: string, private router: Router, private jwtHelper: JwtHelperService, private errorService: ErrorService) { }

  login(credentials: any): Observable<string> {
    return this.http.post<string>(this.baseUrl + `api/auth/login`, credentials, httpOptions);
  }

  logout() {
    localStorage.removeItem("jwt");
    this.router.navigate(["/"]);
  }

  isLoggedIn(): boolean {
    return !this.jwtHelper.isTokenExpired();
  }

  currentUserId(): number {
    if (this.isLoggedIn()) {
      let decodedJwtData = this.jwtHelper.decodeToken(localStorage.getItem("jwt"))
      if (decodedJwtData != null) {
        return Number(decodedJwtData['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier']);
      }
    }
    return null;
  }

  currentUsername(): string {
    if (this.isLoggedIn()) {
      let decodedJwtData = this.jwtHelper.decodeToken(localStorage.getItem("jwt"))
      if (decodedJwtData != null) {
        return decodedJwtData['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name'];
      }
    }
    return '';
  }

  currentDisplayName(): string {
    if (this.isLoggedIn()) {
      let decodedJwtData = this.jwtHelper.decodeToken(localStorage.getItem("jwt"))
      if (decodedJwtData != null) {
        return decodedJwtData['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/givenname'] + " " + decodedJwtData['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/surname'];
      }
    }
    return '';
  }

  currentCustomer(): string {
    if (this.isLoggedIn()) {
      let decodedJwtData = this.jwtHelper.decodeToken(localStorage.getItem("jwt"))
      if (decodedJwtData != null) {
        return decodedJwtData['user/customer/name'];
      }
    }
    return '';
  }

  currentCustomerId(): string {
    if (this.isLoggedIn()) {
      let decodedJwtData = this.jwtHelper.decodeToken(localStorage.getItem("jwt"))
      if (decodedJwtData != null) {
        return decodedJwtData['usercustomer'];
      }
    }
    return '';
  }

  currentArea(): string[] {
    if (this.isLoggedIn()) {
      let decodedJwtData = this.jwtHelper.decodeToken(localStorage.getItem("jwt"))
      if (decodedJwtData != null) {
        var area = decodedJwtData['user/area']
        if (area != undefined) {
          return JSON.parse(area);
        }
      }
    }
    return null;
  }

  currentPermissions(): any {
    if (this.isLoggedIn()) {
      let decodedJwtData = this.jwtHelper.decodeToken(localStorage.getItem("jwt"))
      if (decodedJwtData != null) {
        return JSON.parse(decodedJwtData['gestionaleben/claims/permissions']);
      }
    }
    return null;
  }

  hasRoles(roles: string) {
    if (this.isLoggedIn()) {
      let decodedJwtData = this.jwtHelper.decodeToken(localStorage.getItem("jwt"))
      if (decodedJwtData != null) {
        return roles.split(',').includes(decodedJwtData['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']);
      }
    }
    return false;
  }

  hasAreas(roles: string) {
    if (this.isLoggedIn()) {
      let decodedJwtData = this.jwtHelper.decodeToken(localStorage.getItem("jwt"))
      if (decodedJwtData != null) {
        let userArea = JSON.parse(decodedJwtData['user/area']);
        let allowedArea = roles.split(',');
        return userArea.some((v) => allowedArea.includes(v));
      }
    }
    return false;
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    let decodedJwtData = this.jwtHelper.decodeToken(localStorage.getItem("jwt"))
    if (decodedJwtData != null && !this.jwtHelper.isTokenExpired()) {

      this.refreshToken();

      const permission = route.data["permission"];
      //Se sono stati impostati permessi specifici sulla route, controllo che il ruolo utente sia incluso
      if (permission && permission.only.length) {
        let role = decodedJwtData['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'];
        let canActivate = permission.only.includes(role);
        if (!canActivate) {
          this.errorService.showError("Non hai i permessi necessari per accedere.");
        }

        return canActivate;
      }
      return true;
    }

    this.router.navigate(["login"],{ queryParams: { returnUrl: state.url } });
    return false;
  }

  refreshToken() {
    this.http.get<string>(this.baseUrl + `api/auth/RefreshToken`, httpOptions)
      .subscribe(result => {
        let token = (<any>result).token;
        if (token)
          localStorage.setItem("jwt", token);
      });
  }

  forgotPassword(form: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + `api/auth/forgotPassword`, JSON.stringify(form), httpOptions);
  }

  changePassword(form: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + `api/auth/changePassword`, JSON.stringify(form), httpOptions);
  }

  checkTokenExpiration(): boolean {
    if (localStorage.getItem("jwt") && this.jwtHelper.isTokenExpired()) {
      localStorage.removeItem("jwt");
      console.debug("tokerExpired");
      return true;
    }
    return false;
  }

}
