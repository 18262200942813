import { Directive, Input } from '@angular/core';
import { NG_VALIDATORS, AbstractControl, ValidationErrors, Validator, ValidatorFn, FormGroup } from '@angular/forms';

@Directive({
  selector: '[appItemVerify]',
  providers: [{ provide: NG_VALIDATORS, useExisting: ItemVerifyDirective, multi: true }]
})
export class ItemVerifyDirective implements Validator {
  @Input('controlToVerify') controlToVerify: AbstractControl;

  constructor() { }

  validate(control: AbstractControl): ValidationErrors {
    if (this.controlToVerify != undefined && control.value !== '')
      if (this.controlToVerify.value != undefined) {
        return !(control.value === this.controlToVerify.value) ? { 'ItemVerifyFail': true } : null;
      }
      else {
        return !(control.value === this.controlToVerify) ? { 'ItemVerifyFail': true } : null;
      }
      
  }
}

