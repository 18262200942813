import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-session-expired',
  templateUrl: './session-expired.component.html',
  styleUrls: ['./session-expired.component.css']
})
export class SessionExpiredComponent implements OnInit {
  @Input() message: string = "Sei stato disconnesso";

  constructor(private router: Router) { }

  ngOnInit() {
  }

  ok() {
    this.router.navigate(["login"]);
  }
}
