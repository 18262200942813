import { Component, OnInit, Input } from '@angular/core';
import { NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DocumentService } from '../document.service';
import { ProductService } from '../../../productmanager/product/product.service';
import { AuthService } from '../../../_services/auth.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-documents',
  templateUrl: './items.component.html',
  styleUrls: ['./items.component.css']
})
export class DocumentComponent implements OnInit {
  @Input() productDocumentPage: boolean;
  @Input() productSection: boolean = false;
  @Input() orderSection: boolean = false;
  @Input() order: any;
  @Input() product: any;
  @Input() orderproduct: any;
  @Input() manageOrder: boolean;
  @Input() onlyView: boolean;
  @Input() editMode: boolean;
  items: any[];
  filter: any;
  currentItem: any;
  loading: boolean;
  page: number = 1;
  pageSize: number = 10;
  itemsCount: number;
  modalTitle: string;
  products: any;
  selectedDocument: any;
  documentModal: any;
  userPermissions: any;
  isAdministrator: boolean;
  filterSubscription: Subscription;

  constructor(private service: DocumentService, private productService: ProductService, private modalService: NgbModal, private authService: AuthService) { }

  ngOnInit() {

    this.isAdministrator = (this.authService.hasRoles("amministratore"));
    this.filterSubscription = this.service.currentFilter.subscribe(filter => this.filter = filter);
    this.productService.getValues().subscribe((result) => {
      this.products = result.items;
    });
    this.refreshItems();

  }

  ngOnDestroy() {

    if (this.filterSubscription != null) {
      this.filterSubscription.unsubscribe();
    }

  }

  refreshItems() {
    if ((this.orderSection && this.orderproduct.id) || !this.orderSection) {
      this.loading = true;
      this.filter.skip = (this.page - 1) * this.pageSize;
      this.filter.take = this.pageSize;
      if (this.product) {
        this.filter.product = this.product.id;
      }
      if (this.orderproduct) {
        this.filter.orderProduct = this.orderproduct.id;
      }
      if (this.hasRoles('cliente')) {
        this.filter.status = 10;
      }

      if (this.productSection) {
        this.service.getProductDocumentsByFilters(this.filter)
          .subscribe(result => {
            this.items = result.items;
            this.itemsCount = result.count;
            this.loading = false;
          }, error => {
            this.loading = false;
          });
      }
      else if (this.orderSection && this.manageOrder) {
        this.service.getOrderProductDocumentsByFilters(this.filter)
          .subscribe(result => {
            this.items = result.items;
            this.itemsCount = result.count;
            this.loading = false;
          }, error => {
            this.loading = false;
          });
      }
      else if (this.orderSection && !this.manageOrder) {
        this.service.getOrderAndProductDocumentsByFilters(this.filter)
          .subscribe(result => {
            this.items = result.items;
            this.itemsCount = result.count;
            this.loading = false;
          }, error => {
            this.loading = false;
          });
      }
      else {
        this.service.getByFilters(this.filter)
          .subscribe(result => {
            this.items = result.items;
            this.itemsCount = result.count;
            this.loading = false;
          }, error => {
            this.loading = false;
          });
      }

    }
    else {
      this.items = new Array();
    }

    this.service.changeFilter(this.filter);
  }


  resetFilter() {
    this.filter = new Object();
    this.page = 1;
    this.refreshItems();
  }

  onPageChange($event) {
    this.refreshItems();
  }

  toggleStatus(modal: NgbModalRef, item: any) {
    if (item.status == 10) {
      this.modalTitle = "Disattiva";
    } else {
      this.modalTitle = "Attiva";
    }
    this.currentItem = item;
    this.modalService.open(modal).result.then((result) => {
      if (result === 'confirm')
        this.service.toggleStatus(item).subscribe(result => this.refreshItems());

      this.currentItem = null;

    }, (reason) => {
      this.currentItem = null;
    });
    //
  }

  openModal(modal: NgbModalRef, item: any) {

    this.modalTitle = "Elimina";
    this.currentItem = item;
    this.modalService.open(modal).result.then((result) => {
      if (result === 'confirm')
        this.delete();

      this.currentItem = null;

    }, (reason) => {
      this.currentItem = null;
    });
  }

  delete() {
    this.service.delete(this.currentItem).subscribe(result => this.refreshItems());
  }


  downloadFile(item: any) {
    this.service.downloadFile(item);
  }

  openDocumentsModalDetails(modal: NgbModalRef, Document: number) {


    if (Document) {
      this.selectedDocument = Document;
    }
    else {
      this.selectedDocument = null;
    }
    this.documentModal = this.modalService.open(modal);
    this.documentModal.result.then((result) => {
      if (result && result.files.length > 0) {

      }
      else {
      }
    }, (reason) => {
    });
  }

  DocumentChange(result?: any, refresh?: boolean) {

    this.refreshItems();
    if (this.documentModal != undefined) {
      this.documentModal.close()
    }
    else {
      this.modalService.dismissAll();
    }

  }

  hasRoles(roles: string) {
    return this.authService.hasRoles(roles);
  }

}
