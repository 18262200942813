import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root',
})

export class DocumentPageService {
  private filterSource = new BehaviorSubject(new Object());
  currentFilter = this.filterSource.asObservable();

  CONTROLLER_NAME = "Document";

  constructor(private http: HttpClient, @Inject('BASE_URL') private baseUrl: string) { }

  /*getAll(filter: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + `api/${this.CONTROLLER_NAME}/GetAll`, filter, httpOptions);
  }*/

  getByFilters(filter: any): Observable<any> {
    return this.http.post<any[]>(this.baseUrl + `api/${this.CONTROLLER_NAME}/GetByFilters`, filter, httpOptions);
  }

  getValues(): Observable<any> {
    return this.getByFilters({ status: 10 });
  }

  getById(id: number): Observable<any> {
    return this.http.get<any>(this.baseUrl + `api/${this.CONTROLLER_NAME}/GetById/${id}`);
  }

  save(item: any): Observable<any> {
    return this.http.post(this.baseUrl + `api/${this.CONTROLLER_NAME}/Save`, item, httpOptions);
  }

  delete(item: any): Observable<any> {
    return this.http.post(this.baseUrl + `api/${this.CONTROLLER_NAME}/Delete`, item, httpOptions);
  }

  toggleStatus(user: any): Observable<any> {
    return this.http.post(this.baseUrl + `api/${this.CONTROLLER_NAME}/ToggleStatus`, user, httpOptions).pipe(
      catchError(this.handleError<any>('toggleStatus'))
    );
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  getFolderList(filter: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + `api/${this.CONTROLLER_NAME}/GetFolderList`, filter, httpOptions);
  }

  getSubFolderList(filter: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + `api/${this.CONTROLLER_NAME}/GetSubFolderList`, filter, httpOptions);
  }

  getProductList(filter: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + `api/${this.CONTROLLER_NAME}/GetProductList`, filter, httpOptions);
  }

  getProducCertificatetList(filter: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + `api/${this.CONTROLLER_NAME}/GetProducCertificatetList`, filter, httpOptions);
  }

  getDocumentFromProduct(filter: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + `api/${this.CONTROLLER_NAME}/GetDocumentFromProduct`, filter, httpOptions);
  }

  getCertificateDocumentFromProduct(filter: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + `api/${this.CONTROLLER_NAME}/GetCertificateDocumentFromProduct`, filter, httpOptions);
  }

  getDocumentFromOrder(filter: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + `api/${this.CONTROLLER_NAME}/GetDocumentFromOrder`, filter, httpOptions);
  }

  getCertificateDocumentFromOrder(filter: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + `api/${this.CONTROLLER_NAME}/GetCertificateDocumentFromOrder`, filter, httpOptions);
  }

  changeFilter(filter: any) {
    this.filterSource.next(filter);
  }

  clearFilter() {
    this.filterSource = new BehaviorSubject(new Object());
  }
}
