import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthService } from '../_services/auth.service';


const allowedRoles = ['amministratore', 'cliente'];

const routes: Routes = [
  
  
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DocumentCertificatemanagerRoutingModule { }
