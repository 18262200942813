import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { SharedService } from '../shared/shared.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-sidebar-menu-button',
  templateUrl: './sidebar-menu-button.component.html',
  styleUrls: ['./sidebar-menu-button.component.scss']
})
export class SidebarMenuButtonComponent implements OnInit {
  constructor(private sharedService: SharedService) { }

  ngOnInit() {
  }

  isActive(): boolean {
    let value;
    this.sharedService.getMenuStatus().subscribe(result => value = result);
    return value;
  }

  onBurgerClicked() {
    this.sharedService.toggleMenuStatus();
  }
}
