import { Component, OnInit } from '@angular/core';
import { NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { DocumentTypeService } from '../documentType.service';

@Component({
  selector: 'app-document-types',
  templateUrl: './items.component.html',
  styleUrls: ['./items.component.css']
})
export class DocumentTypeComponent implements OnInit {
  items: any[];
  filter: any;
  currentItem: any;
  loading: boolean;
  page: number = 1;
  pageSize: number = 10;
  itemsCount: number;
  filterSubscription: Subscription;

  constructor(private service: DocumentTypeService, private modalService: NgbModal) { }

  ngOnInit() {
    this.filterSubscription = this.service.currentFilter.subscribe(filter => this.filter = filter);
    this.refreshItems();
  }

  ngOnDestroy() {

    if (this.filterSubscription != null) {
      this.filterSubscription.unsubscribe();
    }

  }

  refreshItems() {
    this.loading = true;
    this.filter.skip = (this.page - 1) * this.pageSize;
    this.filter.take = this.pageSize;
    this.service.getByFilters(this.filter)
      .subscribe(result => {
        this.items = result.items;
        this.itemsCount = result.count;
        this.loading = false;
      }, error => {
        this.loading = false;
      });

    this.service.changeFilter(this.filter);
  }

  onPageChange($event) {
    this.refreshItems();
  }

  toggleStatus(item: any) {
    this.service.toggleStatus(item).subscribe(result => this.refreshItems());
  }

  openModal(modal: NgbModalRef, item: any) {
    this.currentItem = item;
    this.modalService.open(modal).result.then((result) => {
      if (result === 'confirm')
        this.delete();

      this.currentItem = null;

    }, (reason) => {
      this.currentItem = null;
    });
  }

  delete() {
    this.service.delete(this.currentItem).subscribe(result => this.refreshItems());
  }
}
