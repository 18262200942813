import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OrderProductCertificateService } from '../orderproductmanager/orderproductcertificate/orderproductcertificate.service';
import { ActivatedRoute, Params } from '@angular/router';
import { AuthService } from '../_services/auth.service';

@Component({
  selector: 'app-verify-token',
  templateUrl: './verify-token.component.html',
  styleUrls: ['./verify-token.component.css']
})
export class VerifyTokenComponent implements OnInit {
  item: any;
  noItemFound: boolean = false;
  loading: boolean;
  today: Date;
  deadlineDate: Date;
  customerId: any;
  filter: any;
  searchVisible: boolean = true;
  customerDeny: boolean= false;

  constructor(private route: ActivatedRoute, private service: OrderProductCertificateService, private authService: AuthService, private modalService: NgbModal) { }

  ngOnInit(): void {
    this.filter = new Object();
  }

  getItem(): void {
    this.noItemFound = false;
    this.today = new Date();
    this.loading = true;
    this.customerId = this.authService.currentCustomerId();
    this.route.params.subscribe((params: Params) => {
      this.filter.token = params.token;
      this.service.getOrderProductByToken(this.filter).subscribe(result => {
        if (result == undefined) {
          this.noItemFound = true;
        }
        else {
          this.item = result;

          if (this.item && this.item.deadlineDate) {
            this.deadlineDate = new Date(this.item.deadlineDate);
          }
        }
        this.searchVisible = false;
        this.loading = false;
      });
    });
    
  }

  newSearch(): void {
    this.searchVisible = true;
  }
}
