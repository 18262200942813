import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { SharedService } from '../../shared/shared.service';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root',
})

export class OrderProductsService {
  private filterSource = new BehaviorSubject(new Object());
  currentFilter = this.filterSource.asObservable();

  CONTROLLER_NAME = "OrderProducts";

  constructor(private http: HttpClient, @Inject('BASE_URL') private baseUrl: string, private sharedService: SharedService) { }

  getByFilters(filter: any): Observable<any> {
    return this.http.post<any[]>(this.baseUrl + `api/${this.CONTROLLER_NAME}/GetByFilters`, filter, httpOptions);
  }

  getValues(): Observable<any> {
    return this.getByFilters({ status: 10 });
  }

  getByOrder(id: number): Observable<any> {
    return this.http.get<any>(this.baseUrl + `api/${this.CONTROLLER_NAME}/GetByOrder/${id}`);
  }

  getForSession(filter: any): Observable<any> {
    return this.http.post<any[]>(this.baseUrl + `api/${this.CONTROLLER_NAME}/GetForSession`, filter, httpOptions);
  }

  getById(id: number): Observable<any> {
    return this.http.get<any>(this.baseUrl + `api/${this.CONTROLLER_NAME}/GetById/${id}`);
  }

  save(item: any): Observable<any> {
    return this.http.post(this.baseUrl + `api/${this.CONTROLLER_NAME}/Save`, item, httpOptions);
  }

  saveMulti(item: any): Observable<any> {
    return this.http.post(this.baseUrl + `api/${this.CONTROLLER_NAME}/SaveMulti`, item, httpOptions);
  }

  delete(item: any): Observable<any> {
    return this.http.post(this.baseUrl + `api/${this.CONTROLLER_NAME}/Delete`, item, httpOptions);
  }

  toggleStatus(user: any): Observable<any> {
    return this.http.post(this.baseUrl + `api/${this.CONTROLLER_NAME}/ToggleStatus`, user, httpOptions).pipe(
      catchError(this.handleError<any>('toggleStatus'))
    );
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  GetCertificateFile(item: any) {
    return this.http.post<any>(this.baseUrl + `api/${this.CONTROLLER_NAME}/GetCertificateFile/`, item, httpOptions)
      .subscribe(result => {
        this.sharedService.downloadFile(result.filename, result.data);
      });
  }

  DownloadCertificateFile(item: any) {
    return this.http.post<any>(this.baseUrl + `api/${this.CONTROLLER_NAME}/DownloadCertificateFile/`, item, httpOptions)
      .subscribe(result => {
        this.sharedService.downloadFile(result.filename, result.data);
      });
  }

  getCertificateBlob(item: any):  Observable<any>{
    return this.http.post<any>(this.baseUrl + `api/${this.CONTROLLER_NAME}/GetCertificateFile/`, item, httpOptions);
  }

  changeFilter(filter: any) {
    this.filterSource.next(filter);
  }

  clearFilter() {
    this.filterSource = new BehaviorSubject(new Object());
  }
}
