import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Location } from '@angular/common';
import { DocumentCertificateService } from '../documentcertificate.service';
import { Subscription } from 'rxjs';
import { ProductService } from '../../../productmanager/product/product.service';
import { OrderService } from '../../../ordermanager/order/order.service';
import { OrderProductsService } from '../../../orderproductmanager/orderproducts/orderproducts.service';

@Component({
  selector: 'app-document-certificate-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.css']
})
export class DocumentCertificateDetailComponent implements OnInit {
  @Input() modal: boolean;
  @Input() selectedDocument: any;
  @Input() product: any;
  @Input() orderProductCertificate: any;
  @Input() order: any;
  @Input() productSection: boolean;
  @Input() orderSection: boolean;
  @Output() changedEvent = new EventEmitter();
  @Input() editMode: boolean;
  @Input() onlyView: boolean;
  @Output() saveProdEvent = new EventEmitter();

  itemSubscription: Subscription;
  item: any;
  isNew: boolean;
  products: any;
  filesUpload: any;
  fileBlob: any;
  fileName: any;

  constructor(private route: ActivatedRoute, private service: DocumentCertificateService, private productService: ProductService, private location: Location,
    private orderService: OrderService,
    private orderProductsService: OrderProductsService) { }

  ngOnInit(): void {
    
    this.getItem(this.selectedDocument);

  }

  getItem(id): void {
    if (id > 0)
      this.service.getById(id).subscribe(result => {
        this.item = result;
      });
    else {
      this.isNew = true;
      this.editMode = true;
      this.item = new Object();
      this.item.status = 10;
      if (this.orderProductCertificate) {
        this.item.idOrderProductCertificate = this.orderProductCertificate.id;
      }
      
    }
  }


  goBack(): void {
    if (this.modal) {
      this.changedEvent.emit();
    }
    else {
      this.location.back();
    }
  }

  edit(): void {
    this.editMode = true;
  }

  view(): void {
    if (this.modal) {
      this.changedEvent.emit();
    }
    else {
      this.getItem(this.item.id);
      this.editMode = false;
    }
  }

  save(): void {
    if (this.fileBlob != undefined) {
      this.item.fileData = this.fileBlob;
      this.item.fileName = this.fileName;
    }
    else {
      this.item.fileData = null;
      this.item.fileName = null;
    }
    
    this.service.save(this.item).subscribe(() => { this.modal ? this.changedEvent.emit() : this.location.back() });
    
  }

  changeFile(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result.toString());
      reader.onerror = error => reject(error);
    });
  }

  uploadFile(event) {
    if (event.target.value) {
      const file = event.target.files[0];
      const fileType = file.type;
      this.changeFile(file).then((base64: string): any => {
        this.fileBlob = base64;
        this.fileName = file.name;
      });
    } else alert('Nothing')
  }

  downloadFile() {
    this.service.downloadFile(this.item);
  }
}
