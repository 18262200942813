import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from '../shared/shared.module';
import { DocumentManagerModule } from '../documentmanager/documentmanager.module';
import { ExternalOrderComponent } from './externalOrder/items/items.component';
import { ExternalOrderDetailComponent } from './externalOrder/detail/detail.component';
import { ExternalOrdermanagerRoutingModule } from './externalordermanager-routing.module';
import { OrderProductManagerModule } from '../orderproductmanager/orderproductmanager.module';

@NgModule({
  imports: [
    CommonModule,
    ExternalOrdermanagerRoutingModule,
    FormsModule,
    NgbModule,
    SharedModule,
    ReactiveFormsModule,
    DocumentManagerModule,
    OrderProductManagerModule
  ],
  declarations: [
    ExternalOrderComponent,
    ExternalOrderDetailComponent
  ],
})
export class ExternalOrderManagerModule { }
