import { Component, OnInit } from '@angular/core';
import { AuthService } from '../_services/auth.service';
import { RecaptchaV3 } from '../shared/recaptchaV3';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css'],
  providers: [RecaptchaV3]
})
export class ForgotPasswordComponent implements OnInit {
  model: any;
  currentStep: number;
  securityQuestion: string;
  successful: boolean;
  errors: boolean;
  errorMessage: string;
  requestSent: boolean;

  constructor(private service: AuthService, private recaptcha: RecaptchaV3) { }

  ngOnInit() {
    this.model = new Object();
  }

  submit() {
    this.recaptcha.execute(() => {
      this.requestSent = true;
      this.errors = false;
      this.errorMessage = null;

      this.service.forgotPassword(this.model).subscribe(response => {
        this.requestSent = false;
        this.successful = true;
      }, err => {
        this.requestSent = false;
        this.errors = true;
        if (err.status === 400)
          this.errorMessage = err.error;
      });
    });
  }

}
