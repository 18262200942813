import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthService } from '../_services/auth.service';
import { PhaseDetailComponent } from './phase/detail/detail.component';
import { PhaseComponent } from './phase/items/items.component';
import { PhaseRootComponent } from './phase/root/root.component';
import { WorkOrderDetailComponent } from './workorder/detail/detail.component';
import { WorkOrderComponent } from './workorder/items/items.component';
import { WorkOrderStatusComponent } from './workorder/status/status.component';


const allowedRoles = ['amministratore', 'projectmanager', 'lavoratore'];
const allowedSatusRoles = ['amministratore', 'projectmanager'];

const routes: Routes = [
  {
    path: '',
    component: WorkOrderComponent,
    canActivate: [AuthService],
    data: {
      permission: {
        only: allowedRoles,
        redirectTo: 'error'
      },
      breadcrumbs: "Ordini di lavoro"
    }
  }, {
    path: 'detail/:id',
    component: WorkOrderDetailComponent,
    canActivate: [AuthService],
    data: {
      permission: {
        only: allowedRoles,
        redirectTo: 'error'
      },
      breadcrumbs: "Dettaglio"
    }
  },
  {
    path: 'detail/:id/:edit',
    component: WorkOrderDetailComponent,
    canActivate: [AuthService],
    data: {
      permission: {
        only: allowedRoles,
        redirectTo: 'error'
      },
      breadcrumbs: "Dettaglio"
    }
  },
  {
    path: 'new',
    component: WorkOrderDetailComponent,
    canActivate: [AuthService],
    data: {
      permission: {
        only: allowedRoles,
        redirectTo: 'error'
      },
      breadcrumbs: "Nuovo"
    }
  },
  {
    path: 'status',
    component: WorkOrderStatusComponent,
    canActivate: [AuthService],
    data: {
      permission: {
        only: allowedSatusRoles,
        redirectTo: 'error'
      },
      breadcrumbs: "Stato commesse"
    }
  },
  {
    path: 'phase',
    component: PhaseRootComponent,
    data: {
      breadcrumbs: "Tipi"
    },

    children: [
      {
        path: '',
        component: PhaseComponent,
        canActivate: [AuthService],
        data: {
          permission: {
            only: allowedSatusRoles,
            redirectTo: 'error'
          },
        },
      },
      {
        path: 'detail/:id',
        component: PhaseDetailComponent,
        canActivate: [AuthService],
        data: {
          permission: {
            only: allowedSatusRoles,
            redirectTo: 'error'
          },
          breadcrumbs: "Dettaglio"
        }
      },
      {
        path: 'new',
        component: PhaseDetailComponent,
        canActivate: [AuthService],
        data: {
          permission: {
            only: allowedSatusRoles,
            redirectTo: 'error'
          },
          breadcrumbs: "Nuovo"
        }
      }
    ]
  }
  
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class WorkOrderManagerRoutingModule { }
