import { Component, OnInit, Input } from '@angular/core';
import { AuthService } from '../../_services/auth.service';

@Component({
  selector: 'app-main-menu-element',
  templateUrl: './main-menu-element.component.html',
  styleUrls: ['./main-menu-element.component.css']
})
export class MainMenuElementComponent implements OnInit {
  @Input() item: any;

  constructor(private authService: AuthService) { }

  ngOnInit() {
  }

  show(item: any) {
    let show = !item.hideInMainMenu;

    if (show && item.loggedIn != null) {
      if (item.loggedIn)
        show = show && this.authService.isLoggedIn();
      else
        show = show && !this.authService.isLoggedIn();
    }

    if (show && item.roles != null) {
      show = show && this.authService.hasRoles(item.roles);
    }

    if (show && item.areas != null && !this.authService.hasRoles('amministratore')) {
      show = show && this.authService.hasAreas(item.areas);
    }

    return show;
  }
}
