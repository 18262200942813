import { Location } from '@angular/common';
import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subscription } from 'rxjs';
import * as moment from 'moment';
import { OrderProductsService } from '../orderproducts.service';
import { ProductService } from '../../../productmanager/product/product.service';
import { CustomerService } from '../../../customermanager/customer/customer.service';
import { FormBuilder, FormGroup, Validators, FormsModule } from '@angular/forms';
import { AuthService } from '../../../_services/auth.service';
import { ErrorService } from '../../../_services/error.service';
import { OrderService } from '../../../ordermanager/order/order.service';
import { DocumentComponent } from '../../../documentmanager/document/items/items.component';
import { CustomerProductionSiteService } from '../../../customermanager/productionsite/customerProductionSite.service';
import { CustomerSubcontractService } from '../../../customermanager/subcontract/customerSubcontract.service';
import { TemplatemanagerService } from '../../../templatemanager/templatemanager.service';
import { DateTimeAdapter } from 'ng-pick-datetime';
import { CustomerEmployeesService } from '../../../customermanager/employees/customerEmployees.service';

@Component({
  selector: 'app-order-products-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.css']
})
export class OrderProductsDetailComponent implements OnInit {
  @Input() orderProduct: any;
  @Input() item: any;
  @Input() order: any;
  @Input() customerId: any;
  @Input() modal: boolean;
  @Input() onlyView: boolean;
  @Input() editMode: boolean;
  @Input() selected: any;
  @Input() externalOrder: boolean=false;
  @Output() changedEvent = new EventEmitter();
  @Output() uploadEvent = new EventEmitter();
  customer: any;
  userPermissions: any;
  isNew: boolean;
  products: any;
  itemSubscription: Subscription;
  OrderProductsForm = new FormGroup({});
  customerEmployees: any[];
  productionSites: any[];
  subcontracts: any[];
  filesUpload: any;
  fileBlob: any;
  fileName: any;
  quantita: number;
  selectedorderProductDocument: any;
  @ViewChild('documentList', { static: false }) documentList: DocumentComponent;
  documentModal: any;
  public minBirthday = new Date();
  public maxBirthday = new Date();
  
  constructor(private route: ActivatedRoute,
    private service: OrderProductsService,
    private orderService: OrderService,
    private productService: ProductService,
    private customerService: CustomerService,
    private modalService: NgbModal,
    private location: Location,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private errorService: ErrorService,
    private productionSiteService: CustomerProductionSiteService,
    private subcontractService: CustomerSubcontractService,
    private customerEmployeesService: CustomerEmployeesService,
    private dateTimeAdapter: DateTimeAdapter<any>
  ) {
    this.OrderProductsForm = formBuilder.group({
      'discount': ['', [Validators.min(0), Validators.max(100)]],
    });
    dateTimeAdapter.setLocale('it-IT');
  }


  ngOnInit(): void {
    this.minBirthday.setFullYear(this.minBirthday.getFullYear() - 90);
    if (this.selected != undefined && this.selected.length == 1) {
      this.item = this.selected[0];
      this.orderProduct = this.item.id;
    }
    this.userPermissions = this.authService.currentPermissions();
    this.itemSubscription = this.route.params.subscribe((params: Params) => {
      if (!this.modal) {
        this.getItem(params.id);
      }
      else {
        this.getItem(this.orderProduct);
      }
    });
    this.productService.getValuesSalable().subscribe(
      result => {
        this.products = result.items;
      }
    );

    

    if (this.customerId) {

      this.customerService.getById(this.customerId).subscribe(result => {
        this.customer = result;
      });

      this.customerEmployeesService.getValuesByCustomer(this.customerId).subscribe(result => {
        this.customerEmployees = result;
      });
      this.productionSiteService.getValuesByCustomer(this.customerId).subscribe(result => {
        this.productionSites = result;
      });
      this.subcontractService.getValuesByCustomer(this.customerId).subscribe(result => {
        this.subcontracts = result;
      });
    }
  }

  ngOnDestroy(): void {
    if (this.itemSubscription != undefined) {
      this.itemSubscription.unsubscribe()
    }
    
  }

  getItem(id?: number, callack?: () => void): void {
    if (id > 0) {
      this.service.getById(id).subscribe(result => {
        this.item = result;
      });
    }
    else {
      if (this.userPermissions.order.create) {
        this.isNew = true;
        this.editMode = true;
        this.item = new Object();
        if (this.customer) {
          this.item.discount = this.customer.discountPercentage;
        }
        if (this.order.id) {
          this.item.idOrder = this.order.id;
        }
      }
      else {
        this.errorService.showError("Non hai i permessi necessari per accedere");
      }
    }
    
  }

  goBack(): void {
    if (this.modal) {
      this.changedEvent.emit();
    }
    else {
      this.location.back();
    }
    
  }

  hasRoles(roles: string) {
    return this.authService.hasRoles(roles);
  }

  edit(): void {
    this.editMode = true;
  }

  view(): void {
    if (this.modal) {
      this.changedEvent.emit(this.item.idOrder);
    }
    else {
      this.getItem(this.item.id);
      this.editMode = false;
    }
    
  }

  changeFile(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result.toString());
      reader.onerror = error => reject(error);
    });
  }

  uploadFile(event) {
    if (event.target.value) {
      const file = event.target.files[0];
      const fileType = file.type;
      this.changeFile(file).then((base64: string): any => {
        this.fileBlob = base64;
        this.fileName = file.name;
      });
    } else alert('Nothing')
  }

  save(): void {
    if (this.fileBlob != undefined) {
      this.item.fileData = this.fileBlob;
      this.item.fileName = this.fileName;
    }
    else {
      this.item.file = null;
      this.item.fileName = null;
    }
    

    if (!this.order.id && this.modal) {
      this.orderService.save(this.order).subscribe(result => {
        this.item.idOrder = result;
        if (this.quantita && this.quantita > 1) {
          var control = 1;
          for (var _i = 0; _i < this.quantita; _i++) {
            this.service.save(this.item).subscribe(() => {
              if (control == (this.quantita - 1)) {
                this.changedEvent.emit(this.item.idOrder);
              }
              control++;

            });
          }
        }
        else {
          this.service.save(this.item).subscribe(() => this.changedEvent.emit(this.item.idOrder));
        }
        
      });
    }
    else {
      if (this.modal) {
        if ((this.quantita && this.quantita > 1) && !this.selected) {
          var control = 1;
          for (var _i = 0; _i < this.quantita; _i++) {
            this.service.save(this.item).subscribe(() => {
              if (control == (this.quantita)) {
                this.changedEvent.emit(this.item.idOrder);
              }
              control++;
            });
          }
        }
        else if (this.selected != undefined && this.selected.length > 0) {
          var i = 0;
          this.selected.forEach(function (value) {
            if (this.item.idProductionSite != undefined) {
              value.idProductionSite = this.item.idProductionSite;
            }

            if (this.item.idSubcontract != undefined) {
              value.idSubcontract = this.item.idSubcontract;
            }

            if (this.item.startDate != undefined) {
              value.startDate = this.item.startDate;
            }

            if (this.item.deadlineDate != undefined) {
              value.deadlineDate = this.item.deadlineDate;
            }

            if (this.item.discount != undefined) {
              value.discount = this.item.discount;
            }
            if (this.item.price != undefined) {
              value.price = this.item.price;
            }
            if (this.item.place != undefined) {
              value.place = this.item.place;
            }
            if (this.item.placeCountry != undefined) {
              value.placeCountry = this.item.placeCountry;
            }
            if (this.item.organizer != undefined) {
              value.organizer = this.item.organizer;
            }
            if (this.item.protocol != undefined) {
              value.protocol = this.item.protocol;
            }

            this.service.saveMulti(value).subscribe(() => {
              if (i == (this.selected.length -1)) {
                this.changedEvent.emit(this.item.idOrder)
              }
              i++;
            });
             
          }, this);
        }
        else {
          this.service.save(this.item).subscribe(() => this.changedEvent.emit(this.item.idOrder));
        }
        
      }
      else {
        if (this.quantita && this.quantita > 1) {
          var control = 1;
          for (var _i = 0; _i < this.quantita; _i++) {
            this.service.save(this.item).subscribe(() => {
              if (control == (this.quantita)) {
                this.changedEvent.emit();
              }
              control++;

            });
          }
        }
        else {
          this.service.save(this.item).subscribe(() => this.isNew ? this.goBack() : this.view());
        }
        
      }
      
    }
    
  }

  openModal(modal: NgbModalRef) {
    this.modalService.open(modal);
  }

  openConfirmationModal(modal: NgbModalRef, item: any) {

  }


  refreshPrice() {
    var Product;
    var Customer;
    var price;
    if (this.item.idProduct) {
      Product = this.products.find(t => t.id === this.item.idProduct);
    }

    if (this.customer && this.item.discount == undefined) {
      this.item.discount = this.customer.discountPercentage;
    }
    if (Product != undefined) {
      this.item.price = ((this.item.discount != undefined) ? (Product.price - ((Product.price * this.item.discount) / 100)) : Product.price)
    }
    
    
  }

  refreshDeadline() {
    var Product;
    if (this.item.idProduct) {
      Product = this.products.find(t => t.id === this.item.idProduct);
    }
    if (Product != undefined && this.item.startDate != undefined) {
      if (Product.duration != undefined) {
        if (Object.prototype.toString.call(this.item.startDate) !== '[object Date]') {
          this.item.startDate = new Date(this.item.startDate);
        }
        /*this.item.deadlineDate = new Date(this.item.startDate);
        var year = this.item.deadlineDate.getFullYear();
        var month = this.item.deadlineDate.getMonth();
        var day = this.item.deadlineDate.getDate();
        this.item.deadlineDate = new Date(year + Product.duration, month, day);*/
        var deadline = moment(this.item.startDate).add(Product.duration, 'years');
        this.item.deadlineDate = deadline.toDate();
      }
      
    }
    
  }

  refreshPriceAndDeadline() {
    this.refreshPrice();
    this.refreshDeadline();
  }

  openOrderProductDocumentsModal(modal: NgbModalRef, orderProduct: number) {

    this.selectedorderProductDocument = null;
    this.documentModal = this.modalService.open(modal);
    this.documentModal.result.then((result) => {
      if (result && result.files.length > 0) {
        /*let file: File = result.files[0];
        var reader: FileReader = new FileReader();
        reader.onloadend = (e) => {
          //this.service.uploadMissionEquipmentReport(this.item, file.name, reader.result.toString()).subscribe(result => this.uploadEvent.emit());
        }
        reader.readAsDataURL(file);*/
      }
      else {
      }
    }, (reason) => {
    });
  }

  openOrderProductDocumentsModalDetails(modal: NgbModalRef, productDocument: number) {


    if (productDocument) {
      this.selectedorderProductDocument = productDocument;
    }
    else {
      this.selectedorderProductDocument = null;
    }
    this.documentModal = this.modalService.open(modal);
    this.documentModal.result.then((result) => {
      if (result && result.files.length > 0) {

      }
      else {
      }
    }, (reason) => {
    });
  }

  orderProductDocumentChange (result?: any, refresh?: boolean) {
    if (result) {
      this.item.id = result;
      this.isNew = false;
      //this.router.navigate(['order','detail', result]);
    }
    this.documentList.refreshItems();
    if (this.documentModal != undefined) {
      this.documentModal.close()
    }
    else {
      this.modalService.dismissAll();
    }

  }

  orderProductSaveByDoc(result?: any, refresh?: boolean) {
    if (result) {

      this.item.id = result.idOrderProduct;
      this.item.idOrder = result.idOrder;
      this.order.id = result.idOrder;
      this.isNew = false;
      //this.router.navigate(['order','detail', result]);
    }
    this.documentList.refreshItems();

  }

  deadlineWarning() {
    var Product;
    if (this.item.idProduct && this.products) {
      Product = this.products.find(t => t.id === this.item.idProduct);
    }

    if (Product != undefined && this.item.startDate != undefined && this.item.deadlineDate) {
      if (Object.prototype.toString.call(this.item.startDate) !== '[object Date]') {
        this.item.startDate = new Date(this.item.startDate);
      }
      if (Object.prototype.toString.call(this.item.deadlineDate) !== '[object Date]') {
        this.item.deadlineDate = new Date(this.item.deadlineDate);
      }

      var deadline = moment(this.item.startDate).add(Product.duration, 'years');
      var deadlineDateCalculated = deadline.toDate();
      if (deadlineDateCalculated.toDateString() !== this.item.deadlineDate.toDateString()) {
        return true;
      }
      else {
        return false;
      }

    }
  }
}
