import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { SharedService } from '../../shared/shared.service';
import { map } from 'rxjs/operators';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root',
})

export class PhaseService {
  private filterSource = new BehaviorSubject(new Object());
  currentFilter = this.filterSource.asObservable();

  CONTROLLER_NAME = "Phase";

  constructor(private sharedService: SharedService,private http: HttpClient, @Inject('BASE_URL') private baseUrl: string) { }

  getByFilters(filter: any): Observable<any> {
    return this.http.post<any[]>(this.baseUrl + `api/${this.CONTROLLER_NAME}/GetByFilters`, filter, httpOptions);
  }

  getValues(): Observable<any> {
    return this.getByFilters({ status: 10 });
  }

  getById(id: number): Observable<any> {
    return this.http.get<any>(this.baseUrl + `api/${this.CONTROLLER_NAME}/GetById/${id}`);
  }

  getByArea(id: number): Observable<any> {
    return this.http.get<any>(this.baseUrl + `api/${this.CONTROLLER_NAME}/GetByArea/${id}`);
  }

  getByWorkOrderArea(filter: any): Observable<any> {
    return this.http.post<any[]>(this.baseUrl + `api/${this.CONTROLLER_NAME}/GetByWorkOrderArea`, filter, httpOptions);
  }

  save(item: any): Observable<any> {
    return this.http.post(this.baseUrl + `api/${this.CONTROLLER_NAME}/Save`, item, httpOptions)
      .pipe(map(response => { this.sharedService.refreshData(); return response; }));
  }

  delete(item: any): Observable<any> {
    return this.http.post(this.baseUrl + `api/${this.CONTROLLER_NAME}/Delete`, item, httpOptions)
      .pipe(map(response => { this.sharedService.refreshData(); return response; }));
  }

  getWorkOrderPhase(filter: any): Observable<any> {
    return this.http.post<any[]>(this.baseUrl + `api/${this.CONTROLLER_NAME}/GetWorkOrderPhase`, filter, httpOptions);
  }

  itemExists(IdArea: number, Percentage: number, Id: number): Observable<boolean> {
    var item: any = new Object();
    
    if (Id != undefined) {
      item.id = Id;
    }
    if (IdArea != undefined) {
      item.idArea = IdArea;
    }
    if (Percentage != undefined) {
      item.percentage = Percentage;
    }
    return this.http.post<boolean>(this.baseUrl + `api/${this.CONTROLLER_NAME}/ItemExists`, item, httpOptions);
  }

  changeFilter(filter: any) {
    this.filterSource.next(filter);
  }

  clearFilter() {
    this.filterSource = new BehaviorSubject(new Object());
  }
}
