import { Component, OnInit, Output, Input, EventEmitter, ViewChild } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Location } from '@angular/common';
import { Subscription } from 'rxjs';
import { WorkOrderAreaDataService } from '../workOrderAreaData.service';
import { SharedService } from '../../../shared/shared.service';
import { UsermanagerService } from '../../../usermanager/usermanager.service';
import { AuthService } from '../../../_services/auth.service';
import { WorkOrderAreaDataComponent } from '../items/items.component';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { WorkOrderService } from '../../workorder/workorder.service';

@Component({
  selector: 'app-workorder-area-data-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.css']
})
export class WorkOrderAreaDataDetailComponent implements OnInit {
  @Input() workOrderAreaData: any;
  @Input() workOrder: any;
  @Input() modal: boolean;
  @Input() onlyView: boolean;
  @Input() editMode: boolean;
  @Input() selected: any;
  @Input() orderArea: any[];
  @Output() changedEvent = new EventEmitter();

  itemSubscription: Subscription;
  item: any;
  isNew: boolean;
  expertiseArea: any[];
  users: any[];
  newStatus: number;
  operationName: string;
  userAreas: any[];

  constructor(private route: ActivatedRoute,
    private service: WorkOrderAreaDataService,
    private location: Location,
    private modalService: NgbModal,
    private sharedService: SharedService,
    private userService: UsermanagerService,
    private authService: AuthService,
    private workOrderService: WorkOrderService) { }

  ngOnInit(): void {
    let selectedAreas = Array();
    if (this.workOrder.areaData != undefined) {
      selectedAreas = this.workOrder.areaData.map(({ idArea }) => idArea);
      if (this.workOrderAreaData != undefined) {
        selectedAreas = selectedAreas.filter(i => i.id != this.workOrderAreaData.idArea);
      }
    }
    this.expertiseArea = this.sharedService.getExpertiseAreas().filter(i => i.id != 30 && !selectedAreas.includes(i.id));
    if (this.orderArea != undefined) {

      this.expertiseArea = this.expertiseArea.filter(i => this.orderArea.includes(i.id));
    }
    var userArea = this.authService.currentArea();
    if (userArea != undefined) {
      this.userAreas = this.authService.currentArea().map(str => { return Number(str); });
    }
    if (this.isProjectManager()) {
      this.expertiseArea = this.expertiseArea.filter(i =>  this.userAreas.includes(i.id));
    }
    this.itemSubscription = this.route.params.subscribe((params: Params) => {
      if (!this.modal) {
        this.getItem(params.id);

      }
      else {
        this.getItem(this.workOrderAreaData);
      }
    });
  }

  getItem(id): void {
    if ( id > 0)
      this.service.getById(id).subscribe(result => {
        this.item = result;
        this.refreshUser();
        if (!this.isAdministrator() && !this.userAreas.includes(this.item.idArea)) {
          this.editMode = false;
        }
      });
    else {
      this.isNew = true;
      this.editMode = true;
      this.item = new Object();
      if (this.workOrder) {
        this.item.idWorkOrder = this.workOrder.id;
      }
      this.refreshUser();
    }
  }

  edit(): void {
    this.editMode = true;
  }

  save(): void {
    if (!this.workOrder.id && this.modal) {
      this.workOrderService.save(this.workOrder).subscribe(result => {
        this.item.idWorkOrder = result;
            this.service.save(this.item).subscribe(() => {
              this.changedEvent.emit(this.item.idWorkOrder);
            });
      });
    }
    else {
      this.service.save(this.item).subscribe(() => { this.modal ? this.changedEvent.emit(this.item.idWorkOrder) : this.location.back() });
    }
  }

  goBack(): void {
    if (this.modal) {
      this.changedEvent.emit();
    }
    else {
      this.location.back();
    }
  }

  refreshUser() {
    let userFilter: any = new Object();
    if (this.item.idArea != undefined) {
      userFilter.area = this.item.idArea;
    }

    this.userService.getByIdArea(userFilter).subscribe(result => {
      this.users = result;
    });
  }

  getWorker() {
    if (this.users) {
      return this.users.filter(i => i.id != this.item.idProjectManager && i.id != this.item.idAdministrative && i.role == 120)
    }

  }

  getProjectManager() {
    if (this.users) {
      return this.users.filter(i => i.id != this.item.idWorker && i.id != this.item.idAdministrative && i.role == 110)
    }
  }

  getAdministrative() {
    if (this.users) {
      return this.users.filter(i => i.id != this.item.idWorker && i.id != this.item.idProjectManager)
    }
  }

  hasRoles(roles: string) {
    return this.authService.hasRoles(roles);
  }

  isAdministrator() {
    return this.hasRoles('amministratore');
  }

  isProjectManager() {
    return this.hasRoles('projectmanager');
  }

  isWorker() {
    return this.hasRoles('lavoratore');
  }

  hasAreaTecnica() {
    return this.authService.currentArea().includes("10");
  }

  hasAreaFormazione() {
    return this.authService.currentArea().includes("20");
  }

  refresh(item: any) {
    this.item = item;
  }

  openConfirmationModal(modal: NgbModalRef, value: number, operationName: string) {
    event.preventDefault();
    this.newStatus = value;
    this.operationName = operationName;
    this.modalService.open(modal).result.then((result) => {
      if (result === 'confirm') {
        this.item.status = this.newStatus;
        this.save();
        this.refresh(this.item)
      }
      this.newStatus = null;
      this.operationName = null;
    }, (reason) => {
      this.newStatus = null;
    });
  }

  canEdit(item) {
    if (this.isAdministrator()) {
      return true;
    }
    else if (this.isProjectManager() &&
      ((this.hasAreaTecnica() && item.areaTecnica) ||
        (this.hasAreaFormazione() && item.areaFormazione))) {
      return true;
    }
    else if (this.isWorker()) {
      return true;
    }
  }

  canClose() {
    if (this.hasRoles('amministratore') || this.hasRoles('projectmanager')) {
      return true;
    }
    return false;
  }

  onAreaChange($event) {
    this.refreshUser();
  }
}
