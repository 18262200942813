import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DictionaryComuniSearchFilter } from './dictionaryAtecoSearchFilter';
import { DictionaryItem } from './dictionaryItem';
import { DictionarySearchFilter } from './dictionarySearchFilter';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root',
})

export class DictionaryService {
  CONTROLLER_NAME = "Dictionary";

  constructor(private http: HttpClient, @Inject('BASE_URL') private baseUrl: string) { }

  getCodiciAteco(filter?: DictionaryComuniSearchFilter): Observable<DictionaryItem[]> {
    if (filter === undefined)
      filter = new DictionaryComuniSearchFilter();

    return this.http.post<DictionaryItem[]>(this.baseUrl + `api/${this.CONTROLLER_NAME}/GetCodiciAteco`, filter, httpOptions);
  }

  getCodiceAteco(id: number): Observable<any> {
    return this.http.get<any>(this.baseUrl + `api/${this.CONTROLLER_NAME}/GetCodiceAteco/${id}`);
  }

}
