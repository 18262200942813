import { Component } from '@angular/core';
import { AuthService } from '../_services/auth.service';

import { OrderProductsComponent } from '../orderproductmanager/orderproducts/items/items.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
})
export class HomeComponent {

  constructor(
    private authService: AuthService,
    private router: Router) { }

  isLoggedin() {
    var logged = this.authService.isLoggedIn();
    if (logged) {
      if (this.authService.hasRoles('projectmanager') || this.authService.hasRoles('lavoratore')) {
        this.router.navigate(['/workorder']);
      }
    }
    return logged;
  }

  hasRoles(roles: string) {
    return this.authService.hasRoles(roles);
  }
}
