import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from '../shared/shared.module';
import { WorkOrderComponent } from './workorder/items/items.component';
import { WorkOrderDetailComponent } from './workorder/detail/detail.component';
import { WorkOrderManagerRoutingModule } from './workordermanager-routing.module';
import { OrderProductManagerModule } from '../orderproductmanager/orderproductmanager.module';
import { WorkOrderDocumentManagerModule } from '../workorderdocumentmanager/workorderdocumentmanager.module';
import { PhaseComponent } from './phase/items/items.component';
import { PhaseDetailComponent } from './phase/detail/detail.component';
import { PhaseRootComponent } from './phase/root/root.component';
import { WorkOrderPhaseComponent } from './workorder/workorder-phase/workorder-phase.component';
import { WorkOrderProductsComponent } from './workorderproducts/items/items.component';
import { WorkOrderProductDocumentManagerModule } from '../workorderproductdocumentmanager/workorderproductdocumentmanager.module';
import { WorkOrderStatusComponent } from './workorder/status/status.component';
import { MatProgressBarModule } from '@angular/material';
import { WorkOrderAreaDataComponent } from './areadata/items/items.component';
import { WorkOrderAreaDataDetailComponent } from './areadata/detail/detail.component';
import { WorkOrderAreaDataRootComponent } from './areadata/root/root.component';



@NgModule({
  imports: [
    CommonModule,
    WorkOrderManagerRoutingModule,
    FormsModule,
    NgbModule,
    SharedModule,
    ReactiveFormsModule,
    OrderProductManagerModule,
    WorkOrderDocumentManagerModule,
    WorkOrderProductDocumentManagerModule,
    MatProgressBarModule
  ],
  declarations: [
    WorkOrderComponent,
    WorkOrderDetailComponent,
    PhaseComponent,
    PhaseDetailComponent, 
    PhaseRootComponent,
    WorkOrderPhaseComponent,
    WorkOrderProductsComponent,
    WorkOrderStatusComponent,
    WorkOrderAreaDataComponent,
    WorkOrderAreaDataDetailComponent,
    WorkOrderAreaDataRootComponent
  ],
  exports: [
    WorkOrderComponent,
    WorkOrderDetailComponent,
    WorkOrderStatusComponent
  ]
})
export class WorkorderManagerModule { }
