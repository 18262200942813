import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { UsermanagerService } from '../usermanager/usermanager.service';
import { NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.css']
})
export class UserProfileComponent implements OnInit {
  item: any;
  editMode: boolean;

  constructor(private service: UsermanagerService, private modalService: NgbModal) { }

  ngOnInit(): void {
    this.getItem();
  }

  getItem(): void {
    this.service.getCurrent().subscribe(result => this.item = result);
  }

  edit(): void {
    this.editMode = true;
  }

  view(): void {
    this.getItem();
    this.editMode = false;
  }

  save(): void {
    this.service.saveUser(this.item).subscribe(() => this.view());
  }

  openModal(modal: NgbModalRef) {
    this.modalService.open(modal);
  }
}
