import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from '../shared/shared.module';
import { DocumentCertificatemanagerRoutingModule } from './documentcertificatemanager-routing.module';
import { DocumentCertificateRootComponent } from './document/root/root.component';
import { DocumentCertificateComponent } from './document/items/items.component';
import { DocumentCertificateDetailComponent } from './document/detail/detail.component';

@NgModule({
  imports: [
    CommonModule,
    DocumentCertificatemanagerRoutingModule,
    FormsModule,
    NgbModule,
    SharedModule,
    ReactiveFormsModule
  ],
  declarations: [
    DocumentCertificateRootComponent,
    DocumentCertificateComponent,
    DocumentCertificateDetailComponent],
  exports: [
    DocumentCertificateRootComponent,
    DocumentCertificateComponent,
    DocumentCertificateDetailComponent
  ]
})
export class DocumentCertificateManagerModule { }
