import { Component, OnInit, ElementRef, HostListener, ViewChild } from '@angular/core';
import { SharedService } from './shared/shared.service';
import { Router, NavigationEnd, NavigationStart, NavigationCancel, NavigationError, ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from './_services/auth.service';
import { SidenavService } from './shared/sidenav.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  @ViewChild('sessionExpiredModal', { static: false }) sessionExpiredModal: any;
  title = 'Ben S.r.l. - Gestionale';
  navigationStart: boolean;
  @ViewChild('sidenav', { static: false }) public sidenav;
  

  constructor(
    private sharedService: SharedService,
    private service: AuthService,
    private modalService: NgbModal,
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private elementRef: ElementRef,
    private sidenavService: SidenavService) {
  }

  @HostListener("window:resize", [])
  private onResize() {
    this.detectScreenSize();
  }

  ngOnInit(): void {
    this.detectScreenSize();
    this.sharedService.refreshData().subscribe(result => {
      /* Serve ad evitare il popup di sessione scaduta
       * se al caricamento della pagina il token è presente ma scaduto
       */
      this.service.checkTokenExpiration();

      setInterval(() => {
        if (this.service.checkTokenExpiration()) {
          this.modalService.dismissAll();
          this.modalService.open(this.sessionExpiredModal, { backdrop: 'static', keyboard: false });
        }
        else if (this.service.isLoggedIn()) {
          this.service.refreshToken();
        }
      }, 300000);
    });
    this.router.events.subscribe(e => {
      if (e instanceof NavigationStart) {
        this.navigationStart = true;
      } else if (e instanceof NavigationEnd || e instanceof NavigationCancel || e instanceof NavigationError) {
        //this.closeMenu();
        this.navigationStart = false;

        if (e instanceof NavigationEnd) {
          window.scrollTo(0, 0);
          this.modalService.dismissAll();
        }
      }
    });
  }

  isActive(): boolean {
    let value;
    this.sharedService.getMenuStatus().subscribe(result => value = result);
    return value;
  }

  isLoggedin() {
    return this.authService.isLoggedIn();
  }

  closeMenu(): void {
    this.sharedService.closeMenu();
  }

  openMenu(): void {
    this.sharedService.openMenu();
  }
  private detectScreenSize() {
    let isVisible = false;
    const el = this.elementRef.nativeElement.querySelector(`.menu-toggler`);
    if (el) {
      isVisible = window.getComputedStyle(el).display != 'none';
    }
    if (isVisible) {
      this.closeMenu();
    }
    else
    {
      this.openMenu();
    }
    
  }

  ngAfterViewInit(): void {
    this.sidenavService.setSidenav(this.sidenav);
  }
}
