import { AbstractControl, ValidationErrors, ValidatorFn, Validator, NG_VALIDATORS, FormControl, FormGroup } from '@angular/forms'
import { PhaseService } from './Phase.service';
import { Directive, OnInit, forwardRef, Input, Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class PhaseValidatorService {

  constructor(private service: PhaseService) {
  }

  public workOrderPhase(): ValidatorFn {
    return (formGroup: FormControl) => {
      const idArea = formGroup.get('idArea');
      const percentage = formGroup.get('percentage');
      const id = formGroup.get('id');

      if (idArea.value != null && percentage.value != null ) {
        
        this.service.itemExists(idArea.value, percentage.value, id.value).subscribe(
          result => {
            if (result) {
              if (percentage != null) {
                percentage.setErrors({ itemExist: true });
                return { itemExist: true }
              }

            }
            else {
              if (percentage != null) {
                if (percentage.errors.max || percentage.errors.required) {

                  percentage.errors.itemExist = null;
                }
                else {
                  percentage.setErrors(null);
                }
                return null;
              }
            }
          });
      }
      else {
        return null;

      }

    }
  }
}
