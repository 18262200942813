import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { WorkOrderService } from '../workorder.service';
import { Observable, Subject, Subscription } from 'rxjs';
import { DictionaryService } from '../../../shared/dictionary/dictionary.service';
import { Sort, MatTableDataSource } from '@angular/material';
import { map } from 'rxjs/operators';
import { CustomerService } from '../../../customermanager/customer/customer.service';
import { AuthService } from '../../../_services/auth.service';

@Component({
  selector: 'app-workorder',
  templateUrl: './items.component.html',
  styleUrls: ['./items.component.css']
})
export class WorkOrderComponent implements OnInit {
  @Input() idOrder: any;
  @Input() onlyView: boolean;
  @Input() orderEditMode: boolean;
  @Output() refreshEvent = new EventEmitter();
  items: any[];
  filter: any;
  currentItem: any;
  userPermissions: any;
  loading: boolean;
  page: number = 1;
  pageSize: number = 10;
  itemsCount: number;
  modalTitle: string;
  sortedData: any;
  customers: any;
  selectedWorkOrder: any;
  activeModal: any;
  filterSubscription: Subscription;

  constructor(
    private service: WorkOrderService,
    private modalService: NgbModal,    
    private dictionaryService: DictionaryService,
    private customerService: CustomerService,
    private authService: AuthService,
  ) { }

  ngOnInit() {
    this.userPermissions = this.authService.currentPermissions();
    this.customerService.getValues().subscribe(
      result => {
        this.customers = result.items;
      }
    );

    this.filterSubscription = this.service.currentFilter.subscribe(filter => {
      this.filter = filter
      if (this.filter.idOrder) {
        this.filter.idOrder = null;
      }
    });

    this.refreshItems();
  }

  ngOnDestroy() {

    if (this.filterSubscription != null) {
      this.filterSubscription.unsubscribe();
    }

  }

  refreshItems() {
    this.loading = true;
    if (this.idOrder) {
      this.filter.idOrder = this.idOrder;
    }
    if (this.isWorker()) {
      this.filter.idWorker = this.authService.currentUserId();
    }


    this.filter.skip = (this.page - 1) * this.pageSize;
    this.filter.take = this.pageSize;
    this.service.getByFilters(this.filter)
      .subscribe(result => {
        this.items = result.items;
        this.itemsCount = result.count;
        this.loading = false;
      }, error => {
        this.loading = false;
      });

    this.service.changeFilter(this.filter);
  }

  resetFilter() {
    this.filter = new Object();
    this.page = 1;
    this.refreshItems();
  }

  onPageChange($event) {
    this.refreshItems();
  }

  openModal(modal: NgbModalRef, item: any) {
    this.modalTitle = "ELIMINA";
    this.currentItem = item;
    this.modalService.open(modal).result.then((result) => {
      if (result === 'confirm')
        this.delete();

      this.currentItem = null;

    }, (reason) => {
      this.currentItem = null;
    });
  }

  delete() {
    this.service.delete(this.currentItem).subscribe(result => this.refreshItems());
  }

  sortData(sort: Sort) {
    const data = this.items.slice();
    if (!sort.active || sort.direction === '') {
      this.sortedData = data;
      return;
    }
    const isAsc = sort.direction === 'asc';

    this.filter.orderby = sort.active;
    this.filter.isAsc = isAsc;
    this.refreshItems();
  }

  openWorkOrderModalDetails(modal: NgbModalRef, wordOrder: number) {
    //this.productEditMode = true;

    if (wordOrder) {
      this.selectedWorkOrder = wordOrder;
    }
    this.activeModal = this.modalService.open(modal, { size: "lg" });
    this.activeModal.result.then((result) => {
      if (result && result.files.length > 0) {
        this.selectedWorkOrder = null;
      }
      else {
      }
    }, (reason) => {
    });
  }

  workOrderChange(result?: any, refresh?: boolean) {
    this.selectedWorkOrder = null;
    this.modalService.dismissAll();
    this.refreshItems();

  }

  hasRoles(roles: string) {
    return this.authService.hasRoles(roles);
  }



  isAdministrator() {
    return this.hasRoles('amministratore');
  }

  isProjectManager() {
    return this.hasRoles('projectmanager');
  }

  isWorker() {
    return this.hasRoles('lavoratore');
  }



  canCreate(item) {
    if (this.isAdministrator()) {
      return true;
    }
    else if (this.isProjectManager()) {
      return true;
    }
  }

  canEdit(item) {
    if (this.isAdministrator()) {
      return true;
    }
    else if (this.isProjectManager() &&
      ((this.authService.currentArea().includes("10") && item.areaTecnica) ||
      (this.authService.currentArea().includes("20") && item.areaFormazione))) {
      return true;
    }
  }
}
