import { Component, OnInit } from '@angular/core';
import { ErrorService } from '../_services/error.service';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.css']
})
export class ErrorComponent implements OnInit {

  constructor(private errorService: ErrorService) { }

  ngOnInit() {
  }

  getError() {
    return this.errorService.getError();
  }

}
