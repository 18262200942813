import { Component, OnInit, Input } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { CustomerService } from '../../../customermanager/customer/customer.service';
import { ProductService } from '../../../productmanager/product/product.service';
import { AuthService } from '../../../_services/auth.service';
import { DocumentService } from '../../document/document.service';

@Component({
  selector: 'app-documents-issued',
  templateUrl: './items.component.html',
  styleUrls: ['./items.component.css']
})
export class DocumentIssuedComponent  {
  items: any[];
  filter: any;
  currentItem: any;
  loading: boolean;
  page: number = 1;
  pageSize: number = 10;
  itemsCount: number;
  modalTitle: string;
  products: any;
  customers: any;
  selectedDocument: any;
  documentModal: any;
  userPermissions: any;
  isAdministrator: boolean;
  filterSubscription: Subscription;

  constructor(private service: DocumentService,
    private productService: ProductService,
    private modalService: NgbModal,
    private authService: AuthService,
    private customerService: CustomerService,) { }

  ngOnInit() {

    this.isAdministrator = (this.authService.hasRoles("amministratore"));
    this.filterSubscription = this.service.currentFilter.subscribe(filter => this.filter = filter);
    this.productService.getValues().subscribe((result) => {
      this.products = result.items;
    });
    this.customerService.getValues().subscribe(
      result => {
        this.customers = result.items;
      }
    );

    this.refreshItems();

  }

  onCustomerChange($event) {
    if (this.filter.idCustomer) {
      this.productService.getCustomerProductsValues(this.filter.idCustomer).subscribe(
        result => {
          this.products = result.items;
        }
      );
    }
    else {
      this.productService.getValues().subscribe(
        result => {
          this.products = result.items;
        }
      );
    }

  }

  ngOnDestroy() {

    if (this.filterSubscription != null) {
      this.filterSubscription.unsubscribe();
    }

  }

  refreshItems() {
      this.loading = true;
      this.filter.skip = (this.page - 1) * this.pageSize;
      this.filter.take = this.pageSize;
      if (this.hasRoles('cliente')) {
        this.filter.status = 10;
      }
      this.service.getOrderProductDocumentsIssuedByFilters(this.filter)
        .subscribe(result => {
          this.items = result.items;
          this.itemsCount = result.count;
          this.loading = false;
        }, error => {
          this.loading = false;
        });

    this.service.changeFilter(this.filter);
  }


  resetFilter() {
    this.filter = new Object();
    this.page = 1;
    this.refreshItems();
  }

  onPageChange($event) {
    this.refreshItems();
  }

  toggleStatus(modal: NgbModalRef, item: any) {
    if (item.status == 10) {
      this.modalTitle = "Disattiva";
    } else {
      this.modalTitle = "Attiva";
    }
    this.currentItem = item;
    this.modalService.open(modal).result.then((result) => {
      if (result === 'confirm')
        this.service.toggleStatus(item).subscribe(result => this.refreshItems());

      this.currentItem = null;

    }, (reason) => {
      this.currentItem = null;
    });
    //
  }

  openModal(modal: NgbModalRef, item: any) {

    this.modalTitle = "Elimina";
    this.currentItem = item;
    this.modalService.open(modal).result.then((result) => {
      if (result === 'confirm')
        this.delete();

      this.currentItem = null;

    }, (reason) => {
      this.currentItem = null;
    });
  }

  delete() {
    this.service.delete(this.currentItem).subscribe(result => this.refreshItems());
  }


  downloadFile(item: any) {
    this.service.downloadFile(item);
  }

  openDocumentsModalDetails(modal: NgbModalRef, Document: number) {


    if (Document) {
      this.selectedDocument = Document;
    }
    else {
      this.selectedDocument = null;
    }
    this.documentModal = this.modalService.open(modal);
    this.documentModal.result.then((result) => {
      if (result && result.files.length > 0) {

      }
      else {
      }
    }, (reason) => {
    });
  }

  DocumentChange(result?: any, refresh?: boolean) {

    this.refreshItems();
    if (this.documentModal != undefined) {
      this.documentModal.close()
    }
    else {
      this.modalService.dismissAll();
    }

  }

  hasRoles(roles: string) {
    return this.authService.hasRoles(roles);
  }
}
