import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { OrderProductsService } from '../orderproducts.service';
import { Observable, Subscription } from 'rxjs';
import { AuthService } from '../../../_services/auth.service';
import { ProductService } from '../../../productmanager/product/product.service';
import { CustomerService } from '../../../customermanager/customer/customer.service';
import { Sort, MatTableDataSource } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';
import { CustomerProductionSiteService } from '../../../customermanager/productionsite/customerProductionSite.service';
import { CustomerSubcontractService } from '../../../customermanager/subcontract/customerSubcontract.service';

@Component({
  selector: 'app-order-products',
  templateUrl: './items.component.html',
  styleUrls: ['./items.component.css']
})
export class OrderProductsComponent implements OnInit {
  @Input() order: any;
  @Input() onlyView: boolean;
  @Input() customerId: any;
  @Input() newOrder: any;
  @Input() isHome: boolean;
  @Input() expired: boolean;
  @Input() expiring: boolean;
  @Input() orderEditMode: boolean = false;
  @Input() externalOrder: boolean = false;
  @Output() refreshEvent = new EventEmitter();
  @Input() area: number;
  items: any[];
  filter: any;
  currentItem: any;
  userPermissions: any;
  loading: boolean;
  page: number = 1;
  pageSize: number = 10;
  itemsCount: number;
  orderCounter: number = 0;
  todayDate: string;
  products: any;
  customers: any;
  modalTitle: string;
  selectedOrderProduct: any;
  sortedData: any;
  selectAll: boolean;
  selection = new SelectionModel<any>(true, []);
  activeModal: any;
  productionSites: any;
  subcontracts: any;
  fileURL: any;
  filterSubscription: Subscription;


  constructor(
    private service: OrderProductsService,
    private modalService: NgbModal,
    private authService: AuthService,
    private productService: ProductService,
    private customerService: CustomerService,
    private productionSiteService: CustomerProductionSiteService,
    private subcontractService: CustomerSubcontractService
  ) { }

  ngOnInit() {
    this.userPermissions = this.authService.currentPermissions();
    if (this.hasRoles('cliente')) {
      this.onlyView = true;
    }
    var today = new Date(Date.now());

    this.todayDate = today.getFullYear().toString() +
      (("0" + today.getMonth() + 1).slice(-2)).toString() +
      (("0" + today.getDate()).slice(-2)).toString() +
      (("0" + today.getHours()).slice(-2)).toString() +
      (("0" + today.getMinutes()).slice(-2)).toString() +
      (("0" + today.getSeconds()).slice(-2)).toString();

    this.filterSubscription = this.service.currentFilter.subscribe(filter => {
      this.filter = filter;
      if (this.filter.expired) {
        this.filter.expired = null;
      }
      if (this.filter.expiring) {
        this.filter.expiring = null;
      }
      if (this.filter.idOrder) {
        this.filter.idOrder = null;
      }
    });

    if (this.expired) {
      this.filter.expired = true;
    }

    if (this.expiring) {
      this.filter.expiring = true;
    }
    if (this.order == undefined && this.order == undefined) {

      if (this.hasRoles('cliente')) {
        this.productService.getCustomerProductsValues().subscribe(
          result => {
            this.products = result.items;
          }
        );
      }
      else {
        this.productService.getValues().subscribe(
          result => {
            this.products = result.items;
          }
        );
      }

      this.customerService.getValues().subscribe(
        result => {
          this.customers = result.items;
        }
      );

      this.customerId = +this.authService.currentCustomerId();
      this.productionSiteService.getValuesByCustomer(this.customerId)
        .subscribe(result => {
          this.productionSites = result;
        });
      this.subcontractService.getValuesByCustomer(this.customerId)
        .subscribe(result => {
          this.subcontracts = result;
        });

    }
    
   
    this.refreshItems();
  }

  ngOnDestroy() {

    if (this.filterSubscription != null) {
      this.filterSubscription.unsubscribe();
    }

  }

  refreshItems(withoutLoading?: boolean) {
    this.loading = true;
    

    this.filter.idArea = this.area;
    this.filter.skip = (this.page - 1) * this.pageSize;
    this.filter.take = this.pageSize;
    if (this.order) {
      this.filter.idOrder = this.order.id;
     
    }
    if (!this.newOrder) {
      this.service.getByFilters(this.filter)
        .subscribe(result => {
          this.items = result.items;
          this.sortedData = result.items;
          this.items.forEach((item) => {
            if (item.endDate)
              item.endDateCollapsed = (item.endDate).replace(/\:|\-|T/g, ''); //porto la data in un formato comparabile al todayDate
            else
              item.endDateCollapsed = '99999999999999';
          });
          this.itemsCount = result.count;
          this.loading = false;
        }, error => {
          this.loading = false;
        });
    }
    else {
      this.loading = false;
    }

    this.service.changeFilter(this.filter);
    
  }

  resetFilter() {
    this.filter = new Object();
    this.page = 1;
    this.refreshItems();
  }

  onPageChange($event) {
    this.refreshItems();
  }

  openModal(modal: NgbModalRef, item: any) {
    this.modalTitle = "Elimina";
    this.currentItem = item;
    this.modalService.open(modal).result.then((result) => {
      if (result === 'confirm')
        this.delete();

      this.currentItem = null;

    }, (reason) => {
      this.currentItem = null;
    });
  }

  openModalMulti(modal: NgbModalRef) {
    this.modalTitle = "Elimina";
    this.modalService.open(modal).result.then((result) => {
      if (result === 'confirm')
        this.deleteMulti();

      this.currentItem = null;

    }, (reason) => {
      this.currentItem = null;
    });
  }

  deleteMulti() {
    this.selection.selected.forEach(function (value, idx, array) {
      this.service.delete(value).subscribe(result => {
        if (idx === array.length - 1) {
          this.refreshItems();
          this.refreshEvent.emit();
        }
      });
    }, this);
    
  }

  delete() {
    this.service.delete(this.currentItem).subscribe(result => {
      this.refreshItems();
      this.refreshEvent.emit();
    });
  }

  orderByOrderStatus() {
    if ((this.orderCounter % 2) > 0) {
      this.items.sort((a, b) => {
        return (a.endDateCollapsed > b.endDateCollapsed) ? 1 : -1;
      });
    } else {
      this.items.sort((a, b) => {
        return (a.endDateCollapsed < b.endDateCollapsed) ? 1 : -1;
      });

    }
    this.orderCounter++;
  }


  hasRoles(roles: string) {
    return this.authService.hasRoles(roles);
  }

  sortData(sort: Sort) {
    const data = this.items.slice();
    if (!sort.active || sort.direction === '') {
      this.sortedData = data;
      return;
    }
    const isAsc = sort.direction === 'asc';

    this.filter.orderby = sort.active;
    this.filter.isAsc = isAsc;
    this.refreshItems(true);
    this.selectAll = false;
  }

  

  toggleItem(modal: NgbModalRef, item: any) {
    if (item.status == 10) {
      this.modalTitle = "Disattiva";
    } else {
      this.modalTitle = "Attiva";
    }
    this.currentItem = item;
    this.modalService.open(modal).result.then((result) => {
      if (result === 'confirm')
        this.service.toggleStatus(item).subscribe(result => this.refreshItems());

      this.currentItem = null;

    }, (reason) => {
      this.currentItem = null;
    });
    //
  }

  openOrderProductsModalDetails(modal: NgbModalRef, orderProduct: number) {
    //this.productEditMode = true;
   
    if (orderProduct) {
      this.selectedOrderProduct = orderProduct;
      this.selectAll = false;
      this.selection.clear(); 
    }
    this.activeModal = this.modalService.open(modal, {size:"lg"});
    this.activeModal.result.then((result) => {
      if (result && result.files.length > 0) {
        this.selectedOrderProduct = null;
      }
      else {
      }
    }, (reason) => {
    });
  }

  orderProductChange(result?: any, refresh?: boolean) {
    this.selectedOrderProduct = null;
    this.activeModal.close();
    this.refreshItems();
    this.refreshEvent.emit();
    this.selectAll = false;
    this.selection.clear();

  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.items.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.items.forEach(row => this.selection.select(row));
  }

  checkValue(event: any, item: any) {
    this.selection.toggle(item);
    if (!this.isAllSelected()) {
      this.selectAll = false;
    }
  }

  /*downloadFile(item) {
    this.service.GetCertificateFile(item);
  }*/
  downloadFile(item: any) {
    
    this.service.DownloadCertificateFile(item.id);
  }

  editFile(modal: NgbModalRef, orderProduct: any) {
    //this.productEditMode = true;

    if (orderProduct) {
      this.selectedOrderProduct = orderProduct;
    }
    this.activeModal = this.modalService.open(modal, { size: "lg" });
    this.activeModal.result.then((result) => {
      if (result && result.files.length > 0) {
        this.selectedOrderProduct = null;
      }
      else {
      }
    }, (reason) => {
    });
  }

  orderProductCertificateChange(result?: any, refresh?: boolean) {
    this.selectedOrderProduct = null;
    this.activeModal.close();
    if (result != undefined) {
      this.service.GetCertificateFile(result.id);
    }

  }

  isAdministrator() {
    return this.hasRoles('amministratore');
  }

  isProjectManager() {
    return this.hasRoles('projectmanager');
  }

  isWorker() {
    return this.hasRoles('lavoratore');
  }

}
