import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from '../shared/shared.module';
import { OrderProductManagerRoutingModule } from './orderproductmanager-routing.module';
import { OrderProductsDetailComponent } from './orderproducts/detail/detail.component';
import { OrderProductsComponent } from './orderproducts/items/items.component';
import { OrderProductsRootComponent } from './orderproducts/root/root.component';
import { DocumentManagerModule } from '../documentmanager/documentmanager.module';
import { OrderProductCertificateDetailComponent } from './orderproductcertificate/detail/detail.component';
import { DocumentCertificateManagerModule } from '../documentcertificatemanager/documentcertificatemanager.module';
import { WorkorderManagerModule } from '../workordermanager/workordermanager.module';

@NgModule({
  imports: [
    CommonModule,
    OrderProductManagerRoutingModule,
    FormsModule,
    NgbModule,
    SharedModule,
    ReactiveFormsModule,
    DocumentManagerModule,
    DocumentCertificateManagerModule
  ],
  declarations: [
    OrderProductsComponent,
    OrderProductsDetailComponent,
    OrderProductsRootComponent,
    OrderProductCertificateDetailComponent
  ],
  exports: [
    OrderProductsComponent,
    OrderProductsDetailComponent,
    OrderProductsRootComponent,
    OrderProductCertificateDetailComponent
  ]
})
export class OrderProductManagerModule { }
