import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { SharedService } from '../../shared/shared.service';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root',
})

export class OrderService {
  private filterSource = new BehaviorSubject(new Object());
  currentFilter = this.filterSource.asObservable();

  CONTROLLER_NAME = "Order";

  constructor(private http: HttpClient, @Inject('BASE_URL') private baseUrl: string, private sharedService: SharedService) { }

  getByFilters(filter: any): Observable<any> {
    return this.http.post<any[]>(this.baseUrl + `api/${this.CONTROLLER_NAME}/GetByFilters`, filter, httpOptions);
  }

  getValues(): Observable<any> {
    return this.getByFilters({ status: 10 });
  }

  getOrderWithDocuments(id: number): Observable<any> {
    return this.http.get<any>(this.baseUrl + `api/${this.CONTROLLER_NAME}/GetOrderWithDocuments/${id}`);
  }

  getById(id: number): Observable<any> {
    return this.http.get<any>(this.baseUrl + `api/${this.CONTROLLER_NAME}/GetById/${id}`);
  }

  getLastOrderNumber(year: number): Observable<any> {
    return this.http.get<any>(this.baseUrl + `api/${this.CONTROLLER_NAME}/GetLastOrderNumber/${year}`);
  }

  save(item: any): Observable<any> {
    return this.http.post(this.baseUrl + `api/${this.CONTROLLER_NAME}/Save`, item, httpOptions);
  }

  delete(item: any): Observable<any> {
    return this.http.post(this.baseUrl + `api/${this.CONTROLLER_NAME}/Delete`, item, httpOptions);
  }


  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }


  GetQuoteFile(item: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + `api/${this.CONTROLLER_NAME}/GetQuoteFile/`, item, httpOptions);
  }

  itemExists(Year: number, Id: number, OldOrderNumber: number): Observable<boolean> {
    var item: any = new Object();
    if (Year != undefined) {
      item.year = Year;
    }
    if (Id != undefined) {
      item.id = Id;
    }

    if (OldOrderNumber != undefined) {
      item.oldOrderNumber = OldOrderNumber;
    }
    return this.http.post<boolean>(this.baseUrl + `api/${this.CONTROLLER_NAME}/ItemExists`, item, httpOptions);
  }

  GetStateNumber(): Observable<any> {
    return this.http.get<any>(this.baseUrl + `api/${this.CONTROLLER_NAME}/GetStateNumber`);
  }

  GetActiveOrderPerYear(): Observable<any> {
    return this.http.get<any>(this.baseUrl + `api/${this.CONTROLLER_NAME}/GetActiveOrderPerYear`);
  }

  getOrderNumber(): Observable<any> {
    return this.http.get<any>(this.baseUrl + `api/${this.CONTROLLER_NAME}/GetOrderNumber`);
  }

  getYear(): Observable<any> {
    return this.http.get<any>(this.baseUrl + `api/${this.CONTROLLER_NAME}/GetYear`);
  }

  downloadZipCert(id: number): Observable<any> {
    return this.http.get<any>(this.baseUrl + `api/${this.CONTROLLER_NAME}/DownloadZipCert/${id}`);
  }

  changeFilter(filter: any) {
    this.filterSource.next(filter);
  }

  clearFilter() {
    this.filterSource = new BehaviorSubject(new Object());
  }
}

