import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CourseSessionPartecipantService } from '../courseSessionPartecipant.service';
import { CourseSessionService } from '../../coursesession/coursesession.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-course-session-partecipants',
  templateUrl: './items.component.html',
  styleUrls: ['./items.component.css']
})
export class CourseSessionPartecipantComponent implements OnInit {
  @Input() courseSession: any;
  @Input() onlyView: boolean;
  @Input() editMode: boolean;
  @Output() changedEvent = new EventEmitter();
  items: any[];
  filter: any;
  currentItem: any;
  loading: boolean;
  page: number = 1;
  pageSize: number = 10;
  itemsCount: number;
  modalTitle: string;
  selectedPartecipant: any;
  partecipantModal: any;
  filterSubscription: Subscription;

  constructor(private service: CourseSessionPartecipantService,  private modalService: NgbModal) { }

  ngOnInit() {
    this.filterSubscription = this.service.currentFilter.subscribe(filter => {
      this.filter = filter
      if (this.filter.courseSession) {
        this.filter.courseSession = null;
      }
    });
    this.refreshItems();
  }

  ngOnDestroy() {

    if (this.filterSubscription != null) {
      this.filterSubscription.unsubscribe();
    }

  }

  refreshItems() {
    this.loading = true;
    if (this.courseSession) {
      this.filter.courseSession = this.courseSession.id;
    }
    this.filter.skip = (this.page - 1) * this.pageSize;
    this.filter.take = this.pageSize;
    this.service.getByFilters(this.filter)
      .subscribe(result => {
        this.items = result.items;
        this.itemsCount = result.count;
        this.loading = false;
      }, error => {
        this.loading = false;
      });

    this.service.changeFilter(this.filter);
  }

  onPageChange($event) {
    this.refreshItems();
  }

  toggleStatus(modal: NgbModalRef, item: any) {
    if (item.status == 10) {
      this.modalTitle = "Disattiva";
    } else {
      this.modalTitle = "Attiva";
    }
    this.currentItem = item;
    this.modalService.open(modal).result.then((result) => {
      if (result === 'confirm')
        this.service.toggleStatus(item).subscribe(result => this.refreshItems());

      this.currentItem = null;

    }, (reason) => {
      this.currentItem = null;
    });
    //
  }

  openModal(modal: NgbModalRef, item: any) {
    this.modalTitle = "Elimina";
    this.currentItem = item;
    this.modalService.open(modal).result.then((result) => {
      if (result === 'confirm')
        this.delete();

      this.currentItem = null;

    }, (reason) => {
      this.currentItem = null;
    });
  }

  delete() {
    this.service.delete(this.currentItem).subscribe(result => {
      this.refreshItems();
      this.changedEvent.emit();
    });
  }

  openPartecipantModalDetails(modal: NgbModalRef, Partecipant: number) {


    if (Partecipant) {
      this.selectedPartecipant = Partecipant;
    }
    else {
      this.selectedPartecipant = null;
    }
    this.partecipantModal = this.modalService.open(modal);
    this.partecipantModal.result.then((result) => {
      if (result && result.files.length > 0) {

      }
      else {
      }
    }, (reason) => {
    });
  }

  PartecipantChange(result?: any, refresh?: boolean) {

    this.refreshItems();
    if (this.partecipantModal != undefined) {
      this.partecipantModal.close()
    }
    else {
      this.modalService.dismissAll();
    }

  }
}
