import { Location } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { WorkOrderService } from '../workorder.service';
import { SharedService } from '../../../shared/shared.service';
import { OrderService } from '../../../ordermanager/order/order.service';
import { CustomerService } from '../../../customermanager/customer/customer.service';
import { CustomerEmployeesService } from '../../../customermanager/employees/customerEmployees.service';
import { OrderProductsComponent } from '../../../orderproductmanager/orderproducts/items/items.component';
import { UsermanagerService } from '../../../usermanager/usermanager.service';
import { AuthService } from '../../../_services/auth.service';
import { WorkOrderDocumentComponent } from '../../../workorderdocumentmanager/document/items/items.component';
import { PhaseService } from '../../phase/Phase.service';
import { WorkOrderPhaseComponent } from '../workorder-phase/workorder-phase.component';
import { WorkOrderAreaDataComponent } from '../../areadata/items/items.component';
import { WorkOrderAreaDataService } from '../../areadata/workOrderAreaData.service';
import { WorkOrderProductsComponent } from '../../workorderproducts/items/items.component';

@Component({
  selector: 'app-workorder-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.css']
})
export class WorkOrderDetailComponent implements OnInit {
  @Input() editMode: boolean;
  @Input() onlyView: boolean;
  @Input() workOrder: any;
  @Input() idOrder: any;
  @Input() modal: boolean;
  @Output() changedEvent = new EventEmitter();
  item: any;
  paramEdit: boolean;
  isNew: boolean;
  itemSubscription: Subscription;
  workOrderStatuses: any;
  newStatus: number;
  selectedWorkOrderDocument: any;
  orderNumbers: any;
  workOrderType: any[];
  expertiseArea: any[];
  customers: any;
  customerEmployees: any[];
  users: any[];
  idCustomerValue: any;
  pivacf: string;
  addressText: string;
  userPermissions: any;
  @ViewChild('workOrderTecnicaProducts', { static: false }) workOrderTecnicaProducts: WorkOrderProductsComponent;
  @ViewChild('workOrderFormazioneProducts', { static: false }) workOrderFormazioneProducts: WorkOrderProductsComponent;
  @ViewChild('documentList', { static: false }) documentList: WorkOrderDocumentComponent;
  selectedDocument: any;
  documentModal: any;
  operationName: string;
  selectedPhase: any = '';
  phases: any[];
  selectedAreaData: any;
  openedModal: any;
  userAreas: any[];
  orderArea: any[];
  noAreaError: boolean = false;
  @ViewChild('areaDataList', { static: false }) areaDataList: WorkOrderAreaDataComponent;
  @ViewChild('phasesAreaTecnica', { static: false }) phasesAreaTecnica: WorkOrderPhaseComponent;
  @ViewChild('phasesAreaFormazione', { static: false }) phasesAreaFormazione: WorkOrderPhaseComponent;

  
  constructor(private route: ActivatedRoute,
    private service: WorkOrderService,
    private modalService: NgbModal,
    private location: Location,
    private sharedService: SharedService,
    private orderService: OrderService,
    private customerService: CustomerService,
    private customerEmployeesService: CustomerEmployeesService,
    private userService: UsermanagerService,
    private PhaseService: PhaseService,
    private router: Router,
    private authService: AuthService,
    private workOrderAreaDataService: WorkOrderAreaDataService) {
  }


  ngOnInit(): void {
    var userArea = this.authService.currentArea();
    if (userArea != undefined) {
      this.userAreas = this.authService.currentArea().map(str => { return Number(str); });
    }
    else if (this.isProjectManager() || this.isWorker()) {
      this.noAreaError = true;
      return;
    }
    
    this.expertiseArea = this.sharedService.getExpertiseAreas();
    if (this.expertiseArea != undefined) {
      this.expertiseArea = this.expertiseArea.filter(i => i.id != 30);
    }
    this.userPermissions = this.authService.currentPermissions();
    this.workOrderStatuses = this.sharedService.getWorkOrderStatuses();

    if (this.modal) {
      this.getItem(this.workOrder);
    }
    else {
      this.itemSubscription = this.route.params.subscribe((params: Params) => {
        this.editMode = params.edit;
        this.paramEdit = params.edit;

        this.getItem(params.id);
      });
    }
    

    this.customerService.getValues().subscribe(
      result => {
        this.customers = result.items;
      }
    );
    
    this.workOrderType = this.sharedService.getWorkOrderType();
    this.orderService.getOrderNumber().subscribe(
      result => {
        this.orderNumbers = result;
      }
    );
  }

  ngOnDestroy(): void {
    if (this.itemSubscription) {
      this.itemSubscription.unsubscribe()
    }
  }

  getItem(id: number, callack?: () => void): void {
    if (id > 0) {
      this.service.getById(id).subscribe(result => {
        this.item = result;

        if (this.item.idOrder != undefined) {
          this.refreshCustomerData(this.item.idOrder);
          this.orderArea = this.item.order.orderProducts.map(i => i.product.idArea);
        }

        if (!this.canEdit(this.item) && this.editMode) {
          this.editMode = false;
        }

        this.refreshUser();

      });
    }
    else {
      this.isNew = true;
      this.editMode = true;
      this.item = new Object();
      this.item.status = 10;
      if (this.idOrder) {
        this.item.idOrder = this.idOrder;
        this.refreshCustomerData(this.item.idOrder);
      }
      if (this.isProjectManager() && this.hasAreaFormazione()) {

        this.item.areaFormazione = true;
      }

      if (this.isProjectManager() && this.hasAreaTecnica()) {

        this.item.areaTecnica = true;
      }

      this.refreshUser();
    }
  }

  goBack(): void {
    if (this.modal) {
      this.changedEvent.emit();
    }
    else {
      this.location.back();
    }
    //if (this.paramEdit) {
    //  this.router.navigate(
    //    ['/workorder/']
    //  );
    //}
    //else {
    //  this.location.back();
    //}
  }

  edit(): void {
    this.editMode = true;
  }

  view(): void {
    this.getItem(this.item.id);
    this.editMode = false;
  }

  save(): void {
    this.service.save(this.item).subscribe((id) => this.modal ? this.goBack() : this.isNew ? this.goBack() : this.view());
  }

  openModal(modal: NgbModalRef) {
    this.modalService.open(modal);
  }

  onOrderChange() {
    if (this.item.idOrder != undefined) {
      this.refreshCustomerData(this.item.idOrder);
    }
    /*if (this.workOrderFormazioneProducts) {
      this.workOrderFormazioneProducts.refreshItems();
    }
    if (this.workOrderTecnicaProducts) {
      this.workOrderTecnicaProducts.refreshItems();
    }*/
    
  }

  onAreaChange() {
    this.refreshUser();
  }

  refreshCustomerData(idOrder) {
    this.orderService.getById(idOrder).subscribe(result => {
      this.item.order = result;
      this.orderArea = this.item.order.orderProducts.map(i => i.product.idArea);
      if (this.workOrderTecnicaProducts) {
        this.workOrderTecnicaProducts.refreshItems();
      }
      if (this.workOrderFormazioneProducts) {
        this.workOrderFormazioneProducts.refreshItems();
      }
      this.idCustomerValue = result.idCustomer;
      this.pivacf = this.item.order.customer.vat;
      this.addressText = this.item.order.customer.address + " - " + this.item.order.customer.city + " (" + this.item.order.customer.province + ") - " + this.item.order.customer.cap;
      this.customerEmployeesService.getValuesByCustomer(result.idCustomer).subscribe(result => {
        this.customerEmployees = result;
      });
    });
  }

  refreshUser() {
    let userFilter: any = new Object();
    if (this.item.areaTecnica != undefined) {
      userFilter.areaTecnica = this.item.areaTecnica;
    }
    else {

      userFilter.areaTecnica = false;
    }

    if (this.item.areaFormazione != undefined) {
      userFilter.areaFormazione = this.item.areaFormazione;
    }
    else {

      userFilter.areaFormazione = false;
    }

    this.userService.getByArea(userFilter).subscribe(result => {
      this.users = result;
    });
  }

  getWorker() {
    if (this.users) {
      return this.users.filter(i => i.id != this.item.idProjectManager && i.id != this.item.idAdministrative)
    }
    
  }

  getProjectManager() {
    if (this.users) {
      return this.users.filter(i => i.id != this.item.idWorker && i.id != this.item.idAdministrative && i.role==110)
    }
  }

  getAdministrative() {
    if (this.users) {
      return this.users.filter(i => i.id != this.item.idWorker && i.id != this.item.idProjectManager)
    }
  }

  openOrderProductDocumentsModalDetails(modal: NgbModalRef, workOrderDocument: number) {


    if (workOrderDocument) {
      this.selectedWorkOrderDocument = workOrderDocument;
    }
    else {
      this.selectedWorkOrderDocument = null;
    }
    this.documentModal = this.modalService.open(modal);
    this.documentModal.result.then((result) => {
      if (result && result.files.length > 0) {

      }
      else {
      }
    }, (reason) => {
    });
  }

  orderProductDocumentChange(result?: any, refresh?: boolean) {
    if (result) {
      this.item.id = result;
      this.isNew = false;
      //this.router.navigate(['order','detail', result]);
    }
    this.documentList.refreshItems();
    if (this.documentModal != undefined) {
      this.documentModal.close()
    }
    else {
      this.modalService.dismissAll();
    }

  }

  openDocumentsModalDetails(modal: NgbModalRef, Document: number) {


    if (Document) {
      this.selectedDocument = Document;
    }
    else {
      this.selectedDocument = null;
    }
    this.documentModal = this.modalService.open(modal);
    this.documentModal.result.then((result) => {
      if (result && result.files.length > 0) {

      }
      else {
      }
    }, (reason) => {
    });
  }

  workOrderDocumentChange(result?: any, refresh?: boolean) {

    this.documentList.refreshItems();
    if (this.documentModal != undefined) {
      this.documentModal.close()
    }
    else {
      this.modalService.dismissAll();
    }

  }

  downloadDeliveryLetter(item) {
    this.service.GetDeliveryLetterFile(item);
  }

  refresh(item: any) {
    this.item = item;
  }

  openBindModal(modal: NgbModalRef, area: number) {
    this.selectedPhase = '';
    this.phases = null;
    var filter = Object();
    filter.idWorkOrder = this.item.id;
    filter.idArea = area;
    this.PhaseService.getByWorkOrderArea(filter).subscribe(phase => {
      this.phases = phase;
      this.modalService.open(modal).result.then((result) => {
        if (result !== '') {
          this.bindPhase(result,area);
          
        }
      }, (reason) => {
      });
    });
    
  }

  bindPhase(phaseId: number, area: number) {
    this.service.bindPhase(this.item, phaseId).subscribe(result => {
      this.refresh(result);
      if (area == 10) {
        this.phasesAreaTecnica.refreshItems();
      }
      if (area == 20) {
        this.phasesAreaFormazione.refreshItems();
      }
    });
  }


  openConfirmationModal(modal: NgbModalRef, value: number, operationName: string) {
    event.preventDefault();
    this.newStatus = value;
    this.operationName = operationName;
    this.modalService.open(modal).result.then((result) => {
      if (result === 'confirm') {
        this.item.status = this.newStatus;
        this.save();
        this.refresh(this.item)
      }
      this.newStatus = null;
      this.operationName = null;
    }, (reason) => {
      this.newStatus = null;
    });
  }

  hasRoles(roles: string) {
    return this.authService.hasRoles(roles);
  }

  isAdministrator() {
    return this.hasRoles('amministratore');
  }

  isProjectManager() {
    return this.hasRoles('projectmanager');
  }

  isWorker() {
    return this.hasRoles('lavoratore');
  }

  hasAreaTecnica() {
    return this.authService.currentArea().includes("10");
  }

  hasAreaFormazione() {
    return this.authService.currentArea().includes("20");
  }

  canEdit(item) {
    if (this.isAdministrator()) {
      return true;
    }
    else if (this.isProjectManager()) {
      return true;
    }
    else if (this.isWorker()) {
      return true;
    }
  }

  canClose() {
    if (this.hasRoles('amministratore') || this.hasRoles('projectmanager')) {
      return true;
    }
    return false;
  }



  openAreaDataModal(modal: NgbModalRef, areaData: number) {

    this.selectedAreaData = null;
    this.openedModal = this.modalService.open(modal, { size: "lg" });
    this.openedModal.result.then((result) => {
      if (result && result.files.length > 0) {
      }
      else {
      }
    }, (reason) => {
    });
  }

  orderProductChange(result?: any) {
    this.getItem(this.item.id);
  }

  workOrderAreaDataChange(result?: any, refresh?: boolean) {
    this.selectedAreaData = null;
    if (result) {
      this.item.id = result;
      this.isNew = false;
      //this.router.navigate(['order','detail', result]);
    }
    this.workOrderAreaDataService.getValuesByWorkOrder(this.item.id).subscribe(result => {
      this.item.areaData = result;

      this.areaDataList.refreshItems();
      if (this.openedModal) {
        this.openedModal.close();
      }
      else {
        this.modalService.dismissAll();
      }

    })

    this.getItem(this.item.id);

  }

  canAddArea() {
    if (this.isWorker()) {
      return false;
    }
    if (this.expertiseArea != undefined && this.item.areaData != undefined) {
      if (this.orderArea != undefined) {

        this.expertiseArea = this.expertiseArea.filter(i => this.orderArea.includes(i.id));
      }
      if (this.isProjectManager()) {
        return (this.expertiseArea.filter(i => this.userAreas.includes(i.id)).length >= this.item.areaData.filter(i => this.userAreas.includes(i.idArea)).length) ? false : true;
      }
      else {
        return (this.expertiseArea.length != this.item.areaData.length) ? true : false;
      }
    }
    return true;
  }

  canViewArea(idArea) {
    if (this.orderArea != undefined && !this.orderArea.includes(idArea)) {

      return false;
    }

    if (this.isWorker() && this.item.areaData != undefined) {
      var hasArea = this.userAreas.includes(idArea);

      var userAreaData =this.item.areaData.filter(i => i.idWorker == this.authService.currentUserId() && i.idArea == idArea)
      if (hasArea && userAreaData.length>0) {
        return true;
      }
      else {
        return false;
      }
      
    }
    else {
      return true;
    }
  }
}
