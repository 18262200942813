import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { SharedService } from '../../shared/shared.service';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root',
})

export class CourseSessionService {
  private filterSource = new BehaviorSubject(new Object());
  currentFilter = this.filterSource.asObservable();

  CONTROLLER_NAME = "CourseSession";

  constructor(private sharedService: SharedService, private http: HttpClient, @Inject('BASE_URL') private baseUrl: string) { }

  getByFilters(filter: any): Observable<any> {
    return this.http.post<any[]>(this.baseUrl + `api/${this.CONTROLLER_NAME}/GetByFilters`, filter, httpOptions);
  }

  getValues(): Observable<any> {
    return this.getByFilters({ status: 10 });
  }

  getById(id: number): Observable<any> {
    return this.http.get<any>(this.baseUrl + `api/${this.CONTROLLER_NAME}/GetById/${id}`);
  }

  save(item: any): Observable<any> {
    return this.http.post(this.baseUrl + `api/${this.CONTROLLER_NAME}/Save`, item, httpOptions)
      .pipe(map(response => { this.sharedService.refreshData(); return response; }));
  }

  toggleStatus(item: any): Observable<any> {
    return this.http.post(this.baseUrl + `api/${this.CONTROLLER_NAME}/ToggleStatus`, item, httpOptions)
      .pipe(map(response => { this.sharedService.refreshData(); return response; }));
  }

  delete(item: any): Observable<any> {
    return this.http.post(this.baseUrl + `api/${this.CONTROLLER_NAME}/Delete`, item, httpOptions)
      .pipe(map(response => { this.sharedService.refreshData(); return response; }));
  }

  courseSessionExists(Description: string, Id: string): Observable<boolean> {
    var item: any = new Object();
    if (Description != undefined) {
      item.description = Description;
    }
    if (Id != undefined) {
      item.id = parseInt(Id);
    }

    return this.http.post<boolean>(this.baseUrl + `api/${this.CONTROLLER_NAME}/CourseSessionExists`, item, httpOptions);
  }

  changeFilter(filter: any) {
    this.filterSource.next(filter);
  }

  clearFilter() {
    this.filterSource = new BehaviorSubject(new Object());
  }
}
