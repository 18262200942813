import { Directive } from '@angular/core';
import { AsyncValidator, AbstractControl, ValidationErrors, NG_ASYNC_VALIDATORS } from '@angular/forms';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DocumentFolderService } from './documentFolder.service';
import { ActivatedRoute, Params } from '@angular/router';

@Directive({
  selector: '[appDocumentFolderExists]',
  providers: [{ provide: NG_ASYNC_VALIDATORS, useExisting: DocumentFolderExistsDirective, multi: true }]
})
export class DocumentFolderExistsDirective implements AsyncValidator {
  id: any;

  constructor(private service: DocumentFolderService,
    private route: ActivatedRoute) { }

  validate(ctrl: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> {
    this.route.params.subscribe((params: Params) => {
      this.id = params.id;
    });

    return this.service.itemExists(ctrl.value, this.id).pipe(map(
      result => {
        return (result ? { itemExists: true } : null)
      }));
  }
}
