import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-progress-bar',
  template: `
        <div class="progress-bar-container">
           <div class="progress-bar" 
            [ngStyle]="{'width': progress + '%','background-color': color }">
           </div>
        </div>
        <div class="progress-bar-label text-right d-flex"
          [ngStyle]="{'width': progress + '%'}">
          {{progress}}%
        </div>
        `,
  styleUrls: ['./progress-bar.component.css']
})
export class ProgressBarComponent implements OnInit {
  @Input() progress: number;
  @Input() total: number;
  color: string;
  constructor() { }
  ngOnInit() {
    //if we don't have progress, set it to 0.
    if (!this.progress) {
      this.progress = 0;
    }
    //if we don't have a total aka no requirement, it's 100%.
    if (this.total === 0) {
      this.total = this.progress;
    } else if (!this.total) {
      this.total = 100;
    }
    //if the progress is greater than the total, it's also 100%.
    if (this.progress > this.total) {
      this.progress = 100;
      this.total = 100;
    }
    this.progress = (this.progress / this.total) * 100;
    if (this.progress <= 10) {
      this.color = '#2ed0e6';
    }
    else if (this.progress <= 20) {
      this.color = '#2789c9';
    }
    else if (this.progress <= 30) {
      this.color = '#7558a2';
    }
    else if (this.progress <= 40) {
      this.color = '#ac5ab0';
    }
    else if (this.progress <= 50) {
      this.color = '#e54f4f';
    }
    else if (this.progress <= 60) {
      this.color = '#f3825b';
    }
    else if (this.progress <= 70) {
      this.color = '#facb58';
    }
    else if (this.progress <= 80) {
      this.color = '#ffe03e';
    }
    else if (this.progress <= 90) {
      this.color = '#9dcc5e';
    }
    else if (this.progress <= 100) {
      this.color = '#52b861';
    }
  }
}
