import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-document-issued-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.css']
})
export class DocumentIssuedDetailComponent  {
  
}
