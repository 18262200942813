import { Pipe, PipeTransform } from '@angular/core';
import { SharedService } from './shared.service';

@Pipe({
  name: 'workOrderAreaDataStatus'
})
export class WorkOrderAreaDataStatusPipe implements PipeTransform {

  constructor(private service: SharedService) { }

  transform(value: any, args?: any): any {

    var item = this.service.getWorkOrderAreaDataStatuses().filter(function (item) {
      return item.id === value;
    })[0];
      if (item)
        return item.description;
      else
        return value;
    
  }

}
