import { Component, OnInit, Input } from '@angular/core';
import { NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { WorkOrderDocumentService } from '../workorderdocument.service';
import { ProductService } from '../../../productmanager/product/product.service';
import { AuthService } from '../../../_services/auth.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-work-order-documents',
  templateUrl: './items.component.html',
  styleUrls: ['./items.component.css']
})
export class WorkOrderDocumentComponent implements OnInit {
  @Input() workorder: any;
  @Input() onlyView: boolean;
  @Input() editMode: boolean;
  items: any[];
  filter: any;
  currentItem: any;
  loading: boolean;
  page: number = 1;
  pageSize: number = 10;
  itemsCount: number;
  modalTitle: string;
  products: any;
  selectedDocument: any;
  documentModal: any;
  userPermissions: any;
  isAdministrator: boolean;
  filterSubscription: Subscription;

  constructor(private service: WorkOrderDocumentService, private productService: ProductService, private modalService: NgbModal, private authService: AuthService) { }

  ngOnInit() {

    this.isAdministrator = (this.authService.hasRoles("amministratore"));
    this.filterSubscription = this.service.currentFilter.subscribe(filter => {
      this.filter = filter;
      if (this.filter.workorder) {
        this.filter.workorder = null;
      }
    });
    this.productService.getValues().subscribe((result) => {
      this.products = result.items;
    });
    this.refreshItems();

  }

  ngOnDestroy() {

    if (this.filterSubscription != null) {
      this.filterSubscription.unsubscribe();
    }

  }

  refreshItems() {
    this.loading = true;
    this.filter.skip = (this.page - 1) * this.pageSize;
    this.filter.take = this.pageSize;
    if (this.workorder) {
      this.filter.workorder = this.workorder.id;
    }

    this.service.getByFilters(this.filter)
      .subscribe(result => {
        this.items = result.items;
        this.itemsCount = result.count;
        this.loading = false;
      }, error => {
        this.loading = false;
      });

    this.service.changeFilter(this.filter);
    
  }


  resetFilter() {
    this.filter = new Object();
    this.page = 1;
    this.refreshItems();
  }

  onPageChange($event) {
    this.refreshItems();
  }

  toggleStatus(modal: NgbModalRef, item: any) {
    if (item.status == 10) {
      this.modalTitle = "Disattiva";
    } else {
      this.modalTitle = "Attiva";
    }
    this.currentItem = item;
    this.modalService.open(modal).result.then((result) => {
      if (result === 'confirm')
        this.service.toggleStatus(item).subscribe(result => this.refreshItems());

      this.currentItem = null;

    }, (reason) => {
      this.currentItem = null;
    });
    //
  }

  openModal(modal: NgbModalRef, item: any) {

    this.modalTitle = "Elimina";
    this.currentItem = item;
    this.modalService.open(modal).result.then((result) => {
      if (result === 'confirm')
        this.delete();

      this.currentItem = null;

    }, (reason) => {
      this.currentItem = null;
    });
  }

  delete() {
    this.service.delete(this.currentItem).subscribe(result => this.refreshItems());
  }


  downloadFile(item: any) {
    this.service.downloadFile(item);
  }

  openDocumentsModalDetails(modal: NgbModalRef, Document: number) {


    if (Document) {
      this.selectedDocument = Document;
    }
    else {
      this.selectedDocument = null;
    }
    this.documentModal = this.modalService.open(modal);
    this.documentModal.result.then((result) => {
      if (result && result.files.length > 0) {

      }
      else {
      }
    }, (reason) => {
    });
  }

  DocumentChange(result?: any, refresh?: boolean) {

    this.refreshItems();
    if (this.documentModal != undefined) {
      this.documentModal.close()
    }
    else {
      this.modalService.dismissAll();
    }

  }

  hasRoles(roles: string) {
    return this.authService.hasRoles(roles);
  }

  isProjectManager() {
    return this.hasRoles('projectmanager');
  }

  isWorker() {
    return this.hasRoles('lavoratore');
  }

  canEdit() {
    if (this.isWorker()) {
      return false;
    }
  }

  canDelete() {
    if (this.isWorker()) {
      return false;
    }
  }

}
