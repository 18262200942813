import { Component, Input, OnInit } from '@angular/core';
import { DocumentPageService } from '../documentpage.service';
import { ProductService } from '../../../productmanager/product/product.service';
import { DocumentService } from '../../document/document.service';
import { OrderService } from '../../../ordermanager/order/order.service';
import { SharedService } from '../../../shared/shared.service';
import { OrderProductsService } from '../../../orderproductmanager/orderproducts/orderproducts.service';
import { Subscription } from 'rxjs';
import { DocumentFolderService } from '../../folder/documentFolder.service';
import { DocumentSubfolderService } from '../../subfolder/documentSubfolder.service';

@Component({
  selector: 'app-document-page',
  templateUrl: './items.component.html',
  styleUrls: ['./items.component.css']
})
export class DocumentPageComponent implements OnInit {
  @Input() idCustomer: number = null;
  loading: boolean;
  productLoading: boolean;
  certificateLoading: boolean;
  folderLoading: boolean;
  products: any;
  orders: any;
  documents: any[];
  filter: any;
  productList: any;
  certificateProductList: any;
  filterSubscription: Subscription;
  folderList: any;

  constructor(
    private service: DocumentPageService,
    private documentService: DocumentService,
    private productService: ProductService,
    private orderService: OrderService,
    private orderProductService: OrderProductsService,
    private sharedService: SharedService,
    private folderService: DocumentFolderService,
    private subfolderService: DocumentSubfolderService
  ) { }

  ngOnInit() {
    console.log(this.idCustomer);
    this.filterSubscription = this.service.currentFilter.subscribe(filter => {
      this.filter = filter;
      if (this.filter.idCustomer) {
        this.filter.idCustomer = null;
      }

    });
    if (this.documents == undefined) {
      this.documents = new Array();
    }

    if (this.idCustomer != undefined) {
      this.filter.idCustomer = this.idCustomer;
    }

    var productfilter = Object();
    productfilter.status = 10;
    productfilter.customer = this.idCustomer;
    this.productService.getCustomerDocumentsProducts(productfilter).subscribe((result) => {
      this.products = result.items;
    });
    this.orderService.getOrderWithDocuments(this.idCustomer).subscribe((result) => {
      this.orders = result;
    });
    this.refreshItems();
  }

  ngOnDestroy() {

    if (this.filterSubscription != null) {
      this.filterSubscription.unsubscribe();
    }

  }

  refreshItems() {
    this.folderLoading = true;
    this.filter.hasCertificate = false;
    this.service.getFolderList(this.filter).subscribe((result) => {
      this.folderList = result;
      this.folderLoading = false;
    });

    this.certificateLoading = true;
    this.filter.hasCertificate = false;
    this.service.getProductList(this.filter).subscribe((result) => {
      this.productList = result;
      this.certificateLoading = false;
    });

    this.productLoading
    this.filter.hasCertificate = true;
    this.service.getProducCertificatetList(this.filter).subscribe((result) => {
      this.certificateProductList = result;
      this.productLoading = false;
    });
    /*this.service.getAll(this.filter).subscribe((result) => {
      this.documents = result;
      this.loading = false;
    });*/

  }

  resetFilter() {
    this.filter = new Object();

    if (this.idCustomer != undefined) {
      this.filter.idCustomer = this.idCustomer;
    }

    this.refreshItems();

    this.service.changeFilter(this.filter);
  }

  getDocument(product,folder,subfolder) {
    if (product.details == undefined) {
      product.loading = true;
      var singlefilter = JSON.parse(JSON.stringify(this.filter));
      singlefilter.idProduct = product.id;
      if (folder) {
        singlefilter.idFolder = folder.id;
      }
      if (subfolder) {
        singlefilter.idSubfolder = subfolder.id;
      }
      singlefilter.hasCertificate = false;
      this.service.getDocumentFromProduct(singlefilter).subscribe((result) => {
        product.details = result;
        product.loading = false;
      });
    }
   
  }

  getCertificateDocument(product) {
    if (product.details == undefined) {
      product.loading = true;
      var singlefilter = JSON.parse(JSON.stringify(this.filter));
      singlefilter.idProduct = product.id;
      singlefilter.hasCertificate = true;
      this.service.getCertificateDocumentFromProduct(singlefilter).subscribe((result) => {
        product.details = result;
        product.loading = false;
      });
    }

  }


  getFolderProductESubfolder(folder) {
    if (folder.products == undefined || folder.subfolderList == undefined) {
      folder.productloading = true;
      folder.subfolderloading = true;
      if (folder.products == undefined) {
        var productfilter = Object();
        productfilter.status = 10;
        productfilter.customer = this.idCustomer;
        productfilter.folder = folder.id;
        this.productService.getCustomerDocumentsProducts(productfilter).subscribe((result) => {
          folder.products = result.items;
          folder.productloading = false;
        });
      }

      if (folder.subfolderList == undefined) {
        var singlefilter = JSON.parse(JSON.stringify(this.filter));
        if (folder) {
          singlefilter.idFolder = folder.id;
        }
        this.service.getSubFolderList(singlefilter).subscribe((result) => {
          folder.subfolderList = result;
          folder.subfolderloading = false;
        });
      }
      
      
    }
  }

  getSubfolderProduct(folder,subfolder) {
    if (subfolder.products == undefined) {
      subfolder.loading = true;
      var productfilter = Object();
      productfilter.status = 10;
      productfilter.customer = this.idCustomer;
      productfilter.folder = folder.id;
      productfilter.subfolder = subfolder.id;
      this.productService.getCustomerDocumentsProducts(productfilter).subscribe((result) => {
        subfolder.products = result.items;
        subfolder.loading = false;
      });
    }
  }

  getDocumentOrder(order, product, folder, subfolder) {
    if (order.orderProductsDocumentList == undefined) {
      order.loading = true;
      var singlefilter = JSON.parse(JSON.stringify(this.filter));
      
      singlefilter.idOrder = order.id;
      if (folder) {
        singlefilter.idFolder = folder.id;
      }
      if (subfolder) {
        singlefilter.idSubfolder = subfolder.id;
      }
      if (product != undefined) {
        singlefilter.idProduct = product.id;
      }
      singlefilter.hasCertificate = false;
      this.service.getDocumentFromOrder(singlefilter).subscribe((result) => {
        order.orderProductsDocumentList = result;
        order.loading = false;
      });
    }
  }

  getCertificateDocumentFromOrder(order, product) {
    if (order.orderProductsDocumentList == undefined) {
      order.loading = true;
      var singlefilter = JSON.parse(JSON.stringify(this.filter));
      
      singlefilter.idOrder = order.id;
      if (product != undefined) {
        singlefilter.idProduct = product.id;
      }
      singlefilter.hasCertificate = true;
      this.service.getCertificateDocumentFromOrder(singlefilter).subscribe((result) => {
        order.orderProductsDocumentList = result;
        order.loading = false;
      });
    }
  }

  filterElements(id) {
    if (this.documents != undefined) {
      return this.documents.filter(item => id == item.idProduct)[0];
    }
  }

  downloadFile(document) {
    this.documentService.downloadFile(document);
  }

  downloadCert(idOrderProduct) {
    this.orderProductService.DownloadCertificateFile(idOrderProduct);
  }


  downloadZip() {
    this.loading = true;
    this.documentService.downloadZip(this.filter).subscribe(result => {
      this.sharedService.downloadFromUrl(result);
      this.loading = false;
    });

  }

}
