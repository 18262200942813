import { BrowserModule, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { NgModule, LOCALE_ID, OnInit, APP_INITIALIZER } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { JwtModule, JWT_OPTIONS } from '@auth0/angular-jwt';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing.module';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { ErrorComponent } from './error/error.component';
//import { registerLocaleData } from '@angular/common';
//import localeIt from '@angular/common/locales/it';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { GestureConfig } from '@angular/material/core';
import { MaterialModule } from './material/material.module';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { SidebarMenuComponent } from './sidebar-menu/sidebar-menu.component';
import { SidebarMenuButtonComponent } from './sidebar-menu-button/sidebar-menu-button.component';
import { SidebarMenuElementComponent } from './sidebar-menu/sidebar-menu-element/sidebar-menu-element.component';
import { MainMenuComponent } from './main-menu/main-menu.component';
import { MainMenuElementComponent } from './main-menu/main-menu-element/main-menu-element.component';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { SharedModule } from './shared/shared.module';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { PpBreadcrumbsModule } from 'pp-breadcrumbs';
import { SharedService } from './shared/shared.service';
import { OrderManagerModule } from './ordermanager/ordermanager.module';
import { ExternalOrderManagerModule } from './externalordermanager/externalordermanager.module';
import { SessionExpiredComponent } from './session-expired/session-expired.component';
import { ChartsModule } from 'ng2-charts';
import { CourseSessionManagerModule } from './coursesessionmanager/coursesessionmanager.module';
import { WorkorderManagerModule } from './workordermanager/workordermanager.module';
import { OrderProductManagerModule } from './orderproductmanager/orderproductmanager.module';
import { WorkOrderDocumentManagerModule } from './workorderdocumentmanager/workorderdocumentmanager.module';
import { VerifyTokenComponent } from './verify-token/verify-token.component';
import { WorkOrderProductDocumentComponent } from './workorderproductdocumentmanager/document/items/items.component';
import { WorkOrderProductDocumentManagerModule } from './workorderproductdocumentmanager/workorderproductdocumentmanager.module';


//registerLocaleData(localeIt);

export function tokenGetter() {
  return localStorage.getItem("jwt");
}

export function getToken() { return localStorage.getItem("jwt"); }
export const whitelistedDomains = [new RegExp('[\s\S]*')] as RegExp[];
export function jwtOptionsFactory() { return { tokenGetter: getToken, whitelistedDomains: whitelistedDomains }; }


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LoginComponent,
    ErrorComponent,
    HeaderComponent,
    FooterComponent,
    SidebarMenuComponent,
    SidebarMenuButtonComponent,
    SidebarMenuElementComponent,
    MainMenuComponent,
    MainMenuElementComponent,
    UserProfileComponent,
    ForgotPasswordComponent,
    SessionExpiredComponent,
    VerifyTokenComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    FormsModule,
    AppRoutingModule,
    NgbModule,
    BrowserAnimationsModule,
    MaterialModule,
    SharedModule,
    PpBreadcrumbsModule,
    OrderManagerModule,
    OrderProductManagerModule,
    ExternalOrderManagerModule,
    CourseSessionManagerModule,
    WorkorderManagerModule,
    WorkOrderDocumentManagerModule,
    WorkOrderProductDocumentManagerModule,
    JwtModule.forRoot({ jwtOptionsProvider: { provide: JWT_OPTIONS, useFactory: jwtOptionsFactory } }),
  ],
  providers: [
    { provide: HAMMER_GESTURE_CONFIG, useClass: GestureConfig }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
