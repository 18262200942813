import { Injectable, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { ReCaptchaV3Service } from 'ngx-captcha';
import { SharedService } from './shared.service';

@Injectable()
export class RecaptchaV3 implements OnDestroy {
  private executionSubscription: Subscription;

  constructor(private recaptchaV3Service: ReCaptchaV3Service,private sharedService: SharedService) { }

  execute(delegateFunction: Function, action: string = null): string {
    let recaptchaToken = null;
    if (this.executionSubscription)
      this.executionSubscription.unsubscribe();

    var apiKey = this.sharedService.getRecaptchaApiKey();

    this.recaptchaV3Service.execute(apiKey, 'homepage', (token) => {
      if (token) {
        recaptchaToken = token;
        if (action)
          console.debug("recaptcha action: " + action);

        console.debug("recaptcha token: " + token);
        delegateFunction();
      }
    });

    return recaptchaToken;
  }

  ngOnDestroy(): void {
    console.debug("recaptcha ngOnDestroy");

    if (this.executionSubscription)
      this.executionSubscription.unsubscribe();
  }
}
