import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { WorkOrderService } from '../workorder.service';
import { Observable, Subject } from 'rxjs';
import { DictionaryService } from '../../../shared/dictionary/dictionary.service';
import { Sort, MatTableDataSource } from '@angular/material';
import { map } from 'rxjs/operators';
import { CustomerService } from '../../../customermanager/customer/customer.service';
import { AuthService } from '../../../_services/auth.service';
import { PhaseService } from '../../phase/Phase.service';

@Component({
  selector: 'app-workorder-phase',
  templateUrl: './workorder-phase.component.html',
  styleUrls: ['./workorder-phase.component.css']
})
export class WorkOrderPhaseComponent implements OnInit {
  @Input() workOrder: any;
  @Input() area: boolean;
  @Output() changedEvent = new EventEmitter();
  items: any;
  filter: any;
  loading: boolean;
  page: number = 1;
  pageSize: number = 10;
  itemsCount: number;
  modalTitle: string;
  currentItem: any;

  constructor(private modalService: NgbModal,
    private service: WorkOrderService,
    private phaseService: PhaseService) { }

  ngOnInit() {
    this.filter = new Object();
    this.filter.idArea = this.area;
    this.filter.idWorkOrder = this.workOrder;
    this.refreshItems();
  }

  refreshItems() {
    this.loading = true;
    this.filter.skip = (this.page - 1) * this.pageSize;
    this.filter.take = this.pageSize;
    this.phaseService.getWorkOrderPhase(this.filter)
      .subscribe(result => {
        this.items = result.items;
        this.itemsCount = result.count;
        this.loading = false;
      }, error => {
        this.loading = false;
      })
  }
  delete() {
    this.service.unbindEquipment(this.currentItem.id).subscribe(result => {
      this.refreshItems();
      this.changedEvent.emit(result)
    });
  }

  openModal(modal: NgbModalRef, item: any) {
    this.modalTitle = "ELIMINA";
    this.currentItem = item;
    this.modalService.open(modal).result.then((result) => {
      if (result === 'confirm')
        this.delete();

      this.currentItem = null;

    }, (reason) => {
      this.currentItem = null;
    });
  }

  onChange($event) {
    this.changedEvent.emit($event)
  }
}
