import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CourseSessionManagerRoutingModule } from './coursesessionmanager-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from '../shared/shared.module';
import { CourseSessionService } from './coursesession/coursesession.service';
import { CourseSessionComponent } from './coursesession/items/items.component';
import { CourseSessionDetailComponent } from './coursesession/detail/detail.component';
import { CourseSessionExistsDirective } from './coursesession/coursesession-exists.directive';
import { CourseSessionPartecipantRootComponent } from './coursesessionpartecipant/root/root.component';
import { CourseSessionPartecipantComponent } from './coursesessionpartecipant/items/items.component';
import { CourseSessionPartecipantDetailComponent } from './coursesessionpartecipant/detail/detail.component';



@NgModule({
  imports: [
    CommonModule,
    CourseSessionManagerRoutingModule,
    FormsModule,
    NgbModule,
    SharedModule,
    ReactiveFormsModule
  ],
  declarations: [
    CourseSessionComponent,
    CourseSessionDetailComponent,
    CourseSessionExistsDirective,
    CourseSessionPartecipantRootComponent,
    CourseSessionPartecipantComponent,
    CourseSessionPartecipantDetailComponent
  ]
})
export class CourseSessionManagerModule { }
