import { Component, OnInit, Input, Output, EventEmitter, Directive } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { AuthService } from '../../../_services/auth.service';
import { ProductService } from '../../../productmanager/product/product.service';
import { CustomerService } from '../../../customermanager/customer/customer.service';
import { Sort, MatTableDataSource } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';
import { CustomerProductionSiteService } from '../../../customermanager/productionsite/customerProductionSite.service';
import { CustomerSubcontractService } from '../../../customermanager/subcontract/customerSubcontract.service';
import { OrderProductsService } from '../../../orderproductmanager/orderproducts/orderproducts.service';
import { WorkOrderProductDocumentService } from '../../../workorderproductdocumentmanager/document/workorderproductdocument.service';

@Component({
  selector: 'app-work-order-products',
  templateUrl: './items.component.html',
  styleUrls: ['./items.component.css']
})
export class WorkOrderProductsComponent implements OnInit {
  @Input() order: any;
  @Input() workOrder: any;
  @Input() editMode: boolean;
  @Input() onlyView: boolean;
  @Input() activityStarted: boolean;
  @Output() refreshEvent = new EventEmitter();
  @Input() area: number;
  items: any[];
  filter: any;
  currentItem: any;
  userPermissions: any;
  loading: boolean;
  page: number = 1;
  pageSize: number = 10;
  itemsCount: number;
  modalTitle: string;
  selectedOrderProduct: any;
  sortedData: any;
  activeModal: any;
  selectedDocument: any;
  documentModal: any;


  constructor(
    private service: OrderProductsService,
    private modalService: NgbModal,
    private authService: AuthService,
    private documentService: WorkOrderProductDocumentService
   
  ) { }

  ngOnInit() {
    this.userPermissions = this.authService.currentPermissions();
    if (this.hasRoles('cliente')) {
      this.onlyView = true;
    }

    this.filter = new Object();
    this.filter.status = 10;
    this.refreshItems();
  }

  refreshItems(withoutLoading?: boolean) {
    this.loading = true;
    

    this.filter.idArea = this.area;
    this.filter.skip = (this.page - 1) * this.pageSize;
    this.filter.take = this.pageSize;
    if (this.order) {
      this.filter.idOrder = this.order;
     
    }

    this.service.getByFilters(this.filter)
      .subscribe(result => {
        this.items = result.items;
        this.sortedData = result.items;
        this.items.forEach((item) => {
          var filter = Object();
          filter.status = 10;
          filter.workOrder = this.workOrder.id;
          filter.orderProduct = item.id;
          this.documentService.getDocumentByFilter(filter).subscribe(result => {
            item.document = result;
          });

          if (item.endDate)
            item.endDateCollapsed = (item.endDate).replace(/\:|\-|T/g, ''); //porto la data in un formato comparabile al todayDate
          else
            item.endDateCollapsed = '99999999999999';
        });
        this.itemsCount = result.count;
        this.loading = false;
      }, error => {
        this.loading = false;
      });
    
    
  }

  resetFilter() {
    this.filter = new Object();
    this.page = 1;
    this.refreshItems();
  }

  onPageChange($event) {
    this.refreshItems();
  }

  openModal(modal: NgbModalRef, item: any) {
    this.modalTitle = "Elimina";
    this.currentItem = item;
    this.modalService.open(modal).result.then((result) => {
      if (result === 'confirm')
        this.delete();

      this.currentItem = null;

    }, (reason) => {
      this.currentItem = null;
    });
  }

  delete() {
    this.service.delete(this.currentItem).subscribe(result => {
      this.refreshItems();
      this.refreshEvent.emit();
    });
  }


  hasRoles(roles: string) {
    return this.authService.hasRoles(roles);
  }

  sortData(sort: Sort) {
    const data = this.items.slice();
    if (!sort.active || sort.direction === '') {
      this.sortedData = data;
      return;
    }
    const isAsc = sort.direction === 'asc';

    this.filter.orderby = sort.active;
    this.filter.isAsc = isAsc;
    this.refreshItems(true);
  }


  openOrderProductsModalDetails(modal: NgbModalRef, orderProduct: number) {
    //this.productEditMode = true;

    if (orderProduct) {
      this.selectedOrderProduct = orderProduct;
    }
    this.activeModal = this.modalService.open(modal, { size: "lg" });
    this.activeModal.result.then((result) => {
      if (result && result.files.length > 0) {
        this.selectedOrderProduct = null;
      }
      else {
      }
    }, (reason) => {
    });
  }

  orderProductChange(result?: any, refresh?: boolean) {
    this.selectedOrderProduct = null;
    this.activeModal.close();
    this.refreshItems();
    this.refreshEvent.emit();

  }

  /*downloadFile(item) {
    this.service.GetCertificateFile(item);
  }*/
  downloadFile(item: any) {

    this.documentService.downloadFile(item.id);
  }

  editFile(modal: NgbModalRef, orderProduct: any) {
    //this.productEditMode = true;

    
    this.activeModal = this.modalService.open(modal, { size: "lg" });
    this.activeModal.result.then((result) => {
      
    }, (reason) => {
    });
  }

  openDocumentsModalDetails(modal: NgbModalRef,selectedOrderProduct:any, Document: any) {


    if (Document) {
      this.selectedDocument = Document.id;
    }
    else {
      this.selectedDocument = null;
    }

    if (selectedOrderProduct) {
      this.selectedOrderProduct = selectedOrderProduct;
    }
    else {
      this.selectedOrderProduct = null;
    }

    this.documentModal = this.modalService.open(modal);
    this.documentModal.result.then((result) => {
      if (result && result.files.length > 0) {

      }
      else {
      }
    }, (reason) => {
    });
  }

  workOrderDocumentChange(result?: any, refresh?: boolean) {

    this.refreshItems();
    if (this.documentModal != undefined) {
      this.documentModal.close()
    }
    else {
      this.modalService.dismissAll();
    }
    this.refreshEvent.emit();

  }

  isAdministrator() {
    return this.hasRoles('amministratore');
  }

  isProjectManager() {
    return this.hasRoles('projectmanager');
  }

  isWorker() {
    return this.hasRoles('lavoratore');
  }

  canUpdateDocument(itemDocument) {
    var userAreas = this.authService.currentArea().map(str => { return Number(str); });
    if (this.isProjectManager() && !userAreas.includes(this.area)) {
      return false;
    }
    if (this.isWorker() && itemDocument!= undefined ) {
      return false;
    }
    return true;
  }

}
