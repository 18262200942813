import { Location } from '@angular/common';
import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subscription } from 'rxjs';
import { ProductService } from '../../../productmanager/product/product.service';
import { CustomerService } from '../../../customermanager/customer/customer.service';
import { FormBuilder, FormGroup, Validators, FormsModule } from '@angular/forms';
import { AuthService } from '../../../_services/auth.service';
import { ErrorService } from '../../../_services/error.service';
import { SharedService } from '../../../shared/shared.service';
import { OrderProductsService } from '../../../orderproductmanager/orderproducts/orderproducts.service';
import { OrderProductsComponent } from '../../../orderproductmanager/orderproducts/items/items.component';
import { ExternalOrderService } from '../externalorder.service';

@Component({
  selector: 'app-external-order-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.css']
})
export class ExternalOrderDetailComponent implements OnInit {
  @Input() onlyView: boolean;
  @Input() order: any;
  @Output() closeEvent = new EventEmitter();
  item: any;
  editMode: boolean;
  userPermissions: any;
  isNew: boolean;
  products: any;
  customers: any;
  itemSubscription: Subscription;
  selectedCustomer: any;
  selectedOrderProduct: number;
  productEditMode: boolean;
  orderStatuses: any;
  openedModal: any;
  maxOrderDate: Date;
  @ViewChild('productList', { static: false }) productList: OrderProductsComponent;
  
  constructor(private route: ActivatedRoute,
    private service: ExternalOrderService,
    private orerProductsService: OrderProductsService,
    private productService: ProductService,
    private customerService: CustomerService,
    private modalService: NgbModal,
    private location: Location,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private errorService: ErrorService,
    private sharedService: SharedService,
    private router: Router) {
  }


  ngOnInit(): void {
    this.orderStatuses = this.sharedService.getOrderStatuses();
    this.userPermissions = this.authService.currentPermissions();
    if (this.order) {
      this.getItem(this.order.id);
    }
    else {
      this.itemSubscription = this.route.params.subscribe((params: Params) => {
        this.getItem(params.id);
      });
    }
   
    this.productService.getValues().subscribe(
      result => {
        this.products = result.items;
      }
    );

    this.customerService.getValues().subscribe(
      result => {
        this.customers = result.items;
      }
    );

    this.maxOrderDate = new Date();
  }

  ngOnDestroy(): void {
    this.itemSubscription.unsubscribe()
  }

  getItem(id: number, callack?: () => void): void {

    if (id > 0) {
      this.service.getById(id).subscribe(result => {
        this.item = result;
        this.customerService.getById(result.idCustomer).subscribe(result => {
          this.selectedCustomer = result;
        });
      });
    }
    else {
      if (this.userPermissions.order.create) {
        this.isNew = true;
        this.editMode = true;
        this.item = new Object();
        this.item.IdType = 2;
        this.item.status = 10;
      }
      else {
        this.errorService.showError("Non hai i permessi necessari per accedere");
      }
    }
    
  }

  goBack(): void {
    this.location.back();
  }

  edit(): void {
    this.editMode = true;
  }

  view(): void {
    var appo = this.item;

    this.getItem(this.item.id);
    this.editMode = false;
  }

  save(): void {
    this.service.save(this.item).subscribe(() => this.isNew ? this.goBack() : this.view());
  }

  openModal(modal: NgbModalRef) {
    this.modalService.open(modal);
  }

  openConfirmationModal(modal: NgbModalRef, item: any) {

  }


  openOrderProductsModal(modal: NgbModalRef, orderProduct: number) {
    this.productEditMode = true;

    this.selectedOrderProduct = null;
    this.openedModal = this.modalService.open(modal, {size:"lg"});
    this.openedModal.result.then((result) => {
      if (result && result.files.length > 0) {
      }
      else {
      }
    }, (reason) => {
    });
  }

  openOrderProductsModalDetails(modal: NgbModalRef, orderProduct: number) {
    this.productEditMode = false;

    if (orderProduct) {
      this.selectedOrderProduct = orderProduct;
    }
    this.openedModal = this.modalService.open(modal, { size: "lg" });
    this.openedModal.result.then((result) => {
      if (result && result.files.length > 0) {
       
      }
      else {
      }
    }, (reason) => {
    });
  }

  openModalProductDelete(modal: NgbModalRef, item: any) {
    this.openedModal = this.modalService.open(modal);
    this.openedModal.result.then((result) => {
      if (result === 'confirm')
        this.deleteProduct(item);

    }, (reason) => {
    });
  }

  deleteProduct(item: any) {
    this.orerProductsService.delete(item).subscribe(result => this.orderProductChange());
  }

  orderProductChange(result?: any, refresh?: boolean) {
    if (result) {
      this.item.id = result;
      this.isNew = false;
      //this.router.navigate(['order','detail', result]);
    }
    this.orerProductsService.getByOrder(this.item.id).subscribe(result => {
      this.item.orderProducts = result.items;
      if (result.price) {
        this.item.orderPrice = result.price;
      }
      if (result.hasTemplate) {
        this.item.hasTemplate = result.hasTemplate;
      }
      if (refresh) {
        this.productList.refreshItems();
      }
      if (this.openedModal) {
        this.openedModal.close();
      }
      else {
        this.modalService.dismissAll();
      }
      
    })
    
  }

  closeModal() {
    this.closeEvent.emit();
  }

  hasRoles(roles: string) {
    return this.authService.hasRoles(roles);
  }

  isAdministrator() {
    return this.hasRoles('amministratore');
  }
  canEdit(item) {
    if (this.isAdministrator()) {
      return true;
    }
  }
}
