import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { DocumentTypeService } from '../documentType.service';

@Component({
  selector: 'app-document-type-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.css']
})
export class DocumentTypeDetailComponent implements OnInit {
  @Input() modal: boolean;
  @Output() saveEvent = new EventEmitter();

  item: any;
  isNew: boolean;

  constructor(private route: ActivatedRoute, private service: DocumentTypeService, private location: Location) { }

  ngOnInit(): void {
    this.getItem();
  }

  getItem(): void {
    const id = +this.route.snapshot.paramMap.get('id');
    if (!this.modal && id > 0)
      this.service.getById(id).subscribe(result => this.item = result);
    else {
      this.isNew = true;
      this.item = new Object();
    }
  }

  save(): void {
    this.service.save(this.item).subscribe(() => { this.modal ? this.saveEvent.emit() : this.location.back() });
  }

}
