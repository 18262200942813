import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthService } from '../_services/auth.service';
import { OrderProductsComponent } from './orderproducts/items/items.component';
import { OrderProductsDetailComponent } from './orderproducts/detail/detail.component';




const routes: Routes = [
  {
    path: '',
    component: OrderProductsComponent,
    canActivate: [AuthService],
    data: {
      permission: {
        only: ['amministratore', 'cliente'],
        redirectTo: 'error'
      },
    },
  },
  {
    path: 'detail/:id',
    component: OrderProductsDetailComponent,
    canActivate: [AuthService],
    data: {
      permission: {
        only: ['amministratore', 'cliente'],
        redirectTo: 'error'
      },
      breadcrumbs: "Dettaglio"
    }
  },
  {
    path: 'new',
    component: OrderProductsDetailComponent,
    canActivate: [AuthService],
    data: {
      permission: {
        only: ['amministratore', 'cliente'],
        redirectTo: 'error'
      },
      breadcrumbs: "Nuovo"
    }
  },


];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class OrderProductManagerRoutingModule { }
