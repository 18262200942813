import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-document-subfolder-root',
  templateUrl: './root.component.html',
  styleUrls: ['./root.component.css']
})
export class DocumentSubfolderRootComponent implements OnInit {
 
  constructor(private route: ActivatedRoute) { }

  ngOnInit(): void {
   
  }
}
