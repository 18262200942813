import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { AuthService } from '../../_services/auth.service';
@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {
  model: any;
  @Input() userId: number;
  @Output() saveEvent = new EventEmitter();
  errors: boolean;
  errorMessage: string;
  requestSent: boolean;
  insertRecapitoComunicazioni: boolean;
  successful: boolean;

  constructor(private service: AuthService) { }

  ngOnInit() {
    this.model = new Object();
  }

  submit() {
    this.requestSent = true;
    this.errors = false;
    this.errorMessage = null;

    this.model.userId = this.userId;

    this.service.changePassword(this.model).subscribe(result => {
      this.saveEvent.emit();
      this.requestSent = false;
      this.successful = true;
    }, err => {
      this.requestSent = false;
      this.errors = true;
      if (err.status === 400)
        this.errorMessage = err.error;
    });
  }
}
