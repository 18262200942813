import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { AuthService } from '../../../_services/auth.service';
import { WorkOrderAreaDataService } from '../workOrderAreaData.service';

@Component({
  selector: 'app-workorder-area-data',
  templateUrl: './items.component.html',
  styleUrls: ['./items.component.css']
})
export class WorkOrderAreaDataComponent implements OnInit {
  @Input() workOrder: any;
  @Input() onlyView: boolean;
  @Input() newWorkOrder: any;
  @Output() refreshEvent = new EventEmitter();
  @Input() area: number;
  @Input() orderArea: any[];
  items: any[] = [];
  customers: any[];
  filter: any;
  currentItem: any;
  loading: boolean;
  page: number = 1;
  pageSize: number = 10;
  itemsCount: number;
  modalTitle: string;
  selectedEmployee: any;
  employeesModal: any;
  filterSubscription: Subscription;
  selectedAreaData: any;
  openedModal: any;

  constructor(private service: WorkOrderAreaDataService, private modalService: NgbModal,
    private authService: AuthService) { }

  ngOnInit() {
    this.filterSubscription = this.service.currentFilter.subscribe(filter => {
      this.filter = filter;
      if (this.filter.customer) {
        this.filter.customer = null;
      }

    });
    this.refreshItems();
  }

  ngOnDestroy() {

    if (this.filterSubscription != null) {
      this.filterSubscription.unsubscribe();
    }


  }

  refreshItems() {
    this.loading = true;
    this.filter.workOrder = this.workOrder.id;
    if (this.isWorker()) {
      this.filter.idWorker = this.authService.currentUserId();
    }
    this.filter.skip = (this.page - 1) * this.pageSize;
    this.filter.take = this.pageSize;
    if (!this.newWorkOrder) {
      this.service.getByFilters(this.filter)
        .subscribe(result => {
          this.items = result.items;
          this.itemsCount = result.count;
          this.loading = false;
        }, error => {
          this.loading = false;
        });
    }
    else {
      this.loading = false;
    }
    /*this.customerService.getValues().subscribe(result => {
      this.customers = result.items;
    });*/

    this.service.changeFilter(this.filter);
  }

  resetFilter() {
    this.filter = new Object();
    this.page = 1;
    this.refreshItems();
  }

  onPageChange($event) {
    this.refreshItems();
  }

  openModal(modal: NgbModalRef, item: any) {
    this.modalTitle = "Elimina";
    this.currentItem = item;
    this.modalService.open(modal).result.then((result) => {
      if (result === 'confirm')
        this.delete();
      this.refreshEvent.emit(this.workOrder.id)
      this.currentItem = null;

    }, (reason) => {
      this.currentItem = null;
    });
  }

  delete() {
    this.service.delete(this.currentItem).subscribe(result => this.refreshItems());
  }

  openEmployeesModalDetails(modal: NgbModalRef, Employees: number) {


    if (Employees) {
      this.selectedEmployee = Employees;
    }
    else {
      this.selectedEmployee = null;
    }
    this.employeesModal = this.modalService.open(modal);
    this.employeesModal.result.then((result) => {
      if (result && result.files.length > 0) {

      }
      else {
      }
    }, (reason) => {
    });
  }

  EmployeesChange(result?: any, refresh?: boolean) {

    this.refreshItems();
    if (this.employeesModal != undefined) {
      this.employeesModal.close()
    }
    else {
      this.modalService.dismissAll();
    }

  }

  openAreaDataModalDetails(modal: NgbModalRef, areaData: number) {
    //this.productEditMode = true;

    if (areaData) {
      this.selectedAreaData = areaData;
    }

    this.openedModal = this.modalService.open(modal, { size: "lg" });
    this.openedModal.result.then((result) => {
      if (result && result.files.length > 0) {
        this.selectedAreaData = null;
      }
      else {
      }
    }, (reason) => {
    });
  }


  hasRoles(roles: string) {
    return this.authService.hasRoles(roles);
  }

  isWorker() {
    return this.hasRoles('lavoratore');
  }

  workOrderAreaDataChange(result?: any, refresh?: boolean) {
    this.selectedAreaData = null;
    this.openedModal.close();
    this.refreshItems();
    this.refreshEvent.emit();

  }
}
