import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root',
})

export class UsermanagerService {
  private filterSource = new BehaviorSubject(new Object());
  currentFilter = this.filterSource.asObservable();

  constructor(private http: HttpClient, @Inject('BASE_URL') private baseUrl: string) { }

  getByFilters(filter: any): Observable<any> {
    return this.http.post<any[]>(this.baseUrl + `api/UserData/GetByFilters`, filter, httpOptions)
      .pipe(
        catchError(this.handleError<any[]>(`getByFilters`, []))
      );
  }

  getById(id: number): Observable<any> {
    return this.http.get<any>(this.baseUrl + `api/UserData/GetById/${id}`)
      .pipe(
        catchError(this.handleError<any>(`getById id=${id}`, undefined))
      );
  }

  getCurrent(): Observable<any> {
    return this.http.get<any>(this.baseUrl + `api/UserData/getCurrent`);
  }

  saveUser(user: any): Observable<any> {
    return this.http.post(this.baseUrl + `api/UserData/Save`, user, httpOptions).pipe(
      catchError(this.handleError<any>('saveUser'))
    );
  }

  toggleStatus(user: any): Observable<any> {
    return this.http.post(this.baseUrl + `api/UserData/ToggleStatus`, user, httpOptions).pipe(
      catchError(this.handleError<any>('toggleStatus'))
    );
  }

  delete(user: any): Observable<any> {
    return this.http.post(this.baseUrl + `api/UserData/Delete`, user, httpOptions).pipe(
      catchError(this.handleError<any>('delete'))
    );
  }

  usernameExists(username: string): Observable<boolean> {
    return this.http.post<boolean>(this.baseUrl + `api/UserData/UsernameExists`, JSON.stringify(username), httpOptions);
  }

  emailExists(email: string): Observable<boolean> {
    return this.http.post<boolean>(this.baseUrl + `api/UserData/EmailExists`, JSON.stringify(email), httpOptions);
  }


  getByArea(filter: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + `api/UserData/GetByArea`, filter, httpOptions)
      .pipe(
        catchError(this.handleError<any>(`getByArea`, undefined))
      );
  }

  getByIdArea(filter: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + `api/UserData/GetByIdArea`, filter, httpOptions)
      .pipe(
        catchError(this.handleError<any>(`GetByIdArea`, undefined))
      );
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  changeFilter(filter: any) {
    this.filterSource.next(filter);
  }

  clearFilter() {
    this.filterSource = new BehaviorSubject(new Object());
  }
}
