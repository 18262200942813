import { Directive } from '@angular/core';
import { AsyncValidator, AbstractControl, ValidationErrors, NG_ASYNC_VALIDATORS } from '@angular/forms';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UsermanagerService } from '../usermanager/usermanager.service';

@Directive({
  selector: '[appUsernameExists]',
  providers: [{ provide: NG_ASYNC_VALIDATORS, useExisting: UsernameExistsDirective, multi: true }]
})
export class UsernameExistsDirective implements AsyncValidator {

  constructor(private userService: UsermanagerService) { }

  validate(ctrl: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> {
    return this.userService.usernameExists(ctrl.value).pipe(map(
      result => {
        return (result ? { usernameExists: true } : null)
      }));
  }
}
