import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root',
})

export class ProductService {

  private filterSource = new BehaviorSubject(new Object());
  currentFilter = this.filterSource.asObservable();

  CONTROLLER_NAME = "Product";

  constructor(private http: HttpClient, @Inject('BASE_URL') private baseUrl: string) { }

  getByFilters(filter: any): Observable<any> {
    return this.http.post<any[]>(this.baseUrl + `api/${this.CONTROLLER_NAME}/GetByFilters`, filter, httpOptions);
  }

  getValues(): Observable<any> {
    return this.getByFilters({ status: 10, orderby: "product" });
  }

  getValuesSalable(): Observable<any> {
    return this.getByFilters({ status: 10, saleability: true });
  }

  getById(id: number): Observable<any> {
    return this.http.get<any>(this.baseUrl + `api/${this.CONTROLLER_NAME}/GetById/${id}`);
  }

  getCustomerProducts(filter: any): Observable<any> {
    return this.http.post<any[]>(this.baseUrl + `api/${this.CONTROLLER_NAME}/GetCustomerProducts`, filter, httpOptions);
  }

  getCustomerProductsValues(idCustomer: number = null): Observable<any> {
    return this.getCustomerProducts({ status: 10, customer: idCustomer});
  }

  getCustomerDocumentsProducts(filter: any): Observable<any> {
    return this.http.post<any[]>(this.baseUrl + `api/${this.CONTROLLER_NAME}/GetCustomerDocumentsProducts`, filter, httpOptions);
  }

  save(item: any): Observable<any> {
    return this.http.post(this.baseUrl + `api/${this.CONTROLLER_NAME}/Save`, item, httpOptions);
  }

  delete(item: any): Observable<any> {
    return this.http.post(this.baseUrl + `api/${this.CONTROLLER_NAME}/Delete`, item, httpOptions);
  }

  toggleStatus(user: any): Observable<any> {
    return this.http.post(this.baseUrl + `api/${this.CONTROLLER_NAME}/ToggleStatus`, user, httpOptions).pipe(
      catchError(this.handleError<any>('toggleStatus'))
    );
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  itemExists(Title: string, Id: string): Observable<boolean> {
    var item: any = new Object();
    if (Title != undefined) {
      item.title = Title;
    }
    if (Id != undefined) {
      item.id = parseInt(Id); 
    }
    
    return this.http.post<boolean>(this.baseUrl + `api/${this.CONTROLLER_NAME}/ItemExists`, item, httpOptions);
  }

  GetBestSeller(): Observable<any> {
    return this.http.get<any>(this.baseUrl + `api/${this.CONTROLLER_NAME}/GetBestSeller`);
  }

  GetLessSold(): Observable<any> {
    return this.http.get<any>(this.baseUrl + `api/${this.CONTROLLER_NAME}/GetLessSold`);
  }

  GetNumberExpiredProducts(): Observable<any> {
    return this.http.get<any>(this.baseUrl + `api/${this.CONTROLLER_NAME}/GetNumberExpiredProducts`);
  }

  GetNumberActiveProducts(): Observable<any> {
    return this.http.get<any>(this.baseUrl + `api/${this.CONTROLLER_NAME}/GetNumberActiveProducts`);
  }

  GetNumberExpiringProducts(): Observable<any> {
    return this.http.get<any>(this.baseUrl + `api/${this.CONTROLLER_NAME}/GetNumberExpiringProducts`);
  }

  productCodeExists(Description: string, Id: string): Observable<boolean> {
    var item: any = new Object();
    if (Description != undefined) {
      item.description = Description;
    }
    if (Id != undefined) {
      item.id = parseInt(Id);
    }

    return this.http.post<boolean>(this.baseUrl + `api/${this.CONTROLLER_NAME}/ProductCodeExists`, item, httpOptions);
  }

  getFormazioneProducts(): Observable<any> {
    return this.getByFilters({ status: 10, orderby: "product", area: 20 });
  }

  changeFilter(filter: any) {
    this.filterSource.next(filter);
  }

  clearFilter() {
    this.filterSource = new BehaviorSubject(new Object());
  }
}
