import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { SharedService } from '../../shared/shared.service';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root',
})

export class WorkOrderService {
  private filterSource = new BehaviorSubject(new Object());
  currentFilter = this.filterSource.asObservable();

  CONTROLLER_NAME = "WorkOrder";

  constructor(private sharedService: SharedService, private http: HttpClient, @Inject('BASE_URL') private baseUrl: string) { }

  getByFilters(filter: any): Observable<any> {
    return this.http.post<any[]>(this.baseUrl + `api/${this.CONTROLLER_NAME}/GetByFilters`, filter, httpOptions);
  }

  getValues(): Observable<any> {
    return this.getByFilters({ status: 10 });
  }

  getById(id: number): Observable<any> {
    return this.http.get<any>(this.baseUrl + `api/${this.CONTROLLER_NAME}/GetById/${id}`);
  }

  save(item: any): Observable<any> {
    return this.http.post(this.baseUrl + `api/${this.CONTROLLER_NAME}/Save`, item, httpOptions)
      .pipe(map(response => { this.sharedService.refreshData(); return response; }));
  }

  delete(item: any): Observable<any> {
    return this.http.post(this.baseUrl + `api/${this.CONTROLLER_NAME}/Delete`, item, httpOptions)
      .pipe(map(response => { this.sharedService.refreshData(); return response; }));
  }

  bindPhase(item: any, phaseId: number): Observable<any> {
    return this.http.post<any>(this.baseUrl + `api/${this.CONTROLLER_NAME}/BindPhase`, [JSON.stringify(item), JSON.stringify(phaseId)], httpOptions);
  }

  unbindEquipment(intersectionId: number): Observable<any> {
    return this.http.post<any>(this.baseUrl + `api/${this.CONTROLLER_NAME}/UnbindPhase`, JSON.stringify(intersectionId), httpOptions);
  }

  GetDeliveryLetterFile(item: any) {
    return this.http.post<any>(this.baseUrl + `api/${this.CONTROLLER_NAME}/GetDeliveryLetterFile/`, item, httpOptions)
      .subscribe(result => {
        this.sharedService.downloadFile(result.filename, result.data);
      });
  }

  DownloadDeliveryLetterFile(item: any) {
    return this.http.post<any>(this.baseUrl + `api/${this.CONTROLLER_NAME}/DownloadCertificateFile/`, item, httpOptions)
      .subscribe(result => {
        this.sharedService.downloadFile(result.filename, result.data);
      });
  }

  getWorkOrderStatusByFilters(filter: any): Observable<any> {
    return this.http.post<any[]>(this.baseUrl + `api/${this.CONTROLLER_NAME}/GetWorkOrderStatusByFilters`, filter, httpOptions);
  }

  changeFilter(filter: any) {
    this.filterSource.next(filter);
  }
}
