import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from '../shared/shared.module';
import { DocumentmanagerRoutingModule } from './documentmanager-routing.module';
import { DocumentTypeRootComponent } from './type/root/root.component';
import { DocumentTypeDetailComponent } from './type/detail/detail.component';
import { DocumentTypeExistsDirective } from './type/documentType-exists.directive';
import { DocumentTypeComponent } from './type/items/items.component';
import { DocumentPageComponent } from './documentpage/items/items.component';
import { DocumentRootComponent } from './document/root/root.component';
import { DocumentComponent } from './document/items/items.component';
import { DocumentDetailComponent } from './document/detail/detail.component';
import { DocumentFolderComponent } from './folder/items/items.component';
import { DocumentFolderDetailComponent } from './folder/detail/detail.component';
import { DocumentFolderExistsDirective } from './folder/documentFolder-exists.directive';
import { DocumentFolderRootComponent } from './folder/root/root.component';
import { DocumentSubfolderComponent } from './subfolder/items/items.component';
import { DocumentSubfolderDetailComponent } from './subfolder/detail/detail.component';
import { DocumentSubfolderExistsDirective } from './subfolder/documentSubfolder-exists.directive';
import { DocumentSubfolderRootComponent } from './subfolder/root/root.component';
import { DocumentIssuedComponent } from './issued/items/items.component';
import { DocumentIssuedDetailComponent } from './issued/detail/detail.component';
import { DocumentIssuedRootComponent } from './issued/root/root.component';

@NgModule({
  imports: [
    CommonModule,
    DocumentmanagerRoutingModule,
    FormsModule,
    NgbModule,
    SharedModule,
    ReactiveFormsModule
  ],
  declarations: [
    DocumentPageComponent,
    DocumentTypeComponent,
    DocumentTypeDetailComponent,
    DocumentTypeExistsDirective,
    DocumentTypeRootComponent,
    DocumentRootComponent,
    DocumentComponent,
    DocumentDetailComponent,
    DocumentFolderComponent,
    DocumentFolderDetailComponent,
    DocumentFolderExistsDirective,
    DocumentFolderRootComponent,
    DocumentSubfolderComponent,
    DocumentSubfolderDetailComponent,
    DocumentSubfolderExistsDirective,
    DocumentSubfolderRootComponent,
    DocumentIssuedComponent,
    DocumentIssuedDetailComponent,
    DocumentIssuedRootComponent],
  exports: [
    DocumentRootComponent,
    DocumentComponent,
    DocumentDetailComponent,
    DocumentPageComponent
  ]
})
export class DocumentManagerModule { }
