import { Directive } from '@angular/core';
import { AsyncValidator, AbstractControl, ValidationErrors, NG_ASYNC_VALIDATORS } from '@angular/forms';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DocumentTypeService } from './documentType.service';

@Directive({
  selector: '[appDocumentTypeExists]',
  providers: [{ provide: NG_ASYNC_VALIDATORS, useExisting: DocumentTypeExistsDirective, multi: true }]
})
export class DocumentTypeExistsDirective implements AsyncValidator {

  constructor(private service: DocumentTypeService) { }

  validate(ctrl: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> {
    return this.service.itemExists(ctrl.value).pipe(map(
      result => {
        return (result ? { itemExists: true } : null)
      }));
  }
}
