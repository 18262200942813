import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthService } from '../_services/auth.service';
import { CourseSessionDetailComponent } from './coursesession/detail/detail.component';
import { CourseSessionComponent } from './coursesession/items/items.component';


const allowedRoles = ['amministratore', 'lavoratore', 'projectmanager'];

const routes: Routes = [
  {
    path: '',
    component: CourseSessionComponent,
    canActivate: [AuthService],
    data: {
      permission: {
        only: allowedRoles,
        redirectTo: 'error'
      },
      breadcrumbs: "Sessioni"
    }
  }, {
    path: 'detail/:id',
    component: CourseSessionDetailComponent,
    canActivate: [AuthService],
    data: {
      permission: {
        only: allowedRoles,
        redirectTo: 'error'
      },
      breadcrumbs: "Dettaglio"
    }
  },
  {
    path: 'detail/:id/:edit',
    component: CourseSessionDetailComponent,
    canActivate: [AuthService],
    data: {
      permission: {
        only: allowedRoles,
        redirectTo: 'error'
      },
      breadcrumbs: "Dettaglio"
    }
  },
  {
    path: 'new',
    component: CourseSessionDetailComponent,
    canActivate: [AuthService],
    data: {
      permission: {
        only: allowedRoles,
        redirectTo: 'error'
      },
      breadcrumbs: "Nuovo"
    }
  }
  
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CourseSessionManagerRoutingModule { }
