import { Location } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { CourseSessionService } from '../coursesession.service';
import { SharedService } from '../../../shared/shared.service';
import { ProductService } from '../../../productmanager/product/product.service';
import { CourseSessionPartecipantComponent } from '../../coursesessionpartecipant/items/items.component';
import { CourseSessionPartecipantService } from '../../coursesessionpartecipant/courseSessionPartecipant.service';
import { AuthService } from '../../../_services/auth.service';

@Component({
  selector: 'app-coursesession-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.css']
})
export class CourseSessionDetailComponent implements OnInit {
  item: any;
  editMode: boolean;
  paramEdit: boolean;
  isNew: boolean;
  itemSubscription: Subscription;
  itemStatuses: any;
  products: any;
  selectedPartecipant: any;
  partecipants: any[];
  @ViewChild('partecipantList', { static: false }) partecipantList: CourseSessionPartecipantComponent;

  
  constructor(private route: ActivatedRoute,
    private service: CourseSessionService,
    private partecipantService: CourseSessionPartecipantService,
    private modalService: NgbModal,
    private location: Location,
    private sharedService: SharedService,
    private productService: ProductService,
    private router: Router,
    private authService: AuthService) {
  }


  ngOnInit(): void {
    this.itemStatuses = this.sharedService.getItemStatuses();
    this.itemSubscription = this.route.params.subscribe((params: Params) => {
      this.editMode = params.edit;
      this.paramEdit = params.edit;
      this.getItem(params.id);
    });

    this.productService.getFormazioneProducts().subscribe(result => {
      this.products = result.items;
    });
    
  }

  ngOnDestroy(): void {
    this.itemSubscription.unsubscribe()
  }

  getItem(id: number, callack?: () => void): void {
    if (id > 0) {
      this.service.getById(id).subscribe(result => {
        this.item = result;
        this.partecipantService.getValuesByCourseSession(this.item.id).subscribe(result => {
          this.partecipants = result;
        });
      });
    }
    else {
      this.isNew = true;
      this.editMode = true;
      this.item = new Object();
    }
  }

  goBack(): void {
    if (this.paramEdit) {
      this.router.navigate(
        ['/coursesession/']
      );
    }
    else {
      this.location.back();
    }
  }

  edit(): void {
    this.editMode = true;
  }

  view(): void {
    this.getItem(this.item.id);
    this.editMode = false;
  }

  save(): void {
    this.service.save(this.item).subscribe((id) => this.isNew ? this.router.navigate(['/coursesession/detail/' + id + '/1']) : this.view());
  }

  openModal(modal: NgbModalRef) {
    this.modalService.open(modal);
  }

  openPartecipantModalDetails(modal: NgbModalRef, partecipant: number) {


    if (partecipant) {
      this.selectedPartecipant = partecipant;
    }
    else {
      this.selectedPartecipant = null;
    }

    this.modalService.open(modal).result.then((result) => {
      if (result && result.files.length > 0) {

      }
      else {
      }
    }, (reason) => {
    });
  }

  partecipantChange(result?: any, refresh?: boolean) {
    if (result) {
      this.item.id = result;
      this.isNew = false;
      //this.router.navigate(['order','detail', result]);
    }
    this.partecipantService.getValuesByCourseSession(this.item.id).subscribe(result => {
      this.partecipants = result;
    });
    this.partecipantList.refreshItems();
    this.modalService.dismissAll();

  }


  hasRoles(roles: string) {
    return this.authService.hasRoles(roles);
  }

  isAdministrator() {
    return this.hasRoles('amministratore');
  }

  isProjectManager() {
    return this.hasRoles('projectmanager');
  }

  isWorker() {
    return this.hasRoles('lavoratore');
  }

  canEdit() {
    if (this.isAdministrator() || this.isProjectManager()) {
      return true
    }
  }
}
