import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { AuthService } from '../../_services/auth.service';
import {  Router } from '@angular/router';
import { SidenavService } from '../../shared/sidenav.service';

@Component({
  selector: 'app-sidebar-menu-element',
  templateUrl: './sidebar-menu-element.component.html',
  styleUrls: ['./sidebar-menu-element.component.css']
})
export class SidebarMenuElementComponent implements OnInit {
  @Input() item: any;
  isCollapsed: boolean = true;

  constructor(private authService: AuthService, private router: Router, private sidenavService: SidenavService) { }

  ngOnInit() {
    
  }

  ngOnChanges(changes: SimpleChanges) {
    this.show();
  }

  show() {
    let show = true;

    if (this.router.url.includes("/verifytoken")) {
      show = false;
    }
    
    if (this.item.loggedIn != null) {
      if (this.item.loggedIn)
        show = show && this.authService.isLoggedIn();
      else
        show = show && !this.authService.isLoggedIn();
    }

    if (this.item.roles != null) {
      show = show && this.authService.hasRoles(this.item.roles);
    }

    if (show && this.item.areas != null && !this.authService.hasRoles('amministratore')) {
      show = show && this.authService.hasAreas(this.item.areas);
    }

    return show;
  }

  isChildActive(item) {
    var exist = false;
    
    if (item.children != undefined && this.router.url!="/") {
      exist = item.children.some((item) =>
      {
        return this.router.isActive(item.routerLink, false);
      });
      if (exist) {
        this.isCollapsed = false;
      }
    }
    return exist; 
  }

  closeSideNav() {
    /*this.sidenavService.close();*/
  }
}
