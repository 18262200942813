import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthService } from '../_services/auth.service';
import { DocumentTypeComponent } from './type/items/items.component';
import { DocumentTypeDetailComponent } from './type/detail/detail.component';
import { DocumentPageComponent } from './documentpage/items/items.component';
import { DocumentTypeRootComponent } from './type/root/root.component';
import { DocumentFolderRootComponent } from './folder/root/root.component';
import { DocumentFolderComponent } from './folder/items/items.component';
import { DocumentFolderDetailComponent } from './folder/detail/detail.component';
import { DocumentSubfolderRootComponent } from './subfolder/root/root.component';
import { DocumentSubfolderComponent } from './subfolder/items/items.component';
import { DocumentSubfolderDetailComponent } from './subfolder/detail/detail.component';
import { DocumentIssuedRootComponent } from './issued/root/root.component';
import { DocumentIssuedComponent } from './issued/items/items.component';
import { DocumentIssuedDetailComponent } from './issued/detail/detail.component';


const allowedRoles = ['amministratore', 'cliente', 'projectmanager'];

const routes: Routes = [
  {
    path: '',
    component: DocumentPageComponent,
    canActivate: [AuthService],
    data: {
      permission: {
        only: allowedRoles,
        redirectTo: 'error'
      },
      breadcrumbs: "Documenti"
    }
  }, 
  {
    path: 'types',
    component: DocumentTypeRootComponent,
    data: {
      breadcrumbs: "Types"
    },    
    
    children: [
      {
        path: '',
        component: DocumentTypeComponent,
        canActivate: [AuthService],
        data: {
          permission: {
            only: allowedRoles,
            redirectTo: 'error'
          },
        },
      },
      {
        path: 'detail/:id',
        component: DocumentTypeDetailComponent,
        canActivate: [AuthService],
        data: {
          permission: {
            only: allowedRoles,
            redirectTo: 'error'
          },
          breadcrumbs: "Dettaglio"
        }
      },
      {
        path: 'new',
        component: DocumentTypeDetailComponent,
        canActivate: [AuthService],
        data: {
          permission: {
            only: allowedRoles,
            redirectTo: 'error'
          },
          breadcrumbs: "Nuovo"
        }
      }
    ]
  },
  {
    path: 'folder',
    component: DocumentFolderRootComponent,
    data: {
      breadcrumbs: "Cartelle"
    },

    children: [
      {
        path: '',
        component: DocumentFolderComponent,
        canActivate: [AuthService],
        data: {
          permission: {
            only: allowedRoles,
            redirectTo: 'error'
          },
        },
      },
      {
        path: 'detail/:id',
        component: DocumentFolderDetailComponent,
        canActivate: [AuthService],
        data: {
          permission: {
            only: allowedRoles,
            redirectTo: 'error'
          },
          breadcrumbs: "Dettaglio"
        }
      },
      {
        path: 'new',
        component: DocumentFolderDetailComponent,
        canActivate: [AuthService],
        data: {
          permission: {
            only: allowedRoles,
            redirectTo: 'error'
          },
          breadcrumbs: "Nuovo"
        }
      }
    ]
  },
  {
    path: 'subfolder',
    component: DocumentSubfolderRootComponent,
    data: {
      breadcrumbs: "Sottocartelle"
    },

    children: [
      {
        path: '',
        component: DocumentSubfolderComponent,
        canActivate: [AuthService],
        data: {
          permission: {
            only: allowedRoles,
            redirectTo: 'error'
          },
        },
      },
      {
        path: 'detail/:id',
        component: DocumentSubfolderDetailComponent,
        canActivate: [AuthService],
        data: {
          permission: {
            only: allowedRoles,
            redirectTo: 'error'
          },
          breadcrumbs: "Dettaglio"
        }
      },
      {
        path: 'new',
        component: DocumentSubfolderDetailComponent,
        canActivate: [AuthService],
        data: {
          permission: {
            only: allowedRoles,
            redirectTo: 'error'
          },
          breadcrumbs: "Nuovo"
        }
      }
    ]
  }, 
  {
    path: 'issued',
    component: DocumentIssuedRootComponent,
    data: {
      breadcrumbs: "Tipi"
    },

    children: [
      {
        path: '',
        component: DocumentIssuedComponent,
        canActivate: [AuthService],
        data: {
          permission: {
            only: allowedRoles,
            redirectTo: 'error'
          },
        },
      },
      {
        path: 'detail/:id',
        component: DocumentIssuedDetailComponent,
        canActivate: [AuthService],
        data: {
          permission: {
            only: allowedRoles,
            redirectTo: 'error'
          },
          breadcrumbs: "Dettaglio"
        }
      },
      {
        path: 'new',
        component: DocumentIssuedDetailComponent,
        canActivate: [AuthService],
        data: {
          permission: {
            only: allowedRoles,
            redirectTo: 'error'
          },
          breadcrumbs: "Nuovo"
        }
      }
    ]
  },
 
  
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DocumentmanagerRoutingModule { }
