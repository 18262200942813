import { AbstractControl, ValidationErrors, ValidatorFn, Validator, NG_VALIDATORS, FormControl, FormGroup } from '@angular/forms'
import { OrderService } from './order.service';
import { Directive, OnInit, forwardRef, Input, Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class orderValidatorService {

  constructor(private service: OrderService) {
  }

  public orderNumber(): ValidatorFn {
    return (formGroup: FormControl) => {
      const orderNumber = formGroup.get('orderNumber');
      const orderDate = formGroup.get('orderDate');
      const oldOrderNumber = formGroup.get('oldOrderNumber');

      if (orderNumber != null && orderDate != null) {
        if (orderDate.value != null) {
          var date = new Date(orderDate.value)
          var year = date.getFullYear();
        }

        this.service.itemExists(year, orderNumber.value, oldOrderNumber.value).subscribe(
          result => {
            if (result) {
              if (orderNumber != null) {
                orderNumber.setErrors({ itemExist: true });
                return { itemExist: true }
              }

            }
            else {
              if (orderNumber != null) {
                orderNumber.setErrors(null);
                return null;
              }
            }
          });
      }
      else {
        return null;

      }

    }
  }
}
