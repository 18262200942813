import { Location } from '@angular/common';
import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import * as moment from 'moment';
import { OrderProductCertificateService } from '../orderproductcertificate.service';
import { OrderProductsService } from '../../orderproducts/orderproducts.service';
import { NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DocumentCertificateComponent } from '../../../documentcertificatemanager/document/items/items.component';

@Component({
  selector: 'app-order-products-certificate-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.css']
})
export class OrderProductCertificateDetailComponent implements OnInit {
  @Input() orderProduct: any;
  @Input() modal: boolean;
  @Output() changedEvent = new EventEmitter();
  item: any;
  isNew: boolean;
  documentModal: any;
  selectedorderProductDocument: any;
  @ViewChild('documentList', { static: false }) documentList: DocumentCertificateComponent;


  constructor(private route: ActivatedRoute,
    private service: OrderProductCertificateService,
    private location: Location,
    private OrderProductsService: OrderProductsService,
    private modalService: NgbModal,
  ) {
  }


  ngOnInit(): void {
    this.getItem(this.orderProduct.idOrderProductCertificate);
  }


  getItem(id?: number): void {
    if (id > 0) {
      this.service.getById(id).subscribe(result => {
        
        this.item = result;
        
      });
    }
    else {
      this.isNew = true;
      this.item = new Object();
      if (this.orderProduct.product.template) {
        this.item.legislation = this.orderProduct.product.template.legislation;
      }
    }

  }

  goBack(): void {
    if (this.modal) {
      this.changedEvent.emit();
    }
    else {
      this.location.back();
    }

  }


  view(): void {
    if (this.modal) {
      this.changedEvent.emit(this.orderProduct);
    }
    else {
      this.getItem(this.orderProduct);
    }

  }

  save(): void {
    this.service.save(this.item).subscribe(result => {
      if (this.isNew) {
        this.item.id = result;
        this.orderProduct.idOrderProductCertificate = result;
        this.OrderProductsService.save(this.orderProduct).subscribe(() => this.changedEvent.emit(this.orderProduct));
      }
      else {
        this.changedEvent.emit(this.orderProduct)
      }
      
    });
  }
  generateCert(): void {
    this.service.save(this.item).subscribe(result => {
      if (this.isNew) {
        this.item.id = result;
        this.orderProduct.idOrderProductCertificate = result;
        this.OrderProductsService.save(this.orderProduct).subscribe(() => this.changedEvent.emit(this.orderProduct));
      }
      else {
        this.changedEvent.emit(this.orderProduct)
      }

    });
  }

  openOrderProductDocumentsModalDetails(modal: NgbModalRef, productDocument: number) {


    if (productDocument) {
      this.selectedorderProductDocument = productDocument;
    }
    else {
      this.selectedorderProductDocument = null;
    }
    this.documentModal = this.modalService.open(modal);
    this.documentModal.result.then((result) => {
      if (result && result.files.length > 0) {

      }
      else {
      }
    }, (reason) => {
    });
  }

  orderProductDocumentChange(result?: any, refresh?: boolean) {
    if (result) {
      this.item.id = result;
      this.isNew = false;
      //this.router.navigate(['order','detail', result]);
    }
    this.documentList.refreshItems();
    if (this.documentModal != undefined) {
      this.documentModal.close()
    }
    else {
      this.modalService.dismissAll();
    }

  }

}
