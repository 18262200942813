import { CommonModule } from '@angular/common';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MaterialModule } from '../material/material.module';
import { OwlDateTimeModule, OwlNativeDateTimeModule, OWL_DATE_TIME_FORMATS, OWL_DATE_TIME_LOCALE, DateTimeAdapter } from 'ng-pick-datetime';
import { NgxCaptchaModule } from 'ngx-captcha';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { ItemStatusPipe } from './item-status.pipe';
import { ItemVerifyDirective } from './item-verify.directive';
import { OrderStatusPipe } from './order-status.pipe';
import { UserRolePipe } from './user-role.pipe';
import { UserStatusPipe } from './user-status.pipe';
import { UsernameExistsDirective } from './username-exists.directive';
import { NgSelectModule } from '@ng-select/ng-select';
import { SharedService } from './shared.service';
import { NgxCurrencyModule } from "ngx-currency";
import { AtecoPipe } from './dictionary/ateco.pipe';
import { EllipsisPipe } from './ellipsis.pipe';
import { DateFnsDateTimeAdapter } from './date-fns-date-time-adapter.class';
import { CustomCurrencyPipe } from './custom-currency.pipe';
import { CustomerCategoryPipe } from './customer-category.pipe';
import { DocumentAreaPipe } from './document-area.pipe';
import { ExpertiseAreaPipe } from './expertise-area.pipe';
import { WorkOrderTypePipe } from './work-order-type.pipe';
import { WorkOrderStatusPipe } from './work-order-status.pipe';
import { DateGreaterOrEqualsDirective } from './date-greater-or-equals.directive';
import { ExpertiseAreaActivityPipe } from './expertise-area-activity.pipe';
import { ProgressBarComponent } from './progress-bar/progress-bar.component';
import { WorkOrderAreaDataStatusPipe } from './work-order-area-data-status.pipe';

export const MY_NATIVE_FORMATS = {
  timePickerInput: { hour: 'numeric', minute: 'numeric', hour12: false }
};

export const MY_MOMENT_FORMATS = {
  parseInput: 'dd/MM/yyyy LT || dd/MM/yyyy', // multiple date input types separated by ||
  fullPickerInput: 'dd/MM/yyyy LT',
  datePickerInput: 'dd/MM/yyyy',
  timePickerInput: 'LT',
  monthYearLabel: 'MMM yyyy',
  dateA11yLabel: 'dd/MM/yyyy',
  monthYearA11yLabel: 'MMMM yyyy',
};

@NgModule({

  declarations: [
    UserStatusPipe,
    UserRolePipe,
    ItemStatusPipe,
    OrderStatusPipe,
    UsernameExistsDirective,
    ItemVerifyDirective,
    ChangePasswordComponent,
    AtecoPipe,
    EllipsisPipe,
    CustomCurrencyPipe,
    CustomerCategoryPipe,
    DocumentAreaPipe,
    ExpertiseAreaPipe,
    WorkOrderTypePipe,
    WorkOrderStatusPipe,
    WorkOrderAreaDataStatusPipe,
    DateGreaterOrEqualsDirective,
    ExpertiseAreaActivityPipe,
    ProgressBarComponent
  ],
  imports: [
    CommonModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    NgxCaptchaModule,
    FormsModule,
    NgSelectModule,
    NgxCurrencyModule,
    MaterialModule
  ],
  exports: [
    UserStatusPipe,
    UserRolePipe,
    ItemStatusPipe,
    OrderStatusPipe,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    UsernameExistsDirective,
    ItemVerifyDirective,
    NgxCaptchaModule,
    ChangePasswordComponent,
    FormsModule,
    NgSelectModule,
    NgxCurrencyModule,
    MaterialModule,
    AtecoPipe,
    EllipsisPipe, 
    CustomCurrencyPipe,
    CustomerCategoryPipe,
    DocumentAreaPipe,
    ExpertiseAreaPipe,
    WorkOrderTypePipe,
    WorkOrderStatusPipe,
    WorkOrderAreaDataStatusPipe,
    DateGreaterOrEqualsDirective,
    ExpertiseAreaActivityPipe,
    ProgressBarComponent
  ],
  providers: [
    //{ provide: APP_INITIALIZER, useFactory: GetRecaptchaApiKey, deps: [SharedService] },
    { provide: DateTimeAdapter, useClass: DateFnsDateTimeAdapter },
    { provide: OWL_DATE_TIME_FORMATS, useValue: MY_MOMENT_FORMATS },
    { provide: OWL_DATE_TIME_LOCALE, useValue: 'it' },
  ]
})
export class SharedModule { }

export function GetRecaptchaApiKey(sharedService: SharedService) {
  var appo = sharedService.getRecaptchaApiKey();
  return sharedService.getRecaptchaApiKey();
}
