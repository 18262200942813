import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from '../shared/shared.module';
import { OrdermanagerRoutingModule } from './ordermanager-routing.module';
import { OrderComponent } from './order/items/items.component';
import { OrderDetailComponent } from './order/detail/detail.component';
import { DocumentManagerModule } from '../documentmanager/documentmanager.module';
import { DocumentCertificateManagerModule } from '../documentcertificatemanager/documentcertificatemanager.module';
import { WorkorderManagerModule } from '../workordermanager/workordermanager.module';
import { OrderProductManagerModule } from '../orderproductmanager/orderproductmanager.module';

@NgModule({
  imports: [
    CommonModule,
    OrdermanagerRoutingModule,
    FormsModule,
    NgbModule,
    SharedModule,
    ReactiveFormsModule,
    DocumentManagerModule,
    DocumentCertificateManagerModule,
    OrderProductManagerModule,
    WorkorderManagerModule
  ],
  declarations: [
    OrderComponent,
    OrderDetailComponent
  ]
})
export class OrderManagerModule { }
