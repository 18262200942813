import { Component, Input } from '@angular/core';
import { AuthService } from '../_services/auth.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent {
  @Input() title: string;
  constructor(private authService: AuthService) { }

  isLoggedin() {
    return this.authService.isLoggedIn();
  }

  logout() {
    this.authService.logout();
  }

  currentUser() {
    return this.authService.currentDisplayName();
  }

  currentCustomer() {

    return this.authService.currentCustomer();
  }

  hasRoles(roles: string) {
    return this.authService.hasRoles(roles);
  }


}
