import { Component, OnInit } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { CourseSessionService } from '../coursesession.service';
import { Observable, Subject, Subscription } from 'rxjs';
import { DictionaryService } from '../../../shared/dictionary/dictionary.service';
import { Sort, MatTableDataSource } from '@angular/material';
import { map } from 'rxjs/operators';
import { AuthService } from '../../../_services/auth.service';

@Component({
  selector: 'app-coursesession',
  templateUrl: './items.component.html',
  styleUrls: ['./items.component.css']
})
export class CourseSessionComponent implements OnInit {
  items: any[];
  filter: any;
  currentItem: any;
  userPermissions: any;
  loading: boolean;
  page: number = 1;
  pageSize: number = 10;
  itemsCount: number;
  modalTitle: string;
  sortedData: any;
  filterSubscription: Subscription;

  constructor(
    private service: CourseSessionService,
    private modalService: NgbModal,    
    private dictionaryService: DictionaryService,
    private authService: AuthService
  ) { }

  ngOnInit() {

    this.filterSubscription = this.service.currentFilter.subscribe(filter => this.filter = filter);

    this.refreshItems();
  }

  ngOnDestroy() {

    if (this.filterSubscription != null) {
      this.filterSubscription.unsubscribe();
    }

  }

  refreshItems() {
    this.loading = true;
    this.filter.skip = (this.page - 1) * this.pageSize;
    this.filter.take = this.pageSize;
    this.service.getByFilters(this.filter)
      .subscribe(result => {
        this.items = result.items;
        this.itemsCount = result.count;
        this.loading = false;
      }, error => {
        this.loading = false;
      })

    this.service.changeFilter(this.filter);
  }

  resetFilter() {
    this.filter = new Object();
    this.page = 1;
    this.refreshItems();
  }

  onPageChange($event) {
    this.refreshItems();
  }

  openModal(modal: NgbModalRef, item: any) {
    this.modalTitle = "ELIMINA";
    this.currentItem = item;
    this.modalService.open(modal).result.then((result) => {
      if (result === 'confirm')
        this.delete();

      this.currentItem = null;

    }, (reason) => {
      this.currentItem = null;
    });
  }

  delete() {
    this.service.delete(this.currentItem).subscribe(result => this.refreshItems());
  }

  toggleItem(modal: NgbModalRef, item: any) {
    if (item.status == 10) {
      this.modalTitle = "Disattiva";
    } else {
      this.modalTitle = "Attiva";
    }
    this.currentItem = item;
    this.modalService.open(modal).result.then((result) => {
      if (result === 'confirm')
        this.service.toggleStatus(item).subscribe(result => this.refreshItems());

      this.currentItem = null;

    }, (reason) => {
      this.currentItem = null;
    });

    
    //
  }

  sortData(sort: Sort) {
    const data = this.items.slice();
    if (!sort.active || sort.direction === '') {
      this.sortedData = data;
      return;
    }
    const isAsc = sort.direction === 'asc';

    this.filter.orderby = sort.active;
    this.filter.isAsc = isAsc;
    this.refreshItems();
  }

  hasRoles(roles: string) {
    return this.authService.hasRoles(roles);
  }

  isAdministrator() {
    return this.hasRoles('amministratore');
  }

  isProjectManager() {
    return this.hasRoles('projectmanager');
  }

  isWorker() {
    return this.hasRoles('lavoratore');
  }
}
