import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthService } from '../_services/auth.service';
import { ExternalOrderComponent } from './externalOrder/items/items.component';
import { ExternalOrderDetailComponent } from './externalOrder/detail/detail.component';




const routes: Routes = [
  {
    path: '',
    component: ExternalOrderComponent,
    canActivate: [AuthService],
    data: {
      permission: {
        only: ['amministratore', 'cliente', 'projectmanager'],
        redirectTo: 'error'
      },
      breadcrumbs: "Ordini"
    }
  }, {
    path: 'detail/:id',
    component: ExternalOrderDetailComponent,
    canActivate: [AuthService],
    data: {
      permission: {
        only: ['amministratore', 'cliente', 'projectmanager'],
        redirectTo: 'error'
      },
      breadcrumbs: "Dettaglio"
    }
  },
  {
    path: 'new',
    component: ExternalOrderDetailComponent,
    canActivate: [AuthService],
    data: {
      permission: {
        only: ['amministratore'],
        redirectTo: 'error'
      },
      breadcrumbs: "Nuovo"
    }
  },
 
  
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ExternalOrdermanagerRoutingModule { }
