import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})

export class ErrorService {
  private errorMessage: string;

  constructor(private router: Router) { }

  public showError(message: string) {
    this.errorMessage = message;
    this.router.navigate(["/error-page"]);
  }

  public getError(): string {
    if (this.errorMessage && this.errorMessage.length)
      return this.errorMessage;
    else
      this.router.navigate(["/"]);
  }
}
