import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Location } from '@angular/common';
import { DocumentService } from '../document.service';
import { Subscription } from 'rxjs';
import { ProductService } from '../../../productmanager/product/product.service';
import { OrderService } from '../../../ordermanager/order/order.service';
import { OrderProductsService } from '../../../orderproductmanager/orderproducts/orderproducts.service';
import { AuthService } from '../../../_services/auth.service';
import { SharedService } from '../../../shared/shared.service';
import { DocumentFolderService } from '../../folder/documentFolder.service';
import { DocumentSubfolderService } from '../../subfolder/documentSubfolder.service';

@Component({
  selector: 'app-document-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.css']
})
export class DocumentDetailComponent implements OnInit {
  @Input() modal: boolean;
  @Input() selectedDocument: any;
  @Input() product: any;
  @Input() orderproduct: any;
  @Input() order: any;
  @Input() productSection: boolean;
  @Input() orderSection: boolean;
  @Output() changedEvent = new EventEmitter();
  @Input() editMode: boolean;
  @Input() onlyView: boolean;
  @Output() saveProdEvent = new EventEmitter();

  itemSubscription: Subscription;
  item: any;
  isNew: boolean;
  products: any;
  documentArea: any;
  filesUpload: any;
  fileBlob: any;
  fileName: any;
  isAdministrator: boolean;
  folders: any;
  subfolders: any;


  constructor(private route: ActivatedRoute, private service: DocumentService, private productService: ProductService, private location: Location,
    private orderService: OrderService,
    private orderProductsService: OrderProductsService,
    private sharedService: SharedService,
    private authService: AuthService,
    private folderService: DocumentFolderService,
    private subfolderService: DocumentSubfolderService  ) { }

  ngOnInit(): void {

    this.isAdministrator = (this.authService.hasRoles("amministratore"));

    this.folderService.getValues().subscribe(result => {
      this.folders = result.items;
    });

    this.documentArea = this.sharedService.getDocumentArea();
    this.itemSubscription = this.route.params.subscribe((params: Params) => {
      if (!this.modal) {
        this.getItem(params.id);
      }
      else {
        this.getItem(this.selectedDocument);
      }
    });
    this.productService.getValues().subscribe(
      result => {
        this.products = result.items;
      });
    if (this.product) {

      this.editMode = true;
    }
  }

  getItem(id): void {
    if (id > 0)
      this.service.getById(id).subscribe(result => {
        this.item = result;
        if (this.item.idFolder) {
          this.subfolderService.getByFolderValues(this.item.idFolder).subscribe(result => {
            this.subfolders = result.items;
          });
        }
      });
    else {
      this.isNew = true;
      this.editMode = true;
      this.item = new Object();
      this.item.status = 10;
      if (this.product) {
        this.item.idProduct = this.product.id;
      }
      else if (this.orderproduct.id) {
        this.item.idOrderProduct = this.orderproduct.id;
      }
    }
  }


  goBack(): void {
    if (this.modal) {
      this.changedEvent.emit();
    }
    else {
      this.location.back();
    }
  }

  edit(): void {
    this.editMode = true;
  }

  view(): void {
    if (this.modal) {
      this.changedEvent.emit();
    }
    else {
      this.getItem(this.item.id);
      this.editMode = false;
    }
  }

  save(): void {
    if (this.fileBlob != undefined) {
      this.item.fileData = this.fileBlob;
      this.item.fileName = this.fileName;
    }
    else {
      this.item.fileData = null;
      this.item.fileName = null;
    }
    if (this.orderSection && this.item.idOrderProduct==undefined) {
      if (this.orderproduct.id == undefined) {
        if (this.orderproduct.idOrder == undefined) {
          this.orderService.save(this.order).subscribe(result => {
            this.orderproduct.idOrder = result;
            this.orderProductsService.save(this.orderproduct).subscribe(result => {
              this.item.idOrderProduct = result;
              var orderValues: any = new Object();
              orderValues.idOrderProduct = this.item.idOrderProduct;
              orderValues.idOrder = this.orderproduct.idOrder;
              this.saveProdEvent.emit(orderValues);
              this.service.save(this.item).subscribe(() => { this.modal ? this.changedEvent.emit() : this.location.back() });
            });
          });
        }
        else {
          this.orderProductsService.save(this.orderproduct).subscribe(result => {
            this.item.idOrderProduct = result;
            var orderValues: any = new Object();
            orderValues.idOrderProduct = this.item.idOrderProduct;
            orderValues.idOrder = this.orderproduct.idOrder;
            this.saveProdEvent.emit(orderValues);
            this.service.save(this.item).subscribe(() => { this.modal ? this.changedEvent.emit() : this.location.back() });
          });
        }

      }
    }
    else {
      this.service.save(this.item).subscribe(() => { this.modal ? this.changedEvent.emit() : this.location.back() });
    }
  }

  changeFile(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result.toString());
      reader.onerror = error => reject(error);
    });
  }

  uploadFile(event) {
    if (event.target.value) {
      const file = event.target.files[0];
      const fileType = file.type;
      this.changeFile(file).then((base64: string): any => {
        this.fileBlob = base64;
        this.fileName = file.name;
      });
    } else alert('Nothing')
  }

  downloadFile() {
    this.service.downloadFile(this.item);
  }

  onFolderChange($event, idFolder) {
    this.subfolderService.getByFolderValues(idFolder).subscribe(result => {
      this.subfolders = result.items;
    });
  }
}
