import { Component, OnInit } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subscription } from 'rxjs';
import { AuthService } from '../../../_services/auth.service';
import { ProductService } from '../../../productmanager/product/product.service';
import { CustomerService } from '../../../customermanager/customer/customer.service';
import { SharedService } from '../../../shared/shared.service';
import { ExternalOrderService } from '../externalorder.service';
import { Sort } from '@angular/material';

@Component({
  selector: 'app-external-order',
  templateUrl: './items.component.html',
  styleUrls: ['./items.component.css']
})
export class ExternalOrderComponent implements OnInit {
  items: any[];
  filter: any;
  currentItem: any;
  userPermissions: any;
  loading: boolean;
  page: number = 1;
  pageSize: number = 10;
  itemsCount: number;
  orderCounter: number = 0;
  todayDate: string;
  products: any;
  customers: any;
  modalTitle: string;
  orderStatuses: any;
  isCustomer: boolean = false;
  selectedOrder: any;
  openedModal: any;
  sortedData: any;
  orderRefs: any;
  filterSubscription: Subscription;

  constructor(
    private service: ExternalOrderService,
    private modalService: NgbModal,
    private authService: AuthService,
    private sharedService: SharedService,
    private productService: ProductService,
    private customerService: CustomerService,
  ) { }

  ngOnInit() {
    this.orderStatuses = this.sharedService.getOrderStatuses();
    this.userPermissions = this.authService.currentPermissions();
    var today = new Date(Date.now());

    if(this.hasRoles('cliente')){
      this.isCustomer = true;
    }


    this.todayDate = today.getFullYear().toString() +
      (("0" + today.getMonth() + 1).slice(-2)).toString() +
      (("0" + today.getDate()).slice(-2)).toString() +
      (("0" + today.getHours()).slice(-2)).toString() +
      (("0" + today.getMinutes()).slice(-2)).toString() +
      (("0" + today.getSeconds()).slice(-2)).toString();

    this.filterSubscription = this.service.currentFilter.subscribe(filter => this.filter = filter);

    if (this.isCustomer) {
      this.productService.getCustomerProductsValues().subscribe(
        result => {
          this.products = result.items;
        }
      );
    }
    else {
      this.productService.getValues().subscribe(
        result => {
          this.products = result.items;
        }
      );
    }
    

    this.customerService.getValues().subscribe(
      result => {
        this.customers = result.items;
      }
    );

    this.service.getOrderRef().subscribe(
      result => {
        this.orderRefs = result;
      }
    );
   
    this.refreshItems();
  }

  ngOnDestroy() {

    if (this.filterSubscription != null) {
      this.filterSubscription.unsubscribe();
    }

  }

  refreshItems() {
    this.loading = true;
    this.filter.skip = (this.page - 1) * this.pageSize;
    this.filter.take = this.pageSize;
    this.service.getByFilters(this.filter)
      .subscribe(result => {
        this.items = result.items;
        this.items.forEach((item) => {
          if (item.endDate)
            item.endDateCollapsed = (item.endDate).replace(/\:|\-|T/g, ''); //porto la data in un formato comparabile al todayDate
          else
            item.endDateCollapsed = '99999999999999';

          var nominativi;
          item.nominativi.forEach((nominativo) => {
            nominativi = (nominativi != undefined ? nominativi : "") + (nominativi != undefined ? "," : "") + nominativo;
          });
          item.listNominativi = nominativi
        });
        this.itemsCount = result.count;

        this.loading = false;
      }, error => {
        this.loading = false;
      });

    this.service.changeFilter(this.filter);
  }

  resetFilter() {
    this.filter = new Object();
    this.page = 1;
    this.refreshItems();
  }

  onPageChange($event) {
    this.refreshItems();
  }

  onCustomerChange($event) {
    if (this.filter.idCustomer) {
      this.productService.getCustomerProductsValues(this.filter.idCustomer).subscribe(
        result => {
          this.products = result.items;
        }
      );
    }
    else {
      this.productService.getValues().subscribe(
        result => {
          this.products = result.items;
        }
      );
    }
    
  }

  openModal(modal: NgbModalRef, item: any) {
    this.modalTitle = "Elimina";
    this.currentItem = item;
    this.modalService.open(modal).result.then((result) => {
      if (result === 'confirm')
        this.delete();

      this.currentItem = null;

    }, (reason) => {
      this.currentItem = null;
    });
  }

  delete() {
    this.service.delete(this.currentItem).subscribe(result => this.refreshItems());
  }

  orderByOrderStatus() {
    if ((this.orderCounter % 2) > 0) {
      this.items.sort((a, b) => {
        return (a.endDateCollapsed > b.endDateCollapsed) ? 1 : -1;
      });
    } else {
      this.items.sort((a, b) => {
        return (a.endDateCollapsed < b.endDateCollapsed) ? 1 : -1;
      });

    }
    this.orderCounter++;
  }


  hasRoles(roles: string) {
    return this.authService.hasRoles(roles);
  }

  orderDetailModalOpen(modal: NgbModalRef, item: any) {
    this.selectedOrder = item;
    this.openedModal = this.modalService.open(modal, { size: "lg" });
    this.openedModal.result.then((result) => {
      
    }, (reason) => {
    });
  }

  orderDetailsClose(result?: any) {
    if (this.openedModal) {
      this.openedModal.close();
    }
    else {
      this.modalService.dismissAll();
    }
  }

  sortData(sort: Sort) {
    const data = this.items.slice();
    if (!sort.active || sort.direction === '') {
      this.sortedData = data;
      return;
    }
    const isAsc = sort.direction === 'asc';

    this.filter.orderby = sort.active;
    this.filter.isAsc = isAsc;
    this.refreshItems();
  }

  isAdministrator() {
    return this.hasRoles('amministratore');
  }

  canEdit(item) {
    if (this.isAdministrator()) {
      return true;
    }
  }
}
