import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Location } from '@angular/common';
import { CourseSessionPartecipantService } from '../courseSessionPartecipant.service';
import { Subject, Subscription } from 'rxjs';
import { OrderProductsService } from '../../../orderproductmanager/orderproducts/orderproducts.service';
import { CustomerService } from '../../../customermanager/customer/customer.service';

@Component({
  selector: 'app-course-session-partecipant-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.css']
})
export class CourseSessionPartecipantDetailComponent implements OnInit {
  @Input() modal: boolean;
  @Input() selectedPartecipant: any;
  @Input() courseSession: any;
  @Output() changedEvent = new EventEmitter();
  @Input() editMode: boolean;
  @Input() onlyView: boolean;

  itemSubscription: Subscription;
  item: any;
  customers: any;
  orderProducts: any[];
  isNew: boolean;
  loading: boolean = false;
  orderProductInput$ = new Subject<string | null>();

  constructor(private route: ActivatedRoute, private service: CourseSessionPartecipantService, private customerService: CustomerService, private orderProductService: OrderProductsService, private location: Location) { }

  ngOnInit(): void {
    this.itemSubscription = this.route.params.subscribe((params: Params) => {
      if (!this.modal) {
        this.getItem(params.id);
      }
      else {
        this.getItem(this.selectedPartecipant);
      }
    });

    let customerFilter: any = new Object();
    customerFilter.idProduct = this.courseSession.idProduct;
    customerFilter.idSession = this.courseSession.id;

    this.customerService.getForSession(customerFilter).subscribe(
      result => {
        this.customers = result.items;
      }
    );

  }

  loadOrderProduct(): void {
    let orderProductFilter: any = new Object();
    orderProductFilter.skip = 0;
    orderProductFilter.take = 100;
    orderProductFilter.IdCustomer = this.item.idCustomer;
    orderProductFilter.idProduct = this.courseSession.idProduct;
    orderProductFilter.idSession = this.courseSession.id;

    this.loading = true;
    this.orderProductService.getForSession(orderProductFilter).subscribe(result => {
      this.orderProducts = result.items;
      this.loading = false;
    });
    this.orderProductInput$.subscribe((term) => {
      this.loading = true;
      orderProductFilter.nominativo = term;

      this.orderProductService.getForSession(orderProductFilter)
        .subscribe(result => {
          this.loading = false;
          this.orderProducts = result.items;
        });
      

    });
  }

  changeCustomer(event) {
    this.loadOrderProduct();
  }

  getItem(id): void {
    if ( id > 0)
      this.service.getById(id).subscribe(result => this.item = result);
    else {
      this.isNew = true;
      this.editMode = true;
      this.item = new Object();
      if (this.courseSession) {
        this.item.idCourseSession = this.courseSession.id;
      }
    }
  }

  save(): void {
    this.service.save(this.item).subscribe(() => { this.modal ? this.changedEvent.emit() : this.location.back() });
  }

}
