import { Component, OnInit } from '@angular/core';
import { NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { SharedService } from '../../../shared/shared.service';
import { PhaseService } from '../Phase.service';

@Component({
  selector: 'app-phase',
  templateUrl: './items.component.html',
  styleUrls: ['./items.component.css']
})
export class PhaseComponent implements OnInit {
  items: any[];
  filter: any;
  currentItem: any;
  loading: boolean;
  page: number = 1;
  pageSize: number = 10;
  itemsCount: number;
  modalTitle: string;
  expertiseArea: any[];
  filterSubscription: Subscription;

  constructor(private service: PhaseService, private modalService: NgbModal,private sharedService: SharedService) { }

  ngOnInit() {
    this.filterSubscription = this.service.currentFilter.subscribe(filter => this.filter = filter);
    this.expertiseArea = this.sharedService.getExpertiseAreas();
    this.refreshItems();

  }

  ngOnDestroy() {

    if (this.filterSubscription != null) {
      this.filterSubscription.unsubscribe();
    }

  }

  refreshItems() {
    this.loading = true;
    this.filter.skip = (this.page - 1) * this.pageSize;
    this.filter.take = this.pageSize;
    this.service.getByFilters(this.filter)
      .subscribe(result => {
        this.items = result.items;
        this.itemsCount = result.count;
        this.loading = false;
      }, error => {
        this.loading = false;
      });

    this.service.changeFilter(this.filter);
  }

  resetFilter() {
    this.filter = new Object();
    this.page = 1;
    this.refreshItems();
  }

  onPageChange($event) {
    this.refreshItems();
  }

  openModal(modal: NgbModalRef, item: any) {
    this.modalTitle = "Elimina";
    this.currentItem = item;
    this.modalService.open(modal).result.then((result) => {
      if (result === 'confirm')
        this.delete();

      this.currentItem = null;

    }, (reason) => {
      this.currentItem = null;
    });
  }

  getExpertiseArea(): any[] {
    if (this.expertiseArea && this.expertiseArea.length) {
      return this.expertiseArea.filter(i => i.id != 30);
    }
    return null
  }

  delete() {
    this.service.delete(this.currentItem).subscribe(result => this.refreshItems());
  }
}
