import { Component, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AuthService } from '../_services/auth.service';
import { Router, Params, ActivatedRoute } from "@angular/router";
import { Location } from '@angular/common';
import { Subscription } from 'rxjs';
import { UsermanagerService } from '../usermanager/usermanager.service';
import { SharedService } from '../shared/shared.service';
import { RecaptchaV3 } from '../shared/recaptchaV3';

@Component({
  selector: 'login-form',
  templateUrl: './login.component.html',
  providers: [RecaptchaV3]
})
export class LoginComponent {
  itemSubscription: Subscription;
  invalidUsername: boolean;
  invalidLogin: boolean;
  requestSent: boolean;
  errors: boolean;
  errorMessage: string;
  model: any;
  @ViewChild('passwordField', { static: false }) passwordField: ElementRef;
  siteKey: string;
  returnUrl: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private service: AuthService,
    private sharedService: SharedService,
    private usermanagerService: UsermanagerService,
    private location: Location,
    private renderer: Renderer2,
    private recaptcha: RecaptchaV3) { }

  ngOnInit(): void {
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    this.siteKey = this.sharedService.getRecaptchaApiKey();
    this.itemSubscription = this.route.params.subscribe((params: Params) => {
      this.model = new Object();
    });
  }

  login() {
    this.recaptcha.execute(() => {
      this.requestSent = true;
      this.errors = false;
      this.errorMessage = null;

      this.service.login(this.model).subscribe(response => {
        let token = (<any>response).token;
        localStorage.setItem("jwt", token);
        this.invalidLogin = false;
        //this.location.back();
        this.router.navigate([this.returnUrl]);
        this.requestSent = false;
      }, err => {
        this.invalidLogin = true;
        this.requestSent = false;
        this.errors = true;
        if (err.status === 400)
          this.errorMessage = err.error;
      });
    });
  }
}
