import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { ErrorComponent } from './error/error.component';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { AuthService } from './_services/auth.service';
import { VerifyTokenComponent } from './verify-token/verify-token.component';

@NgModule({
  exports: [RouterModule],
  imports: [
    RouterModule.forRoot([
      { path: '', component: HomeComponent, pathMatch: 'full', data: { breadcrumbs: '' } },
      { path: 'login', component: LoginComponent },
      { path: 'account', component: UserProfileComponent, canActivate: [AuthService], data: { breadcrumbs: 'Account' } },
      { path: 'forgot-password', component: ForgotPasswordComponent },
      { path: 'error-page', component: ErrorComponent },

      {
        path: 'product',
        loadChildren: './productmanager/productmanager.module#ProductManagerModule',
        data: {
          breadcrumbs: "Prodotti"
        }
      },
      {
        path: 'customer',
        loadChildren: './customermanager/customermanager.module#CustomerManagerModule',
        data: {
          breadcrumbs: "Clienti"
        }
      },
      {
        path: 'externalorder',
        loadChildren: './externalordermanager/externalordermanager.module#ExternalOrderManagerModule',
        data: {
          breadcrumbs: "Documentazione acquisita "
        }
      },
      {
        path: 'order',
        loadChildren: './ordermanager/ordermanager.module#OrderManagerModule',
        data: {
          breadcrumbs: "Ordini"
        }
      },
      {
        path: 'coursesession',
        loadChildren: './coursesessionmanager/coursesessionmanager.module#CourseSessionManagerModule',
        data: {
          breadcrumbs: "Sessioni"
        }
      },
      {
        path: 'workorder',
        loadChildren: './workordermanager/workordermanager.module#WorkorderManagerModule',
        data: {
          breadcrumbs: "Ordini di lavoro"
        }
      },
      {
        path: 'timetable',
        loadChildren: './timetablemanager/timetablemanager.module#TimetableManagerModule',
        data: {
          breadcrumbs: "Timetable"
        }
      },
      {
        path: 'document',
        loadChildren: './documentmanager/documentmanager.module#DocumentManagerModule',
        data: {
          breadcrumbs: "Documenti"
        }
      },
      {
        path: 'templates',
        loadChildren: './templatemanager/templatemanager.module#TemplatemanagerModule',
        data: {
          breadcrumbs: 'Template'
        }
      },
      {
        path: 'report',
        loadChildren: './reportmanager/reportmanager.module#ReportManagerModule',
        data: {
          breadcrumbs: 'report'
        }
      },
      {
        path: 'users',
        loadChildren: './usermanager/usermanager.module#UsermanagerModule',
        data: {
          breadcrumbs: 'Utenti'
        }
      },
      {
        path: 'verifytoken/:token',
        component: VerifyTokenComponent,
        data: {
          breadcrumbs: "Verifica token"
        }
      },
    ])
  ]
})

export class AppRoutingModule { }
