import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from '../shared/shared.module';
import { WorkOrderDocumentComponent } from './document/items/items.component';
import { WorkOrderDocumentDetailComponent } from './document/detail/detail.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NgbModule,
    SharedModule,
    ReactiveFormsModule
  ],
  declarations: [
    WorkOrderDocumentComponent,
    WorkOrderDocumentDetailComponent],
  exports: [
    WorkOrderDocumentComponent,
    WorkOrderDocumentDetailComponent
  ]
})
export class WorkOrderDocumentManagerModule { }
