import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { saveAs } from "file-saver";
import { Buffer } from 'buffer';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from '../_services/auth.service';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root',
})

export class SharedService {
  CONTROLLER_NAME = "Shared";

  itemStatuses: any[];
  userStatuses: any[];
  userRoles: any[];
  orderStatuses: any[];
  customerCategory: any[];
  documentArea: any[];
  expertiseArea: any[];
  expertiseAreaActivity: any[];
  workOrderType: any[];
  workOrderStatuses: any[];
  workOrderAreaDataStatuses: any[];
  isDevelopment: boolean = false;
  menuOpened: boolean = true;
  globalConfiguration: any;

  menu: any = [
    {
      title: "Prodotti",
      icon: "fa-store-alt",
      roles: "amministratore",
      children: [
        { title: "Prezzario prodotti", routerLink: "/product" },
        { title: "Categorie prodotti", routerLink: "/product/types" },
      ]
    },
    {
      title: "Clienti",
      icon: "fa-user-circle",
      roles: "amministratore,projectmanager",
      children: [
        { title: "Clienti", routerLink: "/customer" },
        { title: "Tipologia cliente", routerLink: "/customer/types" },
        { title: "Dipendenti", routerLink: "/customer/employees" }
      ]
    },
    {
      title: "Documenti",
      icon: "fa-files",
      roles: "amministratore,projectmanager",
      children: [
        { title: "Cartelle", routerLink: "/document/folder" },
        { title: "Sottocartelle", routerLink: "/document/subfolder" },
        { title: "Documenti emessi", routerLink: "/document/issued" },
        { title: "Documentazione acquisita", routerLink: "/externalorder" }
      ]
    },
    {
      title: "Preventivi",
      icon: "fa-shopping-basket",
      roles: "amministratore,projectmanager",
      routerLink: "/order"
    },
    {
      title: "Sessioni",
      icon: "fa-users-class",
      roles: "amministratore,lavoratore,projectmanager",
      areas: "20",
      routerLink: "/coursesession"
    },
    {
      title: "Ordini di lavoro",
      icon: "fa-file-spreadsheet",
      roles: "amministratore,projectmanager,lavoratore",
      children: [
        { title: "Ordini", routerLink: "/workorder" },
        { title: "Stato commesse", roles: "amministratore,projectmanager", routerLink: "/workorder/status" }
      ]
    },
    {
      title: "Scadenzario",
      icon: "fa-calendar-alt",
      roles: "amministratore,projectmanager,lavoratore",
      routerLink: "/timetable"
    },
    {
      title: "I miei ordini",
      icon: "fa-shopping-basket",
      roles: "cliente",
      routerLink: "/order"
    },
    {
      title: "I miei prodotti",
      icon: "fa-list-alt",
      roles: "cliente",
      routerLink: "/order/products"
    },
    {
      title: "Documenti",
      icon: "fa-file",
      roles: "cliente",
      routerLink: "/document"
    },
    {
      title: "Template",
      icon: "fa-file-invoice",
      roles: "amministratore",
      routerLink: "/templates"
    },
    {
      title: "Report",
      icon: "fa-chart-pie",
      roles: "amministratore",
      children: [
        { title: "Prodotti", routerLink: "/report/product" },
        { title: "Ordini", routerLink: "/report/order" },
        { title: "Clienti", routerLink: "/report/customer" }, 
      ]
    },
    {
      title: "Utenti",
      icon: "fa-users",
      roles: "amministratore",
      routerLink: "/users"
    },
    /*{
      title: "Login",
      loggedIn: false,
      routerLink: "/login",
      class: "btn btn-primary",
      hideInMainMenu: true
    },*/
  ];

  constructor(private http: HttpClient, @Inject('BASE_URL') private baseUrl: string, private authService: AuthService) { }

  refreshData(): Observable<any> {
    return this.http.get<any>(this.baseUrl + `api/${this.CONTROLLER_NAME}/GetValueData`)
      .pipe(
        map((result) => {
        this.itemStatuses = result.itemStatuses;
        this.userStatuses = result.userStatuses;
        this.userRoles = result.userRoles;
        this.orderStatuses = result.orderStatuses;
        this.customerCategory = result.customerCategory;
        this.documentArea = result.documentArea;
        this.expertiseArea = result.expertiseArea;
        this.expertiseAreaActivity = result.expertiseAreaActivity;
        this.workOrderType = result.workOrderType;
        this.isDevelopment = result.isDevelopment;
        this.workOrderStatuses = result.workOrderStatuses;
        this.workOrderAreaDataStatuses = result.workOrderAreaDataStatuses;
        this.globalConfiguration = result.globalConfiguration;
      }));
  }

  LoadDataAppInit(): Observable<any> {
    return this.http.get<any>(this.baseUrl + `api/${this.CONTROLLER_NAME}/GetValueData`);
     
  }

  getRecaptchaApiKey(): string {
    return this.globalConfiguration ? this.globalConfiguration.recaptchaApiKey : null;
  }
  getWhiteList(): string {
    return this.globalConfiguration ? this.globalConfiguration.authorizedDomains : null;
  }
  getItemStatuses(): any[] {
    return this.itemStatuses;
  }

  getUserStatuses(): any[] {
    return this.userStatuses;
  }

  getUserRoles(): any[] {
    return this.userRoles;
  }

  getOrderStatuses(): any[] {
    return this.orderStatuses;
  }

  getCustomerCategory(): any[] {
    return this.customerCategory;
  }

  getDocumentArea(): any[] {
    return this.documentArea;
  }

  getExpertiseAreas(): any[] {
    return this.expertiseArea;
  }

  getExpertiseAreaActivities(): any[] {
    return this.expertiseAreaActivity;
  }

  getWorkOrderType(): any[] {
    return this.workOrderType;
  }

  getWorkOrderStatuses(): any[] {
    return this.workOrderStatuses;
  }

  getWorkOrderAreaDataStatuses(): any[] {
    return this.workOrderAreaDataStatuses;
  }

  getOnlyOrderStatuses(): Observable<any> {
    return this.http.get<any>(this.baseUrl + `api/${this.CONTROLLER_NAME}/GetOrderStatuses/`);
  }

  downloadFile(fileName, fileDataAsBase64) {
    var byteArray = new Buffer(fileDataAsBase64, 'base64');
    var blob = new Blob([byteArray], { type: 'application/pdf' });
    saveAs(blob, fileName);
  }

  downloadFromUrl(FileName) {
    if (this.authService.isLoggedIn()) {
      const a: any = document.createElement('a');
      a.style = 'display: none';
      document.body.appendChild(a);
      const url = "tmp\\" + FileName.url;
      a.href = url;
      a.download = "archivio.zip";
      a.click();
      document.body.removeChild(a);
      
    }
    
  }


  getMenu(): any {
    return this.menu;
  }

  getMenuStatus(): Observable<boolean> {
    return of(this.menuOpened);
  }

  toggleMenuStatus(): void {
    this.menuOpened = !this.menuOpened;
    console.debug("toggled " + this.menuOpened);
  }

  closeMenu(): void {
    this.menuOpened = false;
    console.debug("closed");
  }

  openMenu(): void {
    this.menuOpened = true;
    console.debug("opened");
  }

  getIsDevelopment(): boolean {
    return this.isDevelopment;
  }
}
