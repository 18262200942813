import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SharedService } from '../../shared/shared.service';
import { map } from 'rxjs/operators';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

const httpOptionsText = {
  headers: new HttpHeaders({
    'Content-Type': 'text/plain'
  })
};

@Injectable({
  providedIn: 'root',
})

export class WorkOrderProductDocumentService {
  CONTROLLER_NAME = "WorkOrderProductDocument";

  constructor(private sharedService: SharedService,private http: HttpClient, @Inject('BASE_URL') private baseUrl: string) { }

  getByFilters(filter: any): Observable<any> {
    return this.http.post<any[]>(this.baseUrl + `api/${this.CONTROLLER_NAME}/GetByFilters`, filter, httpOptions);
  }

  getOrderProductDocumentsByFilters(filter: any): Observable<any> {
    return this.http.post<any[]>(this.baseUrl + `api/${this.CONTROLLER_NAME}/GetOrderProductDocumentsByFilters`, filter, httpOptions);
  }

  getOrderAndProductDocumentsByFilters(filter: any): Observable<any> {
    return this.http.post<any[]>(this.baseUrl + `api/${this.CONTROLLER_NAME}/GetOrderAndProductDocumentsByFilters`, filter, httpOptions);
  }

  getValues(): Observable<any> {
    return this.getByFilters({ status: 10 });
  }

  getDocumentByFilter(filter: any): Observable<any> {
    return this.http.post<any[]>(this.baseUrl + `api/${this.CONTROLLER_NAME}/GetDocumentByFilter`, filter, httpOptions);
  }

  getById(id: number): Observable<any> {
    return this.http.get<any>(this.baseUrl + `api/${this.CONTROLLER_NAME}/GetById/${id}`);
  }

  save(item: any): Observable<any> {
    return this.http.post(this.baseUrl + `api/${this.CONTROLLER_NAME}/Save`, item, httpOptions)
      .pipe(map(response => { this.sharedService.refreshData(); return response; }));
  }

  toggleStatus(item: any): Observable<any> {
    return this.http.post(this.baseUrl + `api/${this.CONTROLLER_NAME}/ToggleStatus`, item, httpOptions)
      .pipe(map(response => { this.sharedService.refreshData(); return response; }));
  }

  toggleDisabled(item: any): Observable<any> {
    return this.http.post(this.baseUrl + `api/${this.CONTROLLER_NAME}/ToggleDisabled`, item, httpOptions)
      .pipe(map(response => { this.sharedService.refreshData(); return response; }));
  }

  delete(item: any): Observable<any> {
    return this.http.post(this.baseUrl + `api/${this.CONTROLLER_NAME}/Delete`, item, httpOptions)
      .pipe(map(response => { this.sharedService.refreshData(); return response; }));
  }


  downloadFile(item: any) {
    return this.http.post<any>(this.baseUrl + `api/${this.CONTROLLER_NAME}/DownloadFile`, item, httpOptions)
      .subscribe(result => {
        this.sharedService.downloadFile(result.filename, result.data);
      });
  }

  downloadZip(filter: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + `api/${this.CONTROLLER_NAME}/DownloadZip`, filter, httpOptions);
  }
}
