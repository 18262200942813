import { Location } from '@angular/common';
import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subscription } from 'rxjs';
import { OrderService } from '../order.service';
import { ProductService } from '../../../productmanager/product/product.service';
import { CustomerService } from '../../../customermanager/customer/customer.service';
import { FormBuilder, FormGroup, Validators, FormsModule, ValidatorFn, FormControl, ValidationErrors } from '@angular/forms';
import { AuthService } from '../../../_services/auth.service';
import { ErrorService } from '../../../_services/error.service';
import { SharedService } from '../../../shared/shared.service';
import { OrderProductsService } from '../../../orderproductmanager/orderproducts/orderproducts.service';
import { OrderProductsComponent } from '../../../orderproductmanager/orderproducts/items/items.component';
import { map } from 'rxjs/operators';
import { orderValidatorService } from '../order.validator';
import { WorkOrderComponent } from '../../../workordermanager/workorder/items/items.component';
import { WorkOrderDetailComponent } from '../../../workordermanager/workorder/detail/detail.component';



@Component({
  selector: 'app-order-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.css']
})
export class OrderDetailComponent implements OnInit {
  @Input() onlyView: boolean;
  @Input() order: any;
  @Output() closeEvent = new EventEmitter();
  loading: boolean;
  item: any;
  oldOrderNumber: any;
  oldDateOrder: any;
  editMode: boolean;
  userPermissions: any;
  isNew: boolean;
  products: any;
  customers: any;
  itemSubscription: Subscription;
  selectedCustomer: any;
  selectedOrderProduct: number;
  selectedWorkOrder: number;
  productEditMode: boolean;
  orderStatuses: any;
  openedModal: any;
  @ViewChild('productList', { static: false }) productList: OrderProductsComponent;
  @ViewChild('workOrderList', { static: false }) workOrderList: WorkOrderComponent;
  isCustomer: boolean = false;
  minOrderDate: Date;
  maxOrderDate: Date;
  form = new FormGroup({}); // Instantiating our form
  ignoredFirstEvent: boolean = false;
  loggedCustomer: any;
  denyAccess: any = false;
  
  constructor(private route: ActivatedRoute,
    private service: OrderService,
    private orerProductsService: OrderProductsService,
    private productService: ProductService,
    private customerService: CustomerService,
    private modalService: NgbModal,
    private location: Location,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private errorService: ErrorService,
    private sharedService: SharedService,
    private router: Router,
    private orderValidator: orderValidatorService) {
    this.form = this.formBuilder.group({
      orderNumber: [null, Validators.required],
      orderDate: [null, Validators.required],
      idCustomer: [null, Validators.required],
      oldOrderNumber: []
    }, { validator: this.orderValidator.orderNumber() });
  }


  ngOnInit(): void {

    if (this.hasRoles('cliente')) {
      this.isCustomer = true;
    }

    this.orderStatuses = this.sharedService.getOrderStatuses();
    this.userPermissions = this.authService.currentPermissions();
    if (this.order) {
      this.getItem(this.order.id);
    }
    else {
      this.itemSubscription = this.route.params.subscribe((params: Params) => {

        this.getItem(params.id);
      });
    }
   
    this.productService.getValues().subscribe(
      result => {
        this.products = result.items;
      }
    );

    this.customerService.getValues().subscribe(
      result => {
        this.customers = result.items;
      }
    );

    /*this.minOrderDate = new Date(new Date().getFullYear(), 0, 1);*/
    this.maxOrderDate = new Date();
  }

  ngOnDestroy(): void {
    this.itemSubscription.unsubscribe()
  }

  getItem(id: number, callack?: () => void): void {

    this.loggedCustomer = this.authService.currentCustomerId();
    if (id > 0) {
      this.service.getById(id).subscribe(result => {
        this.item = result;
        if (this.loggedCustomer) {
          this.denyAccess = result.idCustomer != this.loggedCustomer;
        }
        this.oldOrderNumber = result.orderNumber;
        this.oldDateOrder = new Date(result.orderDate);

        this.form.updateValueAndValidity();
        this.customerService.getById(result.idCustomer).subscribe(result => {
          this.selectedCustomer = result;
        });
      });
    }
    else {
      if (this.userPermissions.order.create) {
        this.isNew = true;
        this.editMode = true;
        this.item = new Object();
        this.item.status = 10;
        this.item.orderDate = new Date();
        this.form.updateValueAndValidity();
        this.service.getLastOrderNumber(this.item.orderDate.getFullYear()).subscribe(result => {
          this.item.orderNumber = result + 1;
        });
      }
      else {
        this.errorService.showError("Non hai i permessi necessari per accedere");
      }
    }
    
  }

  goBack(): void {
    this.location.back();
  }

  edit(): void {
    this.editMode = true;
  }

  view(): void {
    var appo = this.item;

    this.getItem(this.item.id);
    this.editMode = false;
  }

  save(): void {
    this.service.save(this.item).subscribe(() => this.isNew ? this.goBack() : this.view());
  }

  openModal(modal: NgbModalRef) {
    this.modalService.open(modal);
  }

  openConfirmationModal(modal: NgbModalRef, item: any) {

  }


  openOrderProductsModal(modal: NgbModalRef, orderProduct: number) {
    this.productEditMode = true;

    this.selectedOrderProduct = null;
    this.openedModal = this.modalService.open(modal, { size: "lg" });
    this.openedModal.result.then((result) => {
      if (result && result.files.length > 0) {
      }
      else {
      }
    }, (reason) => {
    });
  }

  openOrderProductsModalDetails(modal: NgbModalRef, orderProduct: number) {
    this.productEditMode = false;

    if (orderProduct) {
      this.selectedOrderProduct = orderProduct;
    }
    this.openedModal = this.modalService.open(modal, { size: "lg" });
    this.openedModal.result.then((result) => {
      if (result && result.files.length > 0) {
       
      }
      else {
      }
    }, (reason) => {
    });
  }

  openModalProductDelete(modal: NgbModalRef, item: any) {
    this.openedModal = this.modalService.open(modal);
    this.openedModal.result.then((result) => {
      if (result === 'confirm')
        this.deleteProduct(item);

    }, (reason) => {
    });
  }

  openWorkOrderModal(modal: NgbModalRef, wordOrder: number) {
    //this.productEditMode = true;

    if (wordOrder) {
      this.selectedWorkOrder = wordOrder;
    }
    this.openedModal = this.modalService.open(modal, { size: "lg" });
    this.openedModal.result.then((result) => {
      if (result && result.files.length > 0) {
        this.selectedWorkOrder = null;
      }
      else {
      }
    }, (reason) => {
    });
  }

  deleteProduct(item: any) {
    this.orerProductsService.delete(item).subscribe(result => this.orderProductChange());
  }

  orderProductChange(result?: any, refresh?: boolean) {
    this.selectedOrderProduct = null;
    if (result) {
      this.item.id = result;
      this.isNew = false;
      //this.router.navigate(['order','detail', result]);
    }
    this.orerProductsService.getByOrder(this.item.id).subscribe(result => {
      this.item.orderProducts = result.items;
      if (result.price) { 
        this.item.orderPrice = result.price;
      }
      if (result.hasTemplate) {
        this.item.hasTemplate = result.hasTemplate;
      }
      if (refresh) {
        this.productList.refreshItems();
      }
      if (this.openedModal) {
        this.openedModal.close();
      }
      else {
        this.modalService.dismissAll();
      }
      
    })
    
  }

  workOrderChange(result?: any, refresh?: boolean) {
    this.selectedWorkOrder = null;
    if (result) {
      this.item.id = result;
      this.isNew = false;
      //this.router.navigate(['order','detail', result]);
    }
    if (this.workOrderList != undefined) {
      this.workOrderList.refreshItems();
    }
    if (this.openedModal) {
      this.openedModal.close();
    }
    else {
      this.modalService.dismissAll();
    }
  }

  closeModal() {
    this.closeEvent.emit();
  }

  hasRoles(roles: string) {
    return this.authService.hasRoles(roles);
  }

  downloadQuote(item: any) {
    this.loading = true;
    this.service.GetQuoteFile(item.id).subscribe(result => {
      this.sharedService.downloadFile(result.filename, result.data);
      this.loading = false;
    });
  }

  onChange(data) {
    if (this.ignoredFirstEvent) {
      var dataOrdine;
      var ignore = false;
      if (data instanceof Date) {
        dataOrdine = data;
      }
      else {
        dataOrdine = new Date(data);
      }

      if (this.oldDateOrder instanceof Date && this.oldDateOrder.getFullYear() == dataOrdine.getFullYear()) {
        ignore = true;
      }

      if (!ignore) {
        this.service.getLastOrderNumber(dataOrdine.getFullYear()).subscribe(result => {
          this.item.orderNumber = result + 1;
        }); 
      }
      
    }
    this.ignoredFirstEvent = true;
    
  }

  downloadZipCert() {
    this.loading = true;
    this.service.downloadZipCert(this.item.id).subscribe(result => {
      this.sharedService.downloadFromUrl(result);
      if (this.productList != undefined ) {
        this.productList.refreshItems();
      }
      this.loading = false;
    });

  }

  isAdministrator() {
    return this.hasRoles('amministratore');
  }
  canEdit(item) {
    if (this.item.hasWorkOrder) {
      return false;
    }

    if (this.isAdministrator()) {
      return true;
    }
  }
}
